import { Models } from '@rematch/core'

import agreement from './agreement'
import auth from './auth'
import basic from './basic'
import building from './building'
import chat from './chat'
import company from './company'
import flags from './flags'
import ga from './ga'
import geocoding from './geocoding'
import listing from './listing'
import listings from './listings'
import loading from './loading'
import maplist from './maplist'
import plans from './plans'
import session from './session'
import sse from './sse'

export interface RootModel extends Models<RootModel> {
  agreement: typeof agreement
  auth: typeof auth
  basic: typeof basic
  building: typeof building
  chat: typeof chat
  company: typeof company
  flags: typeof flags
  ga: typeof ga
  geocoding: typeof geocoding
  listing: typeof listing
  listings: typeof listings
  loading: typeof loading
  plans: typeof plans
  session: typeof session
  sse: typeof sse
}

const models: RootModel = {
  agreement,
  auth,
  basic,
  building,
  chat,
  company,
  flags,
  ga,
  geocoding,
  listing,
  listings,
  loading,
  plans,
  session,
  sse,
  maplist
}

export default models
