import fetch from 'cross-fetch'
import FormData from 'form-data'
import isObject from 'lodash/isObject'
import Cookies from 'universal-cookie'

import { stringify } from '@/utils/qs'

const cookies = new Cookies()

const parametizeUrl = (template: string, params: Record<string, string>) => {
  let url = template

  Object.keys(params).forEach(key => {
    const value = params[key]
    if (url.indexOf(key) === -1) {
      return true
    }

    url = url.replace(`%${key}%`, encodeURI(value))
    return false
  })

  return url
}

const stringifyQueryString = (url: string, queryString: any) => {
  const stringified = stringify(queryString)
  const char = url.indexOf('?') > -1 ? '&' : '?'
  return stringified ? `${url}${char}${stringified}` : url
}

const cleanData = (data: unknown) => {
  if (Array.isArray(data) || isObject(data)) {
    return JSON.stringify(data)
  }

  return data
}

const cleanFormData = (body: Record<string, any>) => {
  const formData = new FormData()
  Object.keys(body).forEach(k => formData.append(k, cleanData(body[k])))
  return formData
}

type FetchOptions = Omit<RequestInit, 'body'> & {
  body?: RequestInit['body'] | FormData
  responseType?: keyof Omit<Body, 'body' | 'bodyUsed'>
}

export type FetchResponse<T> = {
  response: Response
  body: T
}

const handleFetch = async <T>(
  url: string,
  { responseType = 'json', ...opts }: FetchOptions,
  queryString = {}
): Promise<FetchResponse<T>> => {
  let response: Response
  try {
    response = await fetch(
      stringifyQueryString(url, queryString),
      opts as RequestInit
    )
  } catch (error) {
    console.log('[ports/handleFetch] Error occured', error)
    throw error
  }
  try {
    const data = (await response[responseType]()) as T
    return { response, body: data }
  } catch (error) {
    return { response, body: error as T }
  }
}

type Opts = {
  defaultHeaders?: Record<string, string>
  headers?: Record<string, string>
  credentials?: 'omit' | 'include'
  noLoading?: boolean
}

export const get =
  <T>(url: string, opts: Opts = {}) =>
  async (params = {}, queryString = {}, moreOpts: Opts = {}) => {
    const defaultHeaders = opts.defaultHeaders || {
      'X-Api-Key':
        cookies.get('X-Api-Token') || process.env.NEXT_PUBLIC_X_API_TOKEN
    }

    try {
      const allOptions = { ...opts, ...moreOpts }
      const data = await handleFetch<T>(
        parametizeUrl(url, params),
        {
          method: 'GET',
          credentials: 'include',
          ...allOptions,
          headers: {
            ...defaultHeaders,
            ...allOptions.headers
          }
        },
        queryString
      )
      return data
    } catch (error) {
      console.log('[ports/get] Error occured', error)
      throw error
    }
  }

type RequestMethod = 'post' | 'put' | 'patch' | 'delete' | 'get'
const requestWithBody =
  <T>(method: RequestMethod) =>
  (url: string, opts: Opts = {}) =>
  async (body: any, params = {}, queryString = {}, moreOpts = {}) => {
    const defaultHeaders = opts.defaultHeaders || {
      'X-Api-Key':
        cookies.get('X-Api-Token') || process.env.NEXT_PUBLIC_X_API_TOKEN
    }

    try {
      const allOptions = { ...opts, ...moreOpts }
      const data = await handleFetch<T>(
        parametizeUrl(url, params),
        {
          method,
          credentials: 'include',
          ...allOptions,
          headers: {
            ...defaultHeaders,
            ...allOptions.headers
          },
          body: body && cleanFormData(body)
        },
        queryString
      )
      return data
    } catch (error) {
      console.log(`[ports/${method}] Error occured`, error)
      throw error
    }
  }

interface RequestWithBody {
  <T>(url: string, opts?: Opts): (
    body?: {},
    params?: {},
    queryString?: {},
    moreOpts?: {}
  ) => Promise<FetchResponse<T>>
}
export const post: RequestWithBody = requestWithBody('post')
export const put: RequestWithBody = requestWithBody('put')
export const patch: RequestWithBody = requestWithBody('patch')
export const del: RequestWithBody = requestWithBody('delete')
