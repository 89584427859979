import { createModel } from '@rematch/core'

import { RootModel } from '.'

const maplist = createModel<RootModel>()({
  state: {
    showMapButton: false
  },
  reducers: {
    showMapButton: () => ({ showMapButton: true }),
    hideMapButton: () => ({ showMapButton: false })
  }
})

export default maplist
