// Adapted from https://stackoverflow.com/a/9039885
// Returns true if user is using iOS Safari
const useIOSSafari = () => {
  if (typeof window === 'undefined') return false
  const iOS13 =
    navigator?.userAgent?.includes('Mac') && 'ontouchend' in document
  const isAppleDevice = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator?.platform)
  return (isAppleDevice || iOS13) && /apple/i.test(navigator?.vendor)
}

export default useIOSSafari
