import '@machobear/design-system/reset'
import '../../styles/globals.css'

import React, { FunctionComponent, useEffect } from 'react'
import type { AppLayoutProps, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { propEq } from 'ramda'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider, ToastProvider } from '@machobear/design-system'
import { lakshmi as theme } from '@machobear/design-system/themes/lakshmi'
import { CookiesProvider } from 'react-cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'

import { flags, FlagsProvider } from '@/flags'
import { useApollo } from '@/lib/apollo'
import { themeProviderOptions } from '@/lib/themes'
import useCurrentSession from '@/hooks/use-current-session'
import { useIOSViewport } from '@/hooks/use-ios-viewport'
import useLoadSession from '@/hooks/use-load-session'
import usePersistLocaleCookie from '@/hooks/usePersistLocaleCookie'
import ErrorPage from '@/features/ErrorPage'
import HotJar from '@/components/hotjar'
import SendInBlue from '@/components/send-in-blue'
import { ToastContainer } from '@/components/toast-notifications'
import { UserPilot } from '@/components/userpilot'
import Viewport from '@/components/viewport'

import { useDispatch, useStore } from '../lib/redux'

import '../../devlink/global.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AlertModalProvider } from '@/hooks/use-alert-modal-context'
import { useSetReferralData } from '@/hooks/use-referral-data'

import { DevLinkProvider } from '../../devlink'

function SessionLoader() {
  useLoadSession()
  const router = useRouter()
  const dispatch = useDispatch()
  useEffect(() => {
    const start = () => {
      dispatch.loading.setLoading()
    }
    const end = () => {
      dispatch.loading.stopLoading()
    }
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', end)
    router.events.on('routeChangeError', end)
    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', end)
      router.events.off('routeChangeError', end)
    }
  }, [dispatch.loading, router.events])
  return null
}

function LanguageRedirect() {
  const router = useRouter()
  const { pathname, asPath, query, locale } = router
  const session = useCurrentSession()
  const sessionComplete =
    !!session?.id && propEq('registration_complete', '1', session)
  const userLanguage = session?.setting_language

  useEffect(() => {
    if (!sessionComplete || !userLanguage || userLanguage === locale) return
    router.push({ pathname, query }, asPath, { locale: userLanguage })
  }, [router, pathname, asPath, query, locale, userLanguage, sessionComplete])
  return null
}

const EmptyLayout: React.FC<{ children: JSX.Element }> = ({ children }) =>
  children

function MyApp({ Component, pageProps, universalCookies }: AppLayoutProps) {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const store = useStore(pageProps.initialReduxState)
  const queryClient = new QueryClient()
  usePersistLocaleCookie()
  useIOSViewport()
  useSetReferralData()
  const Layout = Component.Layout || EmptyLayout
  return (
    <ErrorBoundary FallbackComponent={() => <ErrorPage />}>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={queryClient}>
            <CookiesProvider cookies={universalCookies}>
              <FlagsProvider flags={flags}>
                <DevLinkProvider>
                  <React.StrictMode>
                    <ThemeProvider theme={theme} options={themeProviderOptions}>
                      <ToastProvider>
                        <AlertModalProvider>
                          <HotJar />
                          <SendInBlue />
                          <Viewport />
                          <SessionLoader />
                          <UserPilot />
                          <LanguageRedirect />
                          <Layout>
                            <Component {...pageProps} />
                          </Layout>
                          <ToastContainer />
                        </AlertModalProvider>
                      </ToastProvider>
                    </ThemeProvider>
                  </React.StrictMode>
                </DevLinkProvider>
              </FlagsProvider>
            </CookiesProvider>
          </QueryClientProvider>
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default appWithTranslation(MyApp as FunctionComponent<AppProps>)
