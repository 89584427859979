import {
  append,
  assoc,
  defaultTo,
  descend,
  length,
  lensProp,
  mergeLeft,
  pipe,
  prop,
  propOr,
  reverse,
  set,
  sortWith,
  uniq,
  values
} from 'ramda'
import { createModel } from '@rematch/core'

import {
  SSEChatMessage,
  Timeline,
  Timelines,
  TimelinesResponse,
  TimelineStatsResponse
} from '@/types'
import { getTimelines, getTimelineStats } from '@/ports'

import { RootModel } from '.'

type ChatState = TimelinesResponse &
  TimelineStatsResponse & {
    intialTimelinesLoaded?: boolean
    unread_timeline_rooms: string[]
    inq: number
    ten: number
  }

const chat = createModel<RootModel>()({
  state: {} as ChatState,

  reducers: {
    saveUnread: (state, payload: TimelineStatsResponse) => ({
      ...state,
      ...payload
    }),
    clearData: () => ({} as ChatState),
    setUnread: (state, timelineRoomId: string) => {
      return set(
        lensProp('unread_timeline_rooms'),
        pipe<ChatState, string[], string[], string[], string[]>(
          prop<'unread_timeline_rooms', string[]>('unread_timeline_rooms'),
          defaultTo([]),
          append(timelineRoomId),
          uniq
        )(state),
        state
      )
    },
    saveTimelines: (state, payload: ChatState) => {
      return pipe<ChatState, ChatState, ChatState, ChatState, ChatState>(
        assoc('intialTimelinesLoaded', payload.intialTimelinesLoaded),
        assoc('inq', propOr(state.inq, 'inq')(payload)),
        assoc('ten', propOr(state.ten, 'ten')(payload)),
        mergeLeft({
          timelines: payload.timelines
        })
      )(state)
    }
  },

  effects: dispatch => ({
    checkUnreadMessages: async (_, rootState) => {
      if (rootState.chat.unread_timeline_rooms) return
      const result = await getTimelineStats()
      const { body } = result || {}
      dispatch.chat.saveUnread(body)
    },
    parseTimelineSSE(message: SSEChatMessage) {
      dispatch.chat.setUnread(message.timeline_room)
    },
    getTimelines: async () => {
      const { body } = await getTimelines()
      dispatch.chat.saveTimelines({
        ...body,
        intialTimelinesLoaded: true
      } as ChatState)
    }
  }),

  selectors: slice => ({
    unreadMessages() {
      return slice(
        pipe<ChatState, string[], number>(
          prop<'unread_timeline_rooms', string[]>('unread_timeline_rooms'),
          length
        )
      )
    },
    timelines() {
      return slice(
        pipe<ChatState, Timelines, Timeline[]>(
          prop<'timelines', Timelines>('timelines'),
          pipe<Timelines, Timeline[], Timeline[], Timeline[]>(
            values,
            reverse,
            sortWith([descend(prop('last_event'))])
          )
        )
      )
    }
  })
})

export default chat
