import { createModel } from '@rematch/core'

import { RootModel } from '.'

const DATA = {
  countries: {
    2: {
      txt_id: 'af',
      name: 'Afghanistan',
      calling_code: '93'
    },
    3: {
      txt_id: 'al',
      name: 'Albania',
      calling_code: '355'
    },
    4: {
      txt_id: 'dz',
      name: 'Algeria',
      calling_code: '213'
    },
    5: {
      txt_id: 'as',
      name: 'American Samoa',
      calling_code: '1'
    },
    6: {
      txt_id: 'ad',
      name: 'Andorra',
      calling_code: '376'
    },
    7: {
      txt_id: 'ao',
      name: 'Angola',
      calling_code: '244'
    },
    8: {
      txt_id: 'ai',
      name: 'Anguilla',
      calling_code: '1'
    },
    9: {
      txt_id: 'aq',
      name: 'Antarctica',
      calling_code: '672'
    },
    10: {
      txt_id: 'ag',
      name: 'Antigua & Barbuda',
      calling_code: '1'
    },
    11: {
      txt_id: 'ar',
      name: 'Argentina',
      calling_code: '54'
    },
    12: {
      txt_id: 'am',
      name: 'Armenia',
      calling_code: '374'
    },
    13: {
      txt_id: 'aw',
      name: 'Aruba',
      calling_code: '297'
    },
    14: {
      txt_id: 'au',
      name: 'Australia',
      calling_code: '61'
    },
    15: {
      txt_id: 'at',
      name: 'Austria',
      calling_code: '43'
    },
    16: {
      txt_id: 'az',
      name: 'Azerbaijan',
      calling_code: '994'
    },
    17: {
      txt_id: 'bs',
      name: 'Bahamas',
      calling_code: '1'
    },
    18: {
      txt_id: 'bh',
      name: 'Bahrain',
      calling_code: '973'
    },
    19: {
      txt_id: 'bd',
      name: 'Bangladesh',
      calling_code: '880'
    },
    20: {
      txt_id: 'bb',
      name: 'Barbados',
      calling_code: '1'
    },
    21: {
      txt_id: 'by',
      name: 'Belarus',
      calling_code: '375'
    },
    22: {
      txt_id: 'be',
      name: 'Belgium',
      calling_code: '32'
    },
    23: {
      txt_id: 'bz',
      name: 'Belize',
      calling_code: '501'
    },
    24: {
      txt_id: 'bj',
      name: 'Benin',
      calling_code: '229'
    },
    25: {
      txt_id: 'bm',
      name: 'Bermuda',
      calling_code: '1'
    },
    26: {
      txt_id: 'bt',
      name: 'Bhutan',
      calling_code: '975'
    },
    27: {
      txt_id: 'bo',
      name: 'Bolivia',
      calling_code: '591'
    },
    28: {
      txt_id: 'ba',
      name: 'Bosnia',
      calling_code: '387'
    },
    29: {
      txt_id: 'bw',
      name: 'Botswana',
      calling_code: '267'
    },
    30: {
      txt_id: 'bv',
      name: 'Bouvet Island',
      calling_code: '47'
    },
    31: {
      txt_id: 'br',
      name: 'Brazil',
      calling_code: '55'
    },
    32: {
      txt_id: 'io',
      name: 'British Indian Ocean Territory',
      calling_code: '246'
    },
    33: {
      txt_id: 'vg',
      name: 'British Virgin Islands',
      calling_code: '1'
    },
    34: {
      txt_id: 'bn',
      name: 'Brunei',
      calling_code: '673'
    },
    35: {
      txt_id: 'bg',
      name: 'Bulgaria',
      calling_code: '359'
    },
    36: {
      txt_id: 'bf',
      name: 'Burkina Faso',
      calling_code: '226'
    },
    37: {
      txt_id: 'bi',
      name: 'Burundi',
      calling_code: '257'
    },
    38: {
      txt_id: 'kh',
      name: 'Cambodia',
      calling_code: '855'
    },
    39: {
      txt_id: 'cm',
      name: 'Cameroon',
      calling_code: '237'
    },
    40: {
      txt_id: 'ca',
      name: 'Canada',
      calling_code: '1',

      states: [
        {
          txt_id: 'AB',
          name: 'Alberta'
        },
        {
          txt_id: 'BC',
          name: 'British Columbia'
        },
        {
          txt_id: 'MB',
          name: 'Manitoba'
        },
        {
          txt_id: 'NB',
          name: 'New Brunswick'
        },
        {
          txt_id: 'NL',
          name: 'Newfoundland and Labrador'
        },
        {
          txt_id: 'NT',
          name: 'Northwest Territories'
        },
        {
          txt_id: 'NS',
          name: 'Nova Scotia'
        },
        {
          txt_id: 'NU',
          name: 'Nunavut'
        },
        {
          txt_id: 'ON',
          name: 'Ontario'
        },
        {
          txt_id: 'PE',
          name: 'Prince Edward Island'
        },
        {
          txt_id: 'QC',
          name: 'Quebec'
        },
        {
          txt_id: 'SK',
          name: 'Saskatchewan'
        },
        {
          txt_id: 'YT',
          name: 'Yukon Territory'
        }
      ]
    },
    41: {
      txt_id: 'cv',
      name: 'Cape Verde',
      calling_code: '238'
    },
    42: {
      txt_id: 'bq',
      name: 'Caribbean Netherlands',
      calling_code: '599'
    },
    43: {
      txt_id: 'ky',
      name: 'Cayman Islands',
      calling_code: '1'
    },
    44: {
      txt_id: 'cf',
      name: 'Central African Republic',
      calling_code: '236'
    },
    45: {
      txt_id: 'td',
      name: 'Chad',
      calling_code: '235'
    },
    46: {
      txt_id: 'cl',
      name: 'Chile',
      calling_code: '56'
    },
    47: {
      txt_id: 'cn',
      name: 'China',
      calling_code: '86'
    },
    48: {
      txt_id: 'cx',
      name: 'Christmas Island',
      calling_code: '61'
    },
    49: {
      txt_id: 'cc',
      name: 'Cocos (Keeling) Islands',
      calling_code: '61'
    },
    50: {
      txt_id: 'co',
      name: 'Colombia',
      calling_code: '57'
    },
    51: {
      txt_id: 'km',
      name: 'Comoros',
      calling_code: '269'
    },
    52: {
      txt_id: 'cg',
      name: 'Congo - Brazzaville',
      calling_code: '242'
    },
    53: {
      txt_id: 'cd',
      name: 'Congo - Kinshasa',
      calling_code: '243'
    },
    54: {
      txt_id: 'ck',
      name: 'Cook Islands',
      calling_code: '682'
    },
    55: {
      txt_id: 'cr',
      name: 'Costa Rica',
      calling_code: '506'
    },
    56: {
      txt_id: 'hr',
      name: 'Croatia',
      calling_code: '385'
    },
    57: {
      txt_id: 'cu',
      name: 'Cuba',
      calling_code: '53'
    },
    58: {
      txt_id: 'cw',
      name: 'Curaçao',
      calling_code: '599'
    },
    59: {
      txt_id: 'cy',
      name: 'Cyprus',
      calling_code: '357'
    },
    60: {
      txt_id: 'cz',
      name: 'Czechia',
      calling_code: '420'
    },
    61: {
      txt_id: 'ci',
      name: 'Côte d’Ivoire',
      calling_code: '225'
    },
    62: {
      txt_id: 'dk',
      name: 'Denmark',
      calling_code: '45'
    },
    63: {
      txt_id: 'dj',
      name: 'Djibouti',
      calling_code: '253'
    },
    64: {
      txt_id: 'dm',
      name: 'Dominica',
      calling_code: '1'
    },
    65: {
      txt_id: 'do',
      name: 'Dominican Republic',
      calling_code: '1'
    },
    66: {
      txt_id: 'ec',
      name: 'Ecuador',
      calling_code: '593'
    },
    67: {
      txt_id: 'eg',
      name: 'Egypt',
      calling_code: '20'
    },
    68: {
      txt_id: 'sv',
      name: 'El Salvador',
      calling_code: '503'
    },
    69: {
      txt_id: 'gq',
      name: 'Equatorial Guinea',
      calling_code: '240'
    },
    70: {
      txt_id: 'er',
      name: 'Eritrea',
      calling_code: '291'
    },
    71: {
      txt_id: 'ee',
      name: 'Estonia',
      calling_code: '372'
    },
    72: {
      txt_id: 'et',
      name: 'Ethiopia',
      calling_code: '251'
    },
    73: {
      txt_id: 'fk',
      name: 'Falkland Islands',
      calling_code: '500'
    },
    74: {
      txt_id: 'fo',
      name: 'Faroe Islands',
      calling_code: '298'
    },
    75: {
      txt_id: 'fj',
      name: 'Fiji',
      calling_code: '679'
    },
    76: {
      txt_id: 'fi',
      name: 'Finland',
      calling_code: '358'
    },
    77: {
      txt_id: 'fr',
      name: 'France',
      calling_code: '33'
    },
    78: {
      txt_id: 'gf',
      name: 'French Guiana',
      calling_code: '594'
    },
    79: {
      txt_id: 'pf',
      name: 'French Polynesia',
      calling_code: '689'
    },
    80: {
      txt_id: 'tf',
      name: 'French Southern Territories',
      calling_code: '262'
    },
    81: {
      txt_id: 'ga',
      name: 'Gabon',
      calling_code: '241'
    },
    82: {
      txt_id: 'gm',
      name: 'Gambia',
      calling_code: '220'
    },
    83: {
      txt_id: 'ge',
      name: 'Georgia',
      calling_code: '995'
    },
    84: {
      txt_id: 'de',
      name: 'Germany',
      calling_code: '49'
    },
    85: {
      txt_id: 'gh',
      name: 'Ghana',
      calling_code: '233'
    },
    86: {
      txt_id: 'gi',
      name: 'Gibraltar',
      calling_code: '350'
    },
    87: {
      txt_id: 'gr',
      name: 'Greece',
      calling_code: '30'
    },
    88: {
      txt_id: 'gl',
      name: 'Greenland',
      calling_code: '299'
    },
    89: {
      txt_id: 'gd',
      name: 'Grenada',
      calling_code: '1'
    },
    90: {
      txt_id: 'gp',
      name: 'Guadeloupe',
      calling_code: '590'
    },
    91: {
      txt_id: 'gu',
      name: 'Guam',
      calling_code: '1'
    },
    92: {
      txt_id: 'gt',
      name: 'Guatemala',
      calling_code: '502'
    },
    93: {
      txt_id: 'gg',
      name: 'Guernsey',
      calling_code: '44'
    },
    94: {
      txt_id: 'gn',
      name: 'Guinea',
      calling_code: '224'
    },
    95: {
      txt_id: 'gw',
      name: 'Guinea-Bissau',
      calling_code: '245'
    },
    96: {
      txt_id: 'gy',
      name: 'Guyana',
      calling_code: '592'
    },
    97: {
      txt_id: 'ht',
      name: 'Haiti',
      calling_code: '509'
    },
    98: {
      txt_id: 'hm',
      name: 'Heard & McDonald Islands',
      calling_code: '672'
    },
    99: {
      txt_id: 'hn',
      name: 'Honduras',
      calling_code: '504'
    },
    100: {
      txt_id: 'hk',
      name: 'Hong Kong',
      calling_code: '852'
    },
    101: {
      txt_id: 'hu',
      name: 'Hungary',
      calling_code: '36'
    },
    102: {
      txt_id: 'is',
      name: 'Iceland',
      calling_code: '354'
    },
    103: {
      txt_id: 'in',
      name: 'India',
      calling_code: '91'
    },
    104: {
      txt_id: 'id',
      name: 'Indonesia',
      calling_code: '62'
    },
    105: {
      txt_id: 'ir',
      name: 'Iran',
      calling_code: '98'
    },
    106: {
      txt_id: 'iq',
      name: 'Iraq',
      calling_code: '964'
    },
    107: {
      txt_id: 'ie',
      name: 'Ireland',
      calling_code: '353'
    },
    108: {
      txt_id: 'im',
      name: 'Isle of Man',
      calling_code: '44'
    },
    109: {
      txt_id: 'il',
      name: 'Israel',
      calling_code: '972'
    },
    110: {
      txt_id: 'it',
      name: 'Italy',
      calling_code: '39'
    },
    111: {
      txt_id: 'jm',
      name: 'Jamaica',
      calling_code: '1'
    },
    112: {
      txt_id: 'jp',
      name: 'Japan',
      calling_code: '81'
    },
    113: {
      txt_id: 'je',
      name: 'Jersey',
      calling_code: '44'
    },
    114: {
      txt_id: 'jo',
      name: 'Jordan',
      calling_code: '962'
    },
    115: {
      txt_id: 'kz',
      name: 'Kazakhstan',
      calling_code: '7'
    },
    116: {
      txt_id: 'ke',
      name: 'Kenya',
      calling_code: '254'
    },
    117: {
      txt_id: 'ki',
      name: 'Kiribati',
      calling_code: '686'
    },
    118: {
      txt_id: 'kw',
      name: 'Kuwait',
      calling_code: '965'
    },
    119: {
      txt_id: 'kg',
      name: 'Kyrgyzstan',
      calling_code: '996'
    },
    120: {
      txt_id: 'la',
      name: 'Laos',
      calling_code: '856'
    },
    121: {
      txt_id: 'lv',
      name: 'Latvia',
      calling_code: '371'
    },
    122: {
      txt_id: 'lb',
      name: 'Lebanon',
      calling_code: '961'
    },
    123: {
      txt_id: 'ls',
      name: 'Lesotho',
      calling_code: '266'
    },
    124: {
      txt_id: 'lr',
      name: 'Liberia',
      calling_code: '231'
    },
    125: {
      txt_id: 'ly',
      name: 'Libya',
      calling_code: '218'
    },
    126: {
      txt_id: 'li',
      name: 'Liechtenstein',
      calling_code: '423'
    },
    127: {
      txt_id: 'lt',
      name: 'Lithuania',
      calling_code: '370'
    },
    128: {
      txt_id: 'lu',
      name: 'Luxembourg',
      calling_code: '352'
    },
    129: {
      txt_id: 'mo',
      name: 'Macau',
      calling_code: '853'
    },
    130: {
      txt_id: 'mk',
      name: 'Macedonia',
      calling_code: '389'
    },
    131: {
      txt_id: 'mg',
      name: 'Madagascar',
      calling_code: '261'
    },
    132: {
      txt_id: 'mw',
      name: 'Malawi',
      calling_code: '265'
    },
    133: {
      txt_id: 'my',
      name: 'Malaysia',
      calling_code: '60'
    },
    134: {
      txt_id: 'mv',
      name: 'Maldives',
      calling_code: '960'
    },
    135: {
      txt_id: 'ml',
      name: 'Mali',
      calling_code: '223'
    },
    136: {
      txt_id: 'mt',
      name: 'Malta',
      calling_code: '356'
    },
    137: {
      txt_id: 'mh',
      name: 'Marshall Islands',
      calling_code: '692'
    },
    138: {
      txt_id: 'mq',
      name: 'Martinique',
      calling_code: '596'
    },
    139: {
      txt_id: 'mr',
      name: 'Mauritania',
      calling_code: '222'
    },
    140: {
      txt_id: 'mu',
      name: 'Mauritius',
      calling_code: '230'
    },
    141: {
      txt_id: 'yt',
      name: 'Mayotte',
      calling_code: '262'
    },
    142: {
      txt_id: 'mx',
      name: 'Mexico',
      calling_code: '52'
    },
    143: {
      txt_id: 'fm',
      name: 'Micronesia',
      calling_code: '691'
    },
    144: {
      txt_id: 'md',
      name: 'Moldova',
      calling_code: '373'
    },
    145: {
      txt_id: 'mc',
      name: 'Monaco',
      calling_code: '377'
    },
    146: {
      txt_id: 'mn',
      name: 'Mongolia',
      calling_code: '976'
    },
    147: {
      txt_id: 'me',
      name: 'Montenegro',
      calling_code: '382'
    },
    148: {
      txt_id: 'ms',
      name: 'Montserrat',
      calling_code: '1'
    },
    149: {
      txt_id: 'ma',
      name: 'Morocco',
      calling_code: '212'
    },
    150: {
      txt_id: 'mz',
      name: 'Mozambique',
      calling_code: '258'
    },
    151: {
      txt_id: 'mm',
      name: 'Myanmar',
      calling_code: '95'
    },
    152: {
      txt_id: 'na',
      name: 'Namibia',
      calling_code: '264'
    },
    153: {
      txt_id: 'nr',
      name: 'Nauru',
      calling_code: '674'
    },
    154: {
      txt_id: 'np',
      name: 'Nepal',
      calling_code: '977'
    },
    155: {
      txt_id: 'nl',
      name: 'Netherlands',
      calling_code: '31'
    },
    156: {
      txt_id: 'nc',
      name: 'New Caledonia',
      calling_code: '687'
    },
    157: {
      txt_id: 'nz',
      name: 'New Zealand',
      calling_code: '64'
    },
    158: {
      txt_id: 'ni',
      name: 'Nicaragua',
      calling_code: '505'
    },
    159: {
      txt_id: 'ne',
      name: 'Niger',
      calling_code: '227'
    },
    160: {
      txt_id: 'ng',
      name: 'Nigeria',
      calling_code: '234'
    },
    161: {
      txt_id: 'nu',
      name: 'Niue',
      calling_code: '683'
    },
    162: {
      txt_id: 'nf',
      name: 'Norfolk Island',
      calling_code: '672'
    },
    163: {
      txt_id: 'kp',
      name: 'North Korea',
      calling_code: '850'
    },
    164: {
      txt_id: 'mp',
      name: 'Northern Mariana Islands',
      calling_code: '1'
    },
    165: {
      txt_id: 'no',
      name: 'Norway',
      calling_code: '47'
    },
    166: {
      txt_id: 'om',
      name: 'Oman',
      calling_code: '968'
    },
    167: {
      txt_id: 'pk',
      name: 'Pakistan',
      calling_code: '92'
    },
    168: {
      txt_id: 'pw',
      name: 'Palau',
      calling_code: '680'
    },
    169: {
      txt_id: 'ps',
      name: 'Palestine',
      calling_code: '970'
    },
    170: {
      txt_id: 'pa',
      name: 'Panama',
      calling_code: '507'
    },
    171: {
      txt_id: 'pg',
      name: 'Papua New Guinea',
      calling_code: '675'
    },
    172: {
      txt_id: 'py',
      name: 'Paraguay',
      calling_code: '595'
    },
    173: {
      txt_id: 'pe',
      name: 'Peru',
      calling_code: '51'
    },
    174: {
      txt_id: 'ph',
      name: 'Philippines',
      calling_code: '63'
    },
    175: {
      txt_id: 'pn',
      name: 'Pitcairn Islands',
      calling_code: '870'
    },
    176: {
      txt_id: 'pl',
      name: 'Poland',
      calling_code: '48'
    },
    177: {
      txt_id: 'pt',
      name: 'Portugal',
      calling_code: '351'
    },
    178: {
      txt_id: 'pr',
      name: 'Puerto Rico',
      calling_code: '1'
    },
    179: {
      txt_id: 'qa',
      name: 'Qatar',
      calling_code: '974'
    },
    180: {
      txt_id: 'ro',
      name: 'Romania',
      calling_code: '40'
    },
    181: {
      txt_id: 'ru',
      name: 'Russia',
      calling_code: '7'
    },
    182: {
      txt_id: 'rw',
      name: 'Rwanda',
      calling_code: '250'
    },
    183: {
      txt_id: 're',
      name: 'Réunion',
      calling_code: '262'
    },
    184: {
      txt_id: 'ws',
      name: 'Samoa',
      calling_code: '685'
    },
    185: {
      txt_id: 'sm',
      name: 'San Marino',
      calling_code: '378'
    },
    186: {
      txt_id: 'sa',
      name: 'Saudi Arabia',
      calling_code: '966'
    },
    187: {
      txt_id: 'sn',
      name: 'Senegal',
      calling_code: '221'
    },
    188: {
      txt_id: 'rs',
      name: 'Serbia',
      calling_code: '1'
    },
    189: {
      txt_id: 'sc',
      name: 'Seychelles',
      calling_code: '248'
    },
    190: {
      txt_id: 'sl',
      name: 'Sierra Leone',
      calling_code: '232'
    },
    191: {
      txt_id: 'sg',
      name: 'Singapore',
      calling_code: '65'
    },
    192: {
      txt_id: 'sx',
      name: 'Sint Maarten',
      calling_code: '1'
    },
    193: {
      txt_id: 'sk',
      name: 'Slovakia',
      calling_code: '421'
    },
    194: {
      txt_id: 'si',
      name: 'Slovenia',
      calling_code: '386'
    },
    195: {
      txt_id: 'sb',
      name: 'Solomon Islands',
      calling_code: '677'
    },
    196: {
      txt_id: 'so',
      name: 'Somalia',
      calling_code: '252'
    },
    197: {
      txt_id: 'za',
      name: 'South Africa',
      calling_code: '27'
    },
    198: {
      txt_id: 'gs',
      name: 'South Georgia & South Sandwich Islands',
      calling_code: '500'
    },
    199: {
      txt_id: 'kr',
      name: 'South Korea',
      calling_code: '82'
    },
    200: {
      txt_id: 'ss',
      name: 'South Sudan',
      calling_code: '211'
    },
    201: {
      txt_id: 'es',
      name: 'Spain',
      calling_code: '34'
    },
    202: {
      txt_id: 'lk',
      name: 'Sri Lanka',
      calling_code: '94'
    },
    203: {
      txt_id: 'bl',
      name: 'St. Barthélemy',
      calling_code: '590'
    },
    204: {
      txt_id: 'sh',
      name: 'St. Helena',
      calling_code: '1'
    },
    205: {
      txt_id: 'kn',
      name: 'St. Kitts & Nevis',
      calling_code: '1'
    },
    206: {
      txt_id: 'lc',
      name: 'St. Lucia',
      calling_code: '1'
    },
    207: {
      txt_id: 'mf',
      name: 'St. Martin',
      calling_code: '590'
    },
    208: {
      txt_id: 'pm',
      name: 'St. Pierre & Miquelon',
      calling_code: '508'
    },
    209: {
      txt_id: 'vc',
      name: 'St. Vincent & Grenadines',
      calling_code: '1'
    },
    210: {
      txt_id: 'sd',
      name: 'Sudan',
      calling_code: '249'
    },
    211: {
      txt_id: 'sr',
      name: 'Suriname',
      calling_code: '597'
    },
    212: {
      txt_id: 'sj',
      name: 'Svalbard & Jan Mayen',
      calling_code: '47'
    },
    213: {
      txt_id: 'sz',
      name: 'Swaziland',
      calling_code: '268'
    },
    214: {
      txt_id: 'se',
      name: 'Sweden',
      calling_code: '46'
    },
    215: {
      txt_id: 'ch',
      name: 'Switzerland',
      calling_code: '41'
    },
    216: {
      txt_id: 'sy',
      name: 'Syria',
      calling_code: '963'
    },
    217: {
      txt_id: 'st',
      name: 'São Tomé & Príncipe',
      calling_code: '239'
    },
    218: {
      txt_id: 'tw',
      name: 'Taiwan',
      calling_code: '886'
    },
    219: {
      txt_id: 'tj',
      name: 'Tajikistan',
      calling_code: '992'
    },
    220: {
      txt_id: 'tz',
      name: 'Tanzania',
      calling_code: '255'
    },
    221: {
      txt_id: 'th',
      name: 'Thailand',
      calling_code: '66'
    },
    222: {
      txt_id: 'tl',
      name: 'Timor-Leste',
      calling_code: '670'
    },
    223: {
      txt_id: 'tg',
      name: 'Togo',
      calling_code: '228'
    },
    224: {
      txt_id: 'tk',
      name: 'Tokelau',
      calling_code: '690'
    },
    225: {
      txt_id: 'to',
      name: 'Tonga',
      calling_code: '676'
    },
    226: {
      txt_id: 'tt',
      name: 'Trinidad & Tobago',
      calling_code: '1'
    },
    227: {
      txt_id: 'tn',
      name: 'Tunisia',
      calling_code: '216'
    },
    228: {
      txt_id: 'tr',
      name: 'Turkey',
      calling_code: '90'
    },
    229: {
      txt_id: 'tm',
      name: 'Turkmenistan',
      calling_code: '993'
    },
    230: {
      txt_id: 'tc',
      name: 'Turks & Caicos Islands',
      calling_code: '1'
    },
    231: {
      txt_id: 'tv',
      name: 'Tuvalu',
      calling_code: '688'
    },
    232: {
      txt_id: 'um',
      name: 'U.S. Outlying Islands',
      calling_code: '1'
    },
    233: {
      txt_id: 'vi',
      name: 'U.S. Virgin Islands',
      calling_code: '1'
    },
    234: {
      txt_id: 'gb',
      name: 'UK',
      calling_code: '44'
    },
    235: {
      txt_id: 'us',
      name: 'US',
      calling_code: '1',

      states: [
        {
          txt_id: 'AL',
          name: 'Alabama'
        },
        {
          txt_id: 'AK',
          name: 'Alaska'
        },
        {
          txt_id: 'AZ',
          name: 'Arizona'
        },
        {
          txt_id: 'AR',
          name: 'Arkansas'
        },
        {
          txt_id: 'CA',
          name: 'California'
        },
        {
          txt_id: 'CO',
          name: 'Colorado'
        },
        {
          txt_id: 'CT',
          name: 'Connecticut'
        },
        {
          txt_id: 'DE',
          name: 'Delaware'
        },
        {
          txt_id: 'DC',
          name: 'District of Columbia'
        },
        {
          txt_id: 'FL',
          name: 'Florida'
        },
        {
          txt_id: 'GA',
          name: 'Georgia'
        },
        {
          txt_id: 'HI',
          name: 'Hawaii'
        },
        {
          txt_id: 'ID',
          name: 'Idaho'
        },
        {
          txt_id: 'IL',
          name: 'Illinois'
        },
        {
          txt_id: 'IN',
          name: 'Indiana'
        },
        {
          txt_id: 'IA',
          name: 'Iowa'
        },
        {
          txt_id: 'KS',
          name: 'Kansas'
        },
        {
          txt_id: 'KY',
          name: 'Kentucky'
        },
        {
          txt_id: 'LA',
          name: 'Louisiana'
        },
        {
          txt_id: 'ME',
          name: 'Maine'
        },
        {
          txt_id: 'MT',
          name: 'Montana'
        },
        {
          txt_id: 'NE',
          name: 'Nebraska'
        },
        {
          txt_id: 'NV',
          name: 'Nevada'
        },
        {
          txt_id: 'NH',
          name: 'New Hampshire'
        },
        {
          txt_id: 'NJ',
          name: 'New Jersey'
        },
        {
          txt_id: 'NM',
          name: 'New Mexico'
        },
        {
          txt_id: 'NY',
          name: 'New York'
        },
        {
          txt_id: 'NC',
          name: 'North Carolina'
        },
        {
          txt_id: 'ND',
          name: 'North Dakota'
        },
        {
          txt_id: 'OH',
          name: 'Ohio'
        },
        {
          txt_id: 'OK',
          name: 'Oklahoma'
        },
        {
          txt_id: 'OR',
          name: 'Oregon'
        },
        {
          txt_id: 'MD',
          name: 'Maryland'
        },
        {
          txt_id: 'MA',
          name: 'Massachusetts'
        },
        {
          txt_id: 'MI',
          name: 'Michigan'
        },
        {
          txt_id: 'MN',
          name: 'Minnesota'
        },
        {
          txt_id: 'MS',
          name: 'Mississippi'
        },
        {
          txt_id: 'MO',
          name: 'Missouri'
        },
        {
          txt_id: 'PA',
          name: 'Pennsylvania'
        },
        {
          txt_id: 'RI',
          name: 'Rhode Island'
        },
        {
          txt_id: 'SC',
          name: 'South Carolina'
        },
        {
          txt_id: 'SD',
          name: 'South Dakota'
        },
        {
          txt_id: 'TN',
          name: 'Tennessee'
        },
        {
          txt_id: 'TX',
          name: 'Texas'
        },
        {
          txt_id: 'UT',
          name: 'Utah'
        },
        {
          txt_id: 'VT',
          name: 'Vermont'
        },
        {
          txt_id: 'VA',
          name: 'Virginia'
        },
        {
          txt_id: 'WA',
          name: 'Washington'
        },
        {
          txt_id: 'WV',
          name: 'West Virginia'
        },
        {
          txt_id: 'WI',
          name: 'Wisconsin'
        },
        {
          txt_id: 'WY',
          name: 'Wyoming'
        }
      ]
    },
    236: {
      txt_id: 'ug',
      name: 'Uganda',
      calling_code: '256'
    },
    237: {
      txt_id: 'ua',
      name: 'Ukraine',
      calling_code: '380'
    },
    238: {
      txt_id: 'ae',
      name: 'United Arab Emirates',
      calling_code: '971'
    },
    239: {
      txt_id: 'uy',
      name: 'Uruguay',
      calling_code: '598'
    },
    240: {
      txt_id: 'uz',
      name: 'Uzbekistan',
      calling_code: '998'
    },
    241: {
      txt_id: 'vu',
      name: 'Vanuatu',
      calling_code: '678'
    },
    242: {
      txt_id: 'va',
      name: 'Vatican City',
      calling_code: '379'
    },
    243: {
      txt_id: 've',
      name: 'Venezuela',
      calling_code: '58'
    },
    244: {
      txt_id: 'vn',
      name: 'Vietnam',
      calling_code: '84'
    },
    245: {
      txt_id: 'wf',
      name: 'Wallis & Futuna',
      calling_code: '681'
    },
    246: {
      txt_id: 'eh',
      name: 'Western Sahara',
      calling_code: '212'
    },
    247: {
      txt_id: 'ye',
      name: 'Yemen',
      calling_code: '967'
    },
    248: {
      txt_id: 'zm',
      name: 'Zambia',
      calling_code: '260'
    },
    249: {
      txt_id: 'zw',
      name: 'Zimbabwe',
      calling_code: '263'
    },
    250: {
      txt_id: 'ax',
      name: 'Åland Islands',
      calling_code: '358'
    }
  },
  utilities: {
    cable: {
      id: '5',
      txt_id: 'cable',
      name: 'Cable'
    },
    electricity: {
      id: '1',
      txt_id: 'electricity',
      name: 'Electricity'
    },
    internet: {
      id: '4',
      txt_id: 'internet',
      name: 'Internet'
    },
    gas: {
      id: '3',
      txt_id: 'gas',
      name: 'Natural Gas'
    },
    water: {
      id: '2',
      txt_id: 'water',
      name: 'Water'
    },
    heat: {
      id: '6',
      txt_id: 'heat',
      name: 'Heat'
    }
  },
  unit_features: {
    bathtub: {
      id: '1',
      txt_id: 'bathtub',
      name: 'Bathtub'
    },
    bbq: {
      id: '2',
      txt_id: 'bbq',
      name: 'BBQ Ready'
    },
    coin_laundry: {
      id: '3',
      txt_id: 'coin_laundry',
      name: 'Coin Laundry'
    },
    dishwasher: {
      id: '6',
      txt_id: 'dishwasher',
      name: 'Dishwasher'
    },
    fridge: {
      id: '8',
      txt_id: 'fridge',
      name: 'Fridge'
    },
    garbage_chute: {
      id: '9',
      txt_id: 'garbage_chute',
      name: 'Garbage Collection'
    },
    dryer: {
      id: '7',
      txt_id: 'dryer',
      name: 'In Suite Dryer'
    },
    washer: {
      id: '25',
      txt_id: 'washer',
      name: 'In Suite Washer'
    },
    jacuzzi: {
      id: '14',
      txt_id: 'jacuzzi',
      name: 'Jacuzzi'
    },
    tv_unit: {
      id: '23',
      txt_id: 'tv_unit',
      name: 'TV Unit'
    },
    disable_ready: {
      id: '5',
      txt_id: 'disable_ready',
      name: 'Wheelchair Accessible'
    },
    microwave: {
      id: '27',
      txt_id: 'microwave',
      name: 'Microwave'
    },
    carpets: {
      id: '28',
      txt_id: 'carpets',
      name: 'Carpets'
    },
    garburator: {
      id: '29',
      txt_id: 'garburator',
      name: 'Garburator'
    },
    windows_covering: {
      id: '30',
      txt_id: 'windows_covering',
      name: 'Windows Covering'
    },
    sewage_disposal: {
      id: '31',
      txt_id: 'sewage_disposal',
      name: 'Sewage disposal'
    },
    snow_removal: {
      id: '32',
      txt_id: 'snow_removal',
      name: 'Snow Removal'
    },
    stove_top: {
      id: '37',
      txt_id: 'stove_top',
      name: 'Stove Top'
    },
    oven: {
      id: '38',
      txt_id: 'oven',
      name: 'Oven'
    },
    air_conditioning: {
      id: '39',
      txt_id: 'air_conditioning',
      name: 'Air Conditioning'
    }
  },
  building_restrictions: {
    age_restricted: {
      txt_id: 'age_restricted',
      name: 'Age Restricted',
      description: 'Age Restricted'
    },
    age_restriction_19_plus: {
      txt_id: 'age_restriction_19_plus',
      name: 'Age restriction 19+',
      description: 'Age restriction 19+'
    },
    age_restriction_40_plus: {
      txt_id: 'age_restriction_40_plus',
      name: 'Age Restriction 40+',
      description: 'Age Restriction 40+'
    },
    age_restriction_45_plus: {
      txt_id: 'age_restriction_45_plus',
      name: 'Age restriction 45+',
      description: 'Age restriction 45+'
    },
    age_restriction_50_plus: {
      txt_id: 'age_restriction_50_plus',
      name: 'Age Restriction 50+',
      description: 'Age Restriction 50+'
    },
    age_restriction_55_plus: {
      txt_id: 'age_restriction_55_plus',
      name: 'Age restriction 55+',
      description: 'Age restriction 55+'
    },
    cats_allowed: {
      txt_id: 'cats_allowed',
      name: 'Cats Allowed',
      description: 'Cats Allowed'
    },
    cats_not_allowed: {
      txt_id: 'cats_not_allowed',
      name: 'Cats Not Allowed',
      description: 'Cats Not Allowed'
    },
    dogs_allowed: {
      txt_id: 'dogs_allowed',
      name: 'Dogs Allowed',
      description: 'Dogs Allowed'
    },
    dogs_not_allowed: {
      txt_id: 'dogs_not_allowed',
      name: 'Dogs Not Allowed',
      description: 'Dogs Not Allowed'
    },
    max_1_pet_allowed: {
      txt_id: 'max_1_pet_allowed',
      name: 'Maximum 1 Pet',
      description: 'Maximum 1 Pet'
    },
    max_2_pets_allowed: {
      txt_id: 'max_2_pets_allowed',
      name: 'Maximum 2 Pets',
      description: 'Maximum 2 Pets'
    },
    no_of_pets_2: {
      txt_id: 'no_of_pets_2',
      name: 'Maximum 2 Pets',
      description: 'Maximum 2 Pets'
    },
    no_rentals_allowed: {
      txt_id: 'no_rentals_allowed',
      name: 'No rentals allowed',
      description: 'No rentals allowed'
    },
    no_smoking_allowed: {
      txt_id: 'no_smoking_allowed',
      name: 'No Smoking Allowed',
      description: 'No Smoking Allowed'
    },
    pets_allowed: {
      txt_id: 'pets_allowed',
      name: 'Pets Allowed',
      description: 'Pets Allowed'
    },
    pets_not_allowed: {
      txt_id: 'pets_not_allowed',
      name: 'Pets Not Allowed',
      description: 'Pets Not Allowed'
    },
    rentals_allowed: {
      txt_id: 'rentals_allowed',
      name: 'Rentals allowed',
      description: 'Rentals allowed'
    },
    rentals_allowed_with_restrictions: {
      txt_id: 'rentals_allowed_with_restrictions',
      name: 'Rentals allowed with restrictions',
      description: 'Rentals allowed with restrictions'
    },
    pets_allowed_with_restrictions: {
      txt_id: 'pets_allowed_with_restrictions',
      name: 'Pets Allowed With Restrictions',
      description: 'Pets Allowed With Restrictions'
    }
  },
  building_developers: {
    1: {
      id: '1',
      name: '4 Ever Home Properties'
    },
    2: {
      id: '2',
      name: 'Abitare Holdings Ltd'
    },
    3: {
      id: '3',
      name: 'Abstract Developments Inc.'
    },
    4: {
      id: '4',
      name: 'Adera'
    },
    5: {
      id: '5',
      name: 'Adera Group'
    },
    6: {
      id: '6',
      name: 'ALDEA HOMES LTD'
    },
    7: {
      id: '7',
      name: 'Algra Bros'
    },
    8: {
      id: '8',
      name: 'Allaire Group'
    },
    9: {
      id: '9',
      name: 'Alpha Beta Developments Ltd.'
    },
    10: {
      id: '10',
      name: 'Alpha Project Development Ltd.'
    },
    11: {
      id: '11',
      name: 'ALTA Group of Companies'
    },
    12: {
      id: '12',
      name: 'ALTUS GROUP'
    },
    13: {
      id: '13',
      name: 'Am-Pri'
    },
    14: {
      id: '14',
      name: 'Am-Pri Construction Ltd.'
    },
    15: {
      id: '15',
      name: 'AMA Development Corp'
    },
    16: {
      id: '16',
      name: 'Amacon'
    },
    17: {
      id: '17',
      name: 'Ambros'
    },
    18: {
      id: '18',
      name: 'Anderson Square Holdings Ltd.'
    },
    19: {
      id: '19',
      name: 'Ansu Developments'
    },
    20: {
      id: '20',
      name: 'Anthem Properties'
    },
    21: {
      id: '21',
      name: 'Apex Custom Homes Ltd'
    },
    22: {
      id: '22',
      name: 'Apolla Development'
    },
    23: {
      id: '23',
      name: 'Appia Developments Ltd.'
    },
    24: {
      id: '24',
      name: 'Appia Group'
    },
    25: {
      id: '25',
      name: 'Aquattro Development'
    },
    26: {
      id: '26',
      name: 'Aquila Developments'
    },
    27: {
      id: '27',
      name: 'Aquilini Development and Construction Inc.'
    },
    28: {
      id: '28',
      name: 'Aquilini Investment Group'
    },
    29: {
      id: '29',
      name: 'ARA VENTURES INC'
    },
    30: {
      id: '30',
      name: 'Aragon Properties Ltd'
    },
    31: {
      id: '31',
      name: 'Archstone Projects Ltd.'
    },
    32: {
      id: '32',
      name: 'AREE Holdings Ltd.'
    },
    33: {
      id: '33',
      name: 'Arthur Bell Holdings Ltd.'
    },
    34: {
      id: '34',
      name: 'Artisan Park Properties Ltd.'
    },
    35: {
      id: '35',
      name: 'Aspac Developments Ltd.'
    },
    36: {
      id: '36',
      name: 'Athenry Development Limited'
    },
    37: {
      id: '37',
      name: 'ATI Investment Ltd.'
    },
    38: {
      id: '38',
      name: 'Ayen Group'
    },
    39: {
      id: '39',
      name: 'Ballenas Project Management'
    },
    40: {
      id: '40',
      name: 'Barber Creek Development Ltd'
    },
    41: {
      id: '41',
      name: 'Barco Canada Development'
    },
    42: {
      id: '42',
      name: 'Bastion Development Corporation'
    },
    43: {
      id: '43',
      name: 'Beach Avenue Developments'
    },
    44: {
      id: '44',
      name: 'Bear Mountain Master Partnership'
    },
    45: {
      id: '45',
      name: 'Beedie Living'
    },
    46: {
      id: '46',
      name: 'Benchmark Homes Ltd.'
    },
    47: {
      id: '47',
      name: 'Bentall Real Estate Services'
    },
    48: {
      id: '48',
      name: 'Bestbuy Development Ltd'
    },
    49: {
      id: '49',
      name: "Bill's Development Ltd"
    },
    50: {
      id: '50',
      name: 'Blexo Developments'
    },
    51: {
      id: '51',
      name: 'Blue Tree Management (Canada) Limited'
    },
    52: {
      id: '52',
      name: 'BlueSky Properties'
    },
    53: {
      id: '53',
      name: 'Bluetree Homes'
    },
    54: {
      id: '54',
      name: 'Boffo Bros Development Ltd.'
    },
    55: {
      id: '55',
      name: 'Boffo Developments Ltd.'
    },
    56: {
      id: '56',
      name: 'Bogner Development Group Ltd'
    },
    57: {
      id: '57',
      name: 'Bogner Development Group Ltd;'
    },
    58: {
      id: '58',
      name: 'Bonnis Development Corp.'
    },
    59: {
      id: '59',
      name: 'Bosa Development'
    },
    60: {
      id: '60',
      name: 'Bosa Properties'
    },
    61: {
      id: '61',
      name: 'Breeze Properties Ltd.'
    },
    62: {
      id: '62',
      name: 'Brentwood Bay Lodge Ltd.'
    },
    63: {
      id: '63',
      name: 'British Pacific Properties'
    },
    64: {
      id: '64',
      name: 'Brody Development Group'
    },
    65: {
      id: '65',
      name: 'Bronte Heights Development'
    },
    66: {
      id: '66',
      name: 'Brookside Walk Holdings Ltd.,'
    },
    67: {
      id: '67',
      name: 'Bucci Developments Ltd.'
    },
    68: {
      id: '68',
      name: 'Buset Development Corp.'
    },
    69: {
      id: '69',
      name: 'Cal-Terra Developments'
    },
    70: {
      id: '70',
      name: 'Canada Lands Company'
    },
    71: {
      id: '71',
      name: 'Casa Mia Projects Ltd'
    },
    72: {
      id: '72',
      name: 'Cascadia Development Corporation'
    },
    73: {
      id: '73',
      name: 'CastleHill Homes'
    },
    74: {
      id: '74',
      name: 'Casula Investment Ltd.'
    },
    75: {
      id: '75',
      name: 'CCM Investment Group Ltd.'
    },
    76: {
      id: '76',
      name: 'Cedar Developments Corporation'
    },
    77: {
      id: '77',
      name: 'Censorio Group of Companies'
    },
    78: {
      id: '78',
      name: 'Centro Parkside Development Ltd.'
    },
    79: {
      id: '79',
      name: 'Century Group'
    },
    80: {
      id: '80',
      name: 'Chandler Development Group Inc.'
    },
    81: {
      id: '81',
      name: 'Charan Sethi'
    },
    82: {
      id: '82',
      name: 'Chard Developments'
    },
    83: {
      id: '83',
      name: 'Charland Homes LP'
    },
    84: {
      id: '84',
      name: 'Citimark'
    },
    85: {
      id: '85',
      name: 'City LifeStyle Group'
    },
    86: {
      id: '86',
      name: 'Claravista Homes Ltd.'
    },
    87: {
      id: '87',
      name: 'Clay Group'
    },
    88: {
      id: '88',
      name: 'Coast 49 Homes Ltd.'
    },
    89: {
      id: '89',
      name: 'Colliers International'
    },
    90: {
      id: '90',
      name: 'Concert Properties Ltd.'
    },
    91: {
      id: '91',
      name: 'Concord Pacific'
    },
    92: {
      id: '92',
      name: 'ConEcon Consultants Inc.'
    },
    93: {
      id: '93',
      name: 'Coniston Developments Ltd.'
    },
    94: {
      id: '94',
      name: 'Cornerstone Construction Ltd.'
    },
    95: {
      id: '95',
      name: 'Cressey Development Group'
    },
    96: {
      id: '96',
      name: 'CYCLAMEN TRADE CO LTD'
    },
    97: {
      id: '97',
      name: 'Dallas Point Developments'
    },
    98: {
      id: '98',
      name: 'Dams Development Corporation'
    },
    99: {
      id: '99',
      name: 'Darwin Construction Ltd'
    },
    100: {
      id: '100',
      name: 'Dava Developments'
    },
    101: {
      id: '101',
      name: 'Dawson + Sawyer'
    },
    102: {
      id: '102',
      name: 'DCM Projects Ltd'
    },
    103: {
      id: '103',
      name: 'De Cotiis Group'
    },
    104: {
      id: '104',
      name: 'Decorus Development Inc'
    },
    105: {
      id: '105',
      name: 'Degelder Group'
    },
    106: {
      id: '106',
      name: 'Delta Blue Construction Inc.'
    },
    107: {
      id: '107',
      name: 'Delta Land Development Ltd'
    },
    108: {
      id: '108',
      name: 'Denford Construction Management Ltd.﻿'
    },
    109: {
      id: '109',
      name: 'Denna Homes'
    },
    110: {
      id: '110',
      name: 'Dennis Rogers'
    },
    111: {
      id: '111',
      name: 'Diamond Robinson Group Ltd'
    },
    112: {
      id: '112',
      name: 'Dimex Group'
    },
    113: {
      id: '113',
      name: 'DMRC Properties Ltd.'
    },
    114: {
      id: '114',
      name: 'Don Mann Excavating Ltd.'
    },
    115: {
      id: '115',
      name: 'Doon Developments'
    },
    116: {
      id: '116',
      name: 'DR4 Developments Ltd.'
    },
    117: {
      id: '117',
      name: 'DTKH Robson Development Ltd.'
    },
    118: {
      id: '118',
      name: 'Dueck General Contracting'
    },
    119: {
      id: '119',
      name: 'Dymax Development Corp'
    },
    120: {
      id: '120',
      name: 'Eccom Developments Ltd.'
    },
    121: {
      id: '121',
      name: 'Eclissi Developments Ltd.'
    },
    122: {
      id: '122',
      name: 'Elegant Development Inc.'
    },
    123: {
      id: '123',
      name: 'Elite Homes'
    },
    124: {
      id: '124',
      name: 'Emaar Properties (Canada) Ltd.'
    },
    125: {
      id: '125',
      name: 'Embassy Development Corporation'
    },
    126: {
      id: '126',
      name: 'Empire Developments'
    },
    127: {
      id: '127',
      name: 'EPIX Developments Ltd.'
    },
    128: {
      id: '128',
      name: 'EPS Westcoast Construction Ltd.'
    },
    129: {
      id: '129',
      name: 'Epta Properties'
    },
    130: {
      id: '130',
      name: 'Equitas'
    },
    131: {
      id: '131',
      name: 'Ernest & Twins Developments Inc.'
    },
    132: {
      id: '132',
      name: 'Ethos Developments LTD'
    },
    133: {
      id: '133',
      name: 'Executive Group Development'
    },
    134: {
      id: '134',
      name: 'Executive Group of Company'
    },
    135: {
      id: '135',
      name: 'Extol Developments'
    },
    136: {
      id: '136',
      name: 'Fairborne Homes'
    },
    137: {
      id: '137',
      name: 'Falcon Homes'
    },
    138: {
      id: '138',
      name: 'Fidelity Development Ltd.'
    },
    139: {
      id: '139',
      name: 'FireLight Developments Ltd.'
    },
    140: {
      id: '140',
      name: 'FIRM Development Ltd'
    },
    141: {
      id: '141',
      name: 'First Western Developments Ltd.'
    },
    142: {
      id: '142',
      name: 'Flame Engineering and Construction'
    },
    143: {
      id: '143',
      name: 'Focus Equities'
    },
    144: {
      id: '144',
      name: 'Fortuna Development Inc'
    },
    145: {
      id: '145',
      name: 'Four Sons Enterprises Ltd'
    },
    146: {
      id: '146',
      name: 'Foxridge Homes'
    },
    147: {
      id: '147',
      name: 'G3 Developments'
    },
    148: {
      id: '148',
      name: 'Galiano Enterprises Ltd.'
    },
    149: {
      id: '149',
      name: 'Garcha Properties Ltd.'
    },
    150: {
      id: '150',
      name: 'Gardenia Homes LTD.'
    },
    151: {
      id: '151',
      name: 'Gem Garden Homes Ltd.'
    },
    152: {
      id: '152',
      name: 'Genesis Tower Ltd.'
    },
    153: {
      id: '153',
      name: 'Genex Development Corporation'
    },
    154: {
      id: '154',
      name: 'Genica Development'
    },
    155: {
      id: '155',
      name: 'Georgia Laine Developments Ltd'
    },
    156: {
      id: '156',
      name: 'Golden City Homes Ltd.'
    },
    157: {
      id: '157',
      name: 'Gord Dhillon'
    },
    158: {
      id: '158',
      name: "Gore Brothers' Homes"
    },
    159: {
      id: '159',
      name: 'Graham Group Ltd.'
    },
    160: {
      id: '160',
      name: 'Gramercy Group'
    },
    161: {
      id: '161',
      name: 'Grand Adex Development'
    },
    162: {
      id: '162',
      name: 'Granville Westside Developments Ltd'
    },
    163: {
      id: '163',
      name: 'Great West Development'
    },
    164: {
      id: '164',
      name: 'Greci Developments'
    },
    165: {
      id: '165',
      name: 'Grosvenor Americas'
    },
    166: {
      id: '166',
      name: 'Group Pacific Developments'
    },
    167: {
      id: '167',
      name: 'Guildford Brook Estates Development Corporation'
    },
    168: {
      id: '168',
      name: 'H.j. Property Investment'
    },
    169: {
      id: '169',
      name: 'Haebler Construction'
    },
    170: {
      id: '170',
      name: 'Hans de Goede Development Ltd.'
    },
    171: {
      id: '171',
      name: 'Harbourview'
    },
    172: {
      id: '172',
      name: 'Hastings Sunrise Development Limited'
    },
    173: {
      id: '173',
      name: 'Hayer Builders Group'
    },
    174: {
      id: '174',
      name: 'Hayer Homes Ltd.'
    },
    175: {
      id: '175',
      name: 'Hazelnut Homes'
    },
    176: {
      id: '176',
      name: 'Hazelwood Holdings Ltd.'
    },
    177: {
      id: '177',
      name: 'HCH Land Co Ltd.'
    },
    178: {
      id: '178',
      name: 'Heatherbrae Construction'
    },
    179: {
      id: '179',
      name: 'Henderson Development (Canada) Ltd.'
    },
    180: {
      id: '180',
      name: 'Hesco Properties'
    },
    181: {
      id: '181',
      name: 'Hillside Development Ltd'
    },
    182: {
      id: '182',
      name: 'Holborn Group of Companies'
    },
    183: {
      id: '183',
      name: 'Homesite Developments Inc.'
    },
    184: {
      id: '184',
      name: 'Homeward Bound Development Inc.'
    },
    185: {
      id: '185',
      name: 'Homewood Constructors Ltd.'
    },
    186: {
      id: '186',
      name: 'Honest Living (0875260 BC Ltd)'
    },
    187: {
      id: '187',
      name: 'Hudson West Development Group'
    },
    188: {
      id: '188',
      name: 'Hungerford Group'
    },
    189: {
      id: '189',
      name: 'Hybrid Homes Ltd.,'
    },
    190: {
      id: '190',
      name: 'Hyland Pacific Holdings Inc'
    },
    191: {
      id: '191',
      name: 'I4 Property Group'
    },
    192: {
      id: '192',
      name: 'Ikonik Homes'
    },
    193: {
      id: '193',
      name: 'Image Development Inc'
    },
    194: {
      id: '194',
      name: 'IMANI Development Ltd.'
    },
    195: {
      id: '195',
      name: 'Intergulf Development Group'
    },
    196: {
      id: '196',
      name: 'Intracorp'
    },
    197: {
      id: '197',
      name: 'Intrawest Development Corporation'
    },
    198: {
      id: '198',
      name: 'IPM Development'
    },
    199: {
      id: '199',
      name: 'IRCA Group'
    },
    200: {
      id: '200',
      name: 'Island View Construction Ltd.'
    },
    201: {
      id: '201',
      name: 'Isle of Mann'
    },
    202: {
      id: '202',
      name: 'Isle of Mann Construction'
    },
    203: {
      id: '203',
      name: 'Jacken Investment Inc'
    },
    204: {
      id: '204',
      name: 'Jakin Group of Companies'
    },
    205: {
      id: '205',
      name: 'James Schouw & Associates'
    },
    206: {
      id: '206',
      name: 'Jameson Development Corp.'
    },
    207: {
      id: '207',
      name: 'Janda Group'
    },
    208: {
      id: '208',
      name: 'Jawl Properties Ltd.'
    },
    209: {
      id: '209',
      name: 'JCR Construction Ltd'
    },
    210: {
      id: '210',
      name: 'JDS Properties'
    },
    211: {
      id: '211',
      name: 'Jeffs Residences Limited Partnership'
    },
    212: {
      id: '212',
      name: 'Jel Investments and Briland Inc.'
    },
    213: {
      id: '213',
      name: 'JooHo Development Ltd.'
    },
    214: {
      id: '214',
      name: 'Jung Developement'
    },
    215: {
      id: '215',
      name: 'Kang & Gill Construction Ltd.'
    },
    216: {
      id: '216',
      name: 'Kenstone Properties Ltd.'
    },
    217: {
      id: '217',
      name: 'Kerkhoff Construction Ltd.'
    },
    218: {
      id: '218',
      name: 'Kerrisdale Station Ltd'
    },
    219: {
      id: '219',
      name: 'Kingma Bros. Developments'
    },
    220: {
      id: '220',
      name: 'KINGSWOOD CAPITAL CORPORATION'
    },
    221: {
      id: '221',
      name: 'Koolhaus- Abbey Woods Development Ltd.'
    },
    222: {
      id: '222',
      name: 'Kraftsmen Group of Companies'
    },
    223: {
      id: '223',
      name: 'Lakewood Management Ltd.'
    },
    224: {
      id: '224',
      name: 'Landmark Yaletown Projects Inc'
    },
    225: {
      id: '225',
      name: 'Lanstone Homes'
    },
    226: {
      id: '226',
      name: 'Larc Development'
    },
    227: {
      id: '227',
      name: 'Larco Investment Ltd'
    },
    228: {
      id: '228',
      name: 'LDHT Enterprise Ltd.'
    },
    229: {
      id: '229',
      name: 'Le Fevre & Company'
    },
    230: {
      id: '230',
      name: 'Ledcor Properties'
    },
    231: {
      id: '231',
      name: 'Ledingham McAllister'
    },
    232: {
      id: '232',
      name: 'Legendary Development'
    },
    233: {
      id: '233',
      name: 'Liberty Homes Ltd.'
    },
    234: {
      id: '234',
      name: 'LICO Construction Ltd'
    },
    235: {
      id: '235',
      name: 'Limona Group'
    },
    236: {
      id: '236',
      name: 'LionRock Developments'
    },
    237: {
      id: '237',
      name: 'Listraor Homes'
    },
    238: {
      id: '238',
      name: 'LPH Development Ltd.'
    },
    239: {
      id: '239',
      name: 'LRG Group of Companies'
    },
    240: {
      id: '240',
      name: 'Luxor Home Collection Group'
    },
    241: {
      id: '241',
      name: 'M. Karton'
    },
    242: {
      id: '242',
      name: 'MacLean Homes Ltd.'
    },
    243: {
      id: '243',
      name: 'MacLean Management Ltd.'
    },
    244: {
      id: '244',
      name: 'Maeda Development Ltd.'
    },
    245: {
      id: '245',
      name: 'Magellen Developments (20/20) Inc'
    },
    246: {
      id: '246',
      name: 'Magusta Development'
    },
    247: {
      id: '247',
      name: 'Mainland Development Ltd'
    },
    248: {
      id: '248',
      name: 'Manchester Homes Ltd.'
    },
    249: {
      id: '249',
      name: 'Mann Group'
    },
    250: {
      id: '250',
      name: 'Manorlane Homes'
    },
    251: {
      id: '251',
      name: 'Mara Lake Estates'
    },
    252: {
      id: '252',
      name: 'Marcon'
    },
    253: {
      id: '253',
      name: 'Matt Gruber Construction Ltd.'
    },
    254: {
      id: '254',
      name: 'McLaren Housing Society'
    },
    255: {
      id: '255',
      name: 'McLean Courtenay Development Associates'
    },
    256: {
      id: '256',
      name: 'Melrose Developments Ltd.'
    },
    257: {
      id: '257',
      name: 'Michael Williams Development'
    },
    258: {
      id: '258',
      name: 'Mierau Contractors Ltd.'
    },
    259: {
      id: '259',
      name: 'Mike Geric Construction Ltd.'
    },
    260: {
      id: '260',
      name: 'Millennium Development Corporation'
    },
    261: {
      id: '261',
      name: 'MILLENNIUM GROUP'
    },
    262: {
      id: '262',
      name: 'Millennium World'
    },
    263: {
      id: '263',
      name: 'Milori Homes'
    },
    264: {
      id: '264',
      name: 'Minglian Holding Ltd.'
    },
    265: {
      id: '265',
      name: 'Minglian Holdings Ltd.'
    },
    266: {
      id: '266',
      name: 'Miracon'
    },
    267: {
      id: '267',
      name: 'ML Properties Ltd.'
    },
    268: {
      id: '268',
      name: 'Mo Yeung International Enterprise Ltd.'
    },
    269: {
      id: '269',
      name: 'Modern Green Development Co. Ltd'
    },
    270: {
      id: '270',
      name: 'Molnar Group'
    },
    271: {
      id: '271',
      name: 'Monaco Estates'
    },
    272: {
      id: '272',
      name: 'MONDEVO Projects Inc.'
    },
    273: {
      id: '273',
      name: 'Mosaic Avenue Realty Ltd.'
    },
    274: {
      id: '274',
      name: 'Mosaic Development'
    },
    275: {
      id: '275',
      name: 'MOSAIC Homes'
    },
    276: {
      id: '276',
      name: 'Mount Baker Enterprises Ltd'
    },
    277: {
      id: '277',
      name: 'Mount Seymour Developments Inc.'
    },
    278: {
      id: '278',
      name: 'Mountain West Properties Inc.'
    },
    279: {
      id: '279',
      name: 'N-Vision Properties Inc.'
    },
    280: {
      id: '280',
      name: 'Narland'
    },
    281: {
      id: '281',
      name: 'New Valley Holdings'
    },
    282: {
      id: '282',
      name: 'New World Equities I'
    },
    283: {
      id: '283',
      name: 'Newgen Group of Companies'
    },
    284: {
      id: '284',
      name: 'Newmark Group'
    },
    285: {
      id: '285',
      name: 'Nipro Developments Ltd'
    },
    286: {
      id: '286',
      name: 'No. 145 Cathedral Ventures Ltd.'
    },
    287: {
      id: '287',
      name: 'No. 249 Cathedral Ventures Ltd.'
    },
    288: {
      id: '288',
      name: 'Noel Developments Ltd.'
    },
    289: {
      id: '289',
      name: 'Noort Homes'
    },
    290: {
      id: '290',
      name: 'Noran West Development Ltd'
    },
    291: {
      id: '291',
      name: 'Nordel Construction Ltd.'
    },
    292: {
      id: '292',
      name: 'Norman Homes Inc.'
    },
    293: {
      id: '293',
      name: 'North America Homes'
    },
    294: {
      id: '294',
      name: 'NOW Developments'
    },
    295: {
      id: '295',
      name: 'Oaklane Homes'
    },
    296: {
      id: '296',
      name: 'Oakvale Development'
    },
    297: {
      id: '297',
      name: 'OMICRON'
    },
    298: {
      id: '298',
      name: 'Omni Pacific Development Corp'
    },
    299: {
      id: '299',
      name: 'Onni Group'
    },
    300: {
      id: '300',
      name: 'OPAL'
    },
    301: {
      id: '301',
      name: 'Open Concept Development Inc.'
    },
    302: {
      id: '302',
      name: 'Oris Consulting limited'
    },
    303: {
      id: '303',
      name: 'Osoyoos Shoreline Development Ltd.'
    },
    304: {
      id: '304',
      name: 'Overture Living'
    },
    305: {
      id: '305',
      name: 'Oviedo Developments'
    },
    306: {
      id: '306',
      name: 'Pacific C & B Development Corporation'
    },
    307: {
      id: '307',
      name: 'Pacific New Technologies'
    },
    308: {
      id: '308',
      name: 'Palcor'
    },
    309: {
      id: '309',
      name: 'Palladium'
    },
    310: {
      id: '310',
      name: 'Pantheon Developments Ltd.'
    },
    311: {
      id: '311',
      name: 'Panther Construction'
    },
    312: {
      id: '312',
      name: 'Parkgreen Homes Ltd'
    },
    313: {
      id: '313',
      name: 'Parklane Homes'
    },
    314: {
      id: '314',
      name: 'Patterson Development'
    },
    315: {
      id: '315',
      name: 'Pavilion Homes'
    },
    316: {
      id: '316',
      name: 'PCI Real Estate Development'
    },
    317: {
      id: '317',
      name: 'Peace Enterprises Ltd'
    },
    318: {
      id: '318',
      name: 'Pennyfarthing Development Corp'
    },
    319: {
      id: '319',
      name: 'Penta Builders Group Inc.'
    },
    320: {
      id: '320',
      name: 'Peterson Group'
    },
    321: {
      id: '321',
      name: 'Phileo Development Corp.'
    },
    322: {
      id: '322',
      name: 'Phoenix Homes'
    },
    323: {
      id: '323',
      name: 'Pinnacle International'
    },
    324: {
      id: '324',
      name: 'Pinora Homes Ltd.'
    },
    325: {
      id: '325',
      name: 'Pioneer Designs Corporation'
    },
    326: {
      id: '326',
      name: 'Platinum Group of Companies'
    },
    327: {
      id: '327',
      name: 'Pointe of View Developments'
    },
    328: {
      id: '328',
      name: 'Polstar Construction'
    },
    329: {
      id: '329',
      name: 'Polygon'
    },
    330: {
      id: '330',
      name: 'Polygon Homes'
    },
    331: {
      id: '331',
      name: 'Polygon Nordel Development Ltd.'
    },
    332: {
      id: '332',
      name: 'Polygon Realty Ltd.'
    },
    333: {
      id: '333',
      name: 'Port Capital Group Inc.'
    },
    334: {
      id: '334',
      name: 'Porte Development Corp.'
    },
    335: {
      id: '335',
      name: 'Portrait Homes Ltd.'
    },
    336: {
      id: '336',
      name: 'PR Pomeroy Restoration & Construction'
    },
    337: {
      id: '337',
      name: 'Premier International Development'
    },
    338: {
      id: '338',
      name: 'Premier Pacific Developments'
    },
    339: {
      id: '339',
      name: 'Prima Properties Ltd.'
    },
    340: {
      id: '340',
      name: 'Prime Tower Ltd.'
    },
    341: {
      id: '341',
      name: 'Prosper Enterprises'
    },
    342: {
      id: '342',
      name: 'Quadra Homes'
    },
    343: {
      id: '343',
      name: 'Qualex-Landmark'
    },
    344: {
      id: '344',
      name: 'Qualico Development'
    },
    345: {
      id: '345',
      name: 'Quantum Properties'
    },
    346: {
      id: '346',
      name: 'Quest Projects Inc'
    },
    347: {
      id: '347',
      name: 'Quigg'
    },
    348: {
      id: '348',
      name: 'Quorum Construction'
    },
    349: {
      id: '349',
      name: 'R.A.R. Holdings Ltd.'
    },
    350: {
      id: '350',
      name: 'Raicon Developments Inc.'
    },
    351: {
      id: '351',
      name: 'RBI Construction Group Inc'
    },
    352: {
      id: '352',
      name: 'RDC Fine Homes'
    },
    353: {
      id: '353',
      name: 'RDG Management Ltd.'
    },
    354: {
      id: '354',
      name: 'Redekop Homes Ltd'
    },
    355: {
      id: '355',
      name: 'Redekop Kroeker Development Inc.'
    },
    356: {
      id: '356',
      name: 'Regent International Development Ltd'
    },
    357: {
      id: '357',
      name: 'Reliance Properties Ltd.'
    },
    358: {
      id: '358',
      name: 'Rempel Development Group Ltd'
    },
    359: {
      id: '359',
      name: 'Revera Living'
    },
    360: {
      id: '360',
      name: 'Revolution Developments'
    },
    361: {
      id: '361',
      name: 'RG Properties'
    },
    362: {
      id: '362',
      name: 'Richardson Bros. Group'
    },
    363: {
      id: '363',
      name: 'Rize Alliance Properties Ltd.'
    },
    364: {
      id: '364',
      name: 'Royal Group City Ventures Ltd.'
    },
    365: {
      id: '365',
      name: 'Royale Properties Ltd.,'
    },
    366: {
      id: '366',
      name: 'Rykon Group Ltd.'
    },
    367: {
      id: '367',
      name: 'Salient Group'
    },
    368: {
      id: '368',
      name: 'Sandcastle Holdings Ltd'
    },
    369: {
      id: '369',
      name: 'Sandhill Development Ltd.'
    },
    370: {
      id: '370',
      name: 'Sant Investments'
    },
    371: {
      id: '371',
      name: 'Scott Hill Developments'
    },
    372: {
      id: '372',
      name: 'Seacorp of Companies'
    },
    373: {
      id: '373',
      name: 'Sequel 138 Development Corp.'
    },
    374: {
      id: '374',
      name: 'Shato Holdings Ltd.'
    },
    375: {
      id: '375',
      name: 'Sightline Group'
    },
    376: {
      id: '376',
      name: 'Sigma Developments Ltd'
    },
    377: {
      id: '377',
      name: 'Silk Properties'
    },
    378: {
      id: '378',
      name: 'Sirus Familamiri'
    },
    379: {
      id: '379',
      name: 'Skov Developments'
    },
    380: {
      id: '380',
      name: 'SKY REACH DEVELOPMENT LIMITED'
    },
    381: {
      id: '381',
      name: 'Solterra Group of Companies'
    },
    382: {
      id: '382',
      name: 'Sonnenberg Home Builders'
    },
    383: {
      id: '383',
      name: 'SONUS DEVELOPMENTS FERNDALE LTD'
    },
    384: {
      id: '384',
      name: 'South Street Development Group'
    },
    385: {
      id: '385',
      name: 'Spade Holdings Ltd.'
    },
    386: {
      id: '386',
      name: 'Spirit Properties'
    },
    387: {
      id: '387',
      name: 'Spotlight Developments Ltd.'
    },
    388: {
      id: '388',
      name: 'Springbank Development Corp.'
    },
    389: {
      id: '389',
      name: 'Spruceland Homes'
    },
    390: {
      id: '390',
      name: 'Staburn Property Group'
    },
    391: {
      id: '391',
      name: 'Statewood Properties Ltd.'
    },
    392: {
      id: '392',
      name: 'Steve Copp Construction Ltd.'
    },
    393: {
      id: '393',
      name: 'Steveston Views Development Corporation, H.A. (Tussy) Berg'
    },
    394: {
      id: '394',
      name: 'Streamline Properties'
    },
    395: {
      id: '395',
      name: 'StreetSide Developments'
    },
    396: {
      id: '396',
      name: 'Sunmark Builders'
    },
    397: {
      id: '397',
      name: 'Sunshine International Holdings Ltd.'
    },
    398: {
      id: '398',
      name: 'Suraj Home Builders'
    },
    399: {
      id: '399',
      name: 'Sutton Group Realty Services Ltd.'
    },
    400: {
      id: '400',
      name: 'SVM Homes'
    },
    401: {
      id: '401',
      name: 'Synergy Projects Ltd.'
    },
    402: {
      id: '402',
      name: 'T.J.B.S. Properties Ltd.'
    },
    403: {
      id: '403',
      name: 'Takaya Developments Ltd.'
    },
    404: {
      id: '404',
      name: 'Texor Homes Inc.'
    },
    405: {
      id: '405',
      name: 'The Airey Group'
    },
    406: {
      id: '406',
      name: 'The Bowra Group Inc.'
    },
    407: {
      id: '407',
      name: 'The Cape Group'
    },
    408: {
      id: '408',
      name: 'THE CIRCADIAN GROUP'
    },
    409: {
      id: '409',
      name: 'The Maskeen Group'
    },
    410: {
      id: '410',
      name: 'The Mission Group'
    },
    411: {
      id: '411',
      name: 'The Norwood Group'
    },
    412: {
      id: '412',
      name: 'The Pioneer Group'
    },
    413: {
      id: '413',
      name: 'The Salient Group'
    },
    414: {
      id: '414',
      name: 'The Surelok Group'
    },
    415: {
      id: '415',
      name: 'The Townline Group of Companies'
    },
    416: {
      id: '416',
      name: 'The Wall Group of Companies'
    },
    417: {
      id: '417',
      name: 'Thind Properties Ltd.'
    },
    418: {
      id: '418',
      name: 'Thomas Ivanore'
    },
    419: {
      id: '419',
      name: 'Tien Sher Homes'
    },
    420: {
      id: '420',
      name: 'Townline'
    },
    421: {
      id: '421',
      name: 'Toyo Developments'
    },
    422: {
      id: '422',
      name: 'Trafalgar Enterprises Inc'
    },
    423: {
      id: '423',
      name: 'Treegroup'
    },
    424: {
      id: '424',
      name: 'Tri Power Developments Ltd'
    },
    425: {
      id: '425',
      name: 'Tri-R Development Group Inc'
    },
    426: {
      id: '426',
      name: 'Trico Homes'
    },
    427: {
      id: '427',
      name: 'Tridecca Development'
    },
    428: {
      id: '428',
      name: 'Trimount developments'
    },
    429: {
      id: '429',
      name: 'Triton Ventures Corp.'
    },
    430: {
      id: '430',
      name: 'Triumph Pacific Properties Inc'
    },
    431: {
      id: '431',
      name: 'Troika Developments'
    },
    432: {
      id: '432',
      name: 'Two By Four Developments Ltd.'
    },
    433: {
      id: '433',
      name: 'UBC Properties Trust (UBCPT)'
    },
    434: {
      id: '434',
      name: 'Unimet Homes Ltd.'
    },
    435: {
      id: '435',
      name: 'United Pacific Development (West 10th) Corporation'
    },
    436: {
      id: '436',
      name: 'United Properties Group of Companies'
    },
    437: {
      id: '437',
      name: 'United Properties Ltd'
    },
    438: {
      id: '438',
      name: 'UPA Construction Group of companies'
    },
    439: {
      id: '439',
      name: 'VALOUR HOMES'
    },
    440: {
      id: '440',
      name: 'Van Maren Group of Companies'
    },
    441: {
      id: '441',
      name: 'Van-burn Construction Ltd.'
    },
    442: {
      id: '442',
      name: 'Vancouver Pacific Development Corporation'
    },
    443: {
      id: '443',
      name: 'Vanlux Development'
    },
    444: {
      id: '444',
      name: 'Vanview Construction'
    },
    445: {
      id: '445',
      name: 'Vedder Ridge Construction'
    },
    446: {
      id: '446',
      name: 'Vesta'
    },
    447: {
      id: '447',
      name: 'Victoria Construction'
    },
    448: {
      id: '448',
      name: 'Vinterra Properties Inc.'
    },
    449: {
      id: '449',
      name: 'Voth Bros Developments Ltd'
    },
    450: {
      id: '450',
      name: 'Walker Hospitality'
    },
    451: {
      id: '451',
      name: 'Wall Centre False Creek Limited Partnership'
    },
    452: {
      id: '452',
      name: 'Wall Financial Corporation'
    },
    453: {
      id: '453',
      name: 'Wallmark Homes'
    },
    454: {
      id: '454',
      name: 'Wanson Development'
    },
    455: {
      id: '455',
      name: 'Wanson Peninsula Dev. Ltd.'
    },
    456: {
      id: '456',
      name: 'Wave Development'
    },
    457: {
      id: '457',
      name: 'Wertman Development Corporation Invesments Ltd.'
    },
    458: {
      id: '458',
      name: 'Wesbild'
    },
    459: {
      id: '459',
      name: 'Wesgroup'
    },
    460: {
      id: '460',
      name: 'Westbank Projects Corp'
    },
    461: {
      id: '461',
      name: 'Western Canadian Properties Group'
    },
    462: {
      id: '462',
      name: 'Western Construstion'
    },
    463: {
      id: '463',
      name: 'Westmark Development Group'
    },
    464: {
      id: '464',
      name: 'WestStone'
    },
    465: {
      id: '465',
      name: 'Woodbridge Properties'
    },
    466: {
      id: '466',
      name: 'YUANHENG HOLDINGS LTD.'
    },
    467: {
      id: '467',
      name: 'Zenith Development Croup'
    },
    468: {
      id: '468',
      name: 'Zenterra Developments'
    },
    469: {
      id: '469',
      name: 'Info Not Yet Available'
    },
    470: {
      id: 470,
      name: 'GMC Projects Inc',
      website: 'https://www.gmcprojects.com/'
    },
    471: {
      id: 471,
      name: 'Anthem Properties',
      website: 'https://anthemproperties.com/'
    },
    472: {
      id: 472,
      name: 'Redbrick Properties Inc',
      website: 'https://redbrickproperties.ca/'
    }
  }
}

const basic = createModel<RootModel>()({
  state: DATA
})

export default basic
