import styled from 'styled-components'

import { colors } from '@/styles'

import {
  CloseIcon,
  Text,
  TextWrapper,
  Title,
  ToastIcon,
  Wrapper
} from './elements'

const ProgressBar = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${colors.grey};

  &::after {
    content: '';
    height: 8px;
    width: ${props => `${props.progress}%`};
    border-radius: 0 2px 2px 0;
    background-color: ${colors.blue48};
    display: block;
    transition: width 0.2s ease-out;
  }
`

const Progress = ({ progress, title, autoClose, closeToast }) => {
  if (autoClose && progress >= 100) {
    setTimeout(closeToast, autoClose)
  }

  return (
    <Wrapper>
      <ToastIcon type="info" />
      <TextWrapper>
        {!!title && <Title>{title}</Title>}
        <Text>{progress}%</Text>
        <ProgressBar progress={progress} />
      </TextWrapper>
      {progress >= 100 && (
        <CloseIcon
          onClick={e => {
            e.stopPropagation()
            closeToast()
          }}
        />
      )}
    </Wrapper>
  )
}
export default Progress
