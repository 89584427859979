import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { CssUnit } from '@/types'
import { zIndex } from '@/styles'
import CloseButton from '@/components/close-button'

const CloseButtonWrapper = styled.div<{ absolute?: boolean }>`
  height: ${props => (props.absolute ? '0' : '50px')};
  z-index: ${zIndex.modal};
`

type ModalCloseProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
  absolute?: boolean
  top?: CssUnit
  right?: CssUnit
}

const ModalClose: React.FC<ModalCloseProps> = ({
  onClick,
  absolute,
  top,
  right,
  ...rest
}) => (
  <CloseButtonWrapper {...rest} absolute={absolute}>
    <CloseButton onClick={onClick} top={top} right={right} />
  </CloseButtonWrapper>
)

export default ModalClose
