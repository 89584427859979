import Head from 'next/head'

import useIOSSafari from '@/hooks/use-ios-safari'

const Viewport = ({
  content = ['width=device-width', 'initial-scale=1', 'viewport-fit=cover']
}) => {
  const isIOSSafari = useIOSSafari()
  const extra = isIOSSafari ? ['maximum-scale=1'] : []
  const value = [...content, ...extra].join(', ')
  return (
    <Head>
      <meta name="viewport" content={value} />
    </Head>
  )
}

export default Viewport
