import { propEq, reject } from 'ramda'
import { createModel } from '@rematch/core'
import toNumber from 'lodash/toNumber'

import { Flag } from '@/types'
import { deleteFlag, getFlag, updateFlag } from '@/ports'
import { getHeaders } from '@/utils/http'

import { RootModel } from '.'

type FlagsState = Record<string, Flag>

const flags = createModel<RootModel>()({
  name: 'flags',
  state: {} as FlagsState,
  reducers: {
    update: (state, payload: FlagsState) => ({ ...state, ...payload }),
    delete: (state, flagId: string) => reject(propEq('id', flagId), state),
    deleteByKey: (state, key: string) =>
      reject(flag => flag?.key_value === key, state),
    clear: () => ({})
  },
  effects: dispatch => ({
    async updateUserFlag(payload, _, req?) {
      try {
        const { response, body } = await updateFlag(
          {
            body: {
              key_name: payload.name,
              key_value: payload.value
            }
          },
          { id: payload.id },
          getHeaders(req)
        )
        if (response.ok) {
          dispatch.flags.update(body)
          if (payload.name === 'intended_plan') {
            dispatch.session.update(
              ['intended_plan'],
              body.intended_plan?.key_value
            )
          }
        }
        return response
      } catch (error) {
        console.log('[flags/updateUserFlag]', error)
      }
    },
    async getUserFlag(flagName: string, state, req?) {
      if (flagName in (state?.flags || {})) return
      try {
        const { response, body } = await getFlag(
          { flagName },
          undefined,
          getHeaders(req)
        )
        if (response.ok) {
          dispatch.flags.update(body)
        } else if (response.status === 409) {
          dispatch.flags.update({ [flagName]: null as any })
        }
        return response
      } catch (error) {
        console.log('[flags/getUserFlag]', error)
      }
    },
    async deleteUserFlag(flagId, _, req?) {
      try {
        const { response } = await deleteFlag(
          {
            body: {
              id: toNumber(flagId)
            }
          },
          { flagId },
          getHeaders(req)
        )
        if (response.ok) {
          dispatch.flags.delete(flagId.toString())
        }
        return response
      } catch (error) {
        console.log('[flags/deleteUserFlag]', error)
      }
    }
  })
})

export default flags
