import { path } from 'ramda'
import { createModel } from '@rematch/core'

import { autoComplete, reverseGeoCoding } from '@/ports'
import { handle } from '@/utils/promise'

import { RootModel } from '.'

const COMMON_PREPOSITIONS = [
  'a',
  'the',
  'of',
  'are',
  'is',
  'am',
  'with',
  'on',
  'at',
  'from',
  'to',
  'on',
  'must'
]

const removePrepositions = (search: string) => {
  const regex = new RegExp(`\\s+(${COMMON_PREPOSITIONS.join('|')})`)
  return search.replace(regex, '')
}

type GeocodingState = {}

const geocoding = createModel<RootModel>()({
  name: 'geocoding',
  state: {} as GeocodingState,
  effects: () => ({
    async search({ query, ...rest }) {
      const params = {
        query: removePrepositions(query),
        maxresults: 3,
        country: 'can',
        ...rest
      }
      const [response, error] = await handle(autoComplete(undefined, params))
      if (error || !response) {
        return { error }
      }
      return response.body
    },
    async reverseGeocode(query) {
      const { latitude, longitude } = query
      const params = {
        at: `${latitude},${longitude}`
      }

      const [response, error] = await handle(
        reverseGeoCoding(undefined, params)
      )
      if (error || !response) {
        return null
      }
      const results = path(['items', 0, 'address'])(response.body)
      return results
    }
  })
})

export default geocoding
