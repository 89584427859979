import createFlags from 'flag'

import { default as flags } from './flags'

export type FlagTypes = {
  features: {
    pricing: {
      taxCode: boolean
    }
  }
}

const {
  FlagsProvider: Provider,
  Flag,
  useFlag,
  useFlags
} = createFlags<FlagTypes>()

type Props = React.ComponentProps<typeof Provider> & {
  children?: React.ReactNode
}
const FlagsProvider: React.ComponentType<Props> = Provider

export { FlagsProvider, Flag, useFlag, useFlags, flags }
