const header = {
  height: 100,
  heightMobile: 60
}

const search = {
  height: header.height + 20,
  heightMobile: header.heightMobile + 10
}

const map = {
  header: header.height + 66,
  headerMobile: header.heightMobile + 76,
  activeFilters: 36,
  listWidthTriple: 1058,
  listWidthDouble: 768,
  listWidthSingle: 448
}

const page = {
  width: 1240
}

const footer = {
  height: 361,
  minimal: {
    height: 62
  }
}

const structure = {
  header,
  search,
  page,
  footer,
  map
}

export default structure
