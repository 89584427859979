import { createModel } from '@rematch/core'

import { RootModel } from '.'

type Ga = {
  source: string
}

const ga = createModel<RootModel>()({
  name: 'ga',
  state: {
    source: 'initial'
  } as Ga,
  reducers: {
    setSource: (state, source: Ga['source']) => ({ ...state, source })
  }
})

export default ga
