import { ReactElement } from 'react'
import styled from 'styled-components'

import { breakpoints, colors } from '@/styles'
import { Button } from '@/components/button-new'
import { WarningIcon } from '@/components/icons'
import { createModal, useModalize } from '@/components/modalize'
import { H4, ParagraphRegular } from '@/components/text'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const ImageWrapper = styled.div<{ bgColor?: string }>`
  width: 100%;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : colors.red96)};
  display: flex;
  justify-content: center;
  padding: 32px 24px 24px;

  svg {
    height: 72px;
    width: 100%;
  }
`

const ContentWrapper = styled.div`
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const ModalText = styled(ParagraphRegular)`
  color: ${colors.grey56};
  margin: 8px 0 0;
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;

  button:nth-child(2) {
    margin-left: 16px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column-reverse;

    button:nth-child(2) {
      margin: 0 0 16px;
    }
  }
`

interface Button {
  text: string
  id?: string
  onClick?: () => void
}

interface Props {
  title: string
  message?: string | ReactElement
  icon?: React.ComponentType
  button?: Button
  secondaryButton?: Button
  iconBgColor?: string
}

const WarningModal: React.FC<Props> = ({
  title,
  message,
  icon = WarningIcon,
  button = null,
  secondaryButton = null,
  iconBgColor = colors.red96
}) => {
  const { close } = useModalize()
  const IconComponent = icon
  return (
    <Wrapper>
      <ImageWrapper bgColor={iconBgColor}>
        <IconComponent width={72} height={72} />
      </ImageWrapper>
      <ContentWrapper>
        <H4>{title}</H4>
        {message && <ModalText>{message}</ModalText>}
        <ButtonsWrapper>
          {secondaryButton && (
            <Button
              theme="secondary"
              width="100%"
              onClick={() => {
                close()
                secondaryButton.onClick && secondaryButton.onClick()
              }}
              id={secondaryButton.id || ''}
            >
              {secondaryButton.text}
            </Button>
          )}
          {button && (
            <Button
              theme="primary"
              width="100%"
              onClick={() => {
                close()
                button.onClick && button.onClick()
              }}
              id={button.id || ''}
            >
              {button.text}
            </Button>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export const showWarningModal = createModal(WarningModal, {
  showClose: false
})

export const showWarningModalWithCloseButton = createModal(WarningModal, {
  showClose: true
})
