import { forEach } from 'ramda'
import { createModel } from '@rematch/core'

import { parseErrorMessage } from '@/utils/form'

import { RootModel } from '..'
import {
  autoLogin,
  login,
  logout,
  requestAutoLogin,
  setInitialPassword
} from './ports'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

interface InitialLoginPayload {
  username: string
  password: string
}

export interface InitialPasswordPayload {
  old_password?: string
  new_password: string
  password_confirm: string
}

const auth = createModel<RootModel>()({
  state: { askPassword: false },
  reducers: {
    update: (state, payload) => ({ ...state, ...payload }),
    clear: () => ({ askPassword: false })
  },
  effects: dispatch => ({
    async logout() {
      try {
        await logout()

        const keysToRemove = ['session-id', '@session:firstLogin']
        forEach(item => localStorage.removeItem(item), keysToRemove)

        dispatch.session.clear()
      } catch (error) {
        console.error('[auth/logout] Error Occured: ', error)
      }
    },
    async setInitialPassword(payload: InitialPasswordPayload) {
      try {
        const { response, body } = await setInitialPassword({ body: payload })
        if (!response.ok) {
          throw new Error(body.message || response.statusText)
        }
        dispatch.session.loadSession()
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async login(payload: InitialLoginPayload) {
      dispatch.loading.setLoading()
      try {
        const { response, body } = await login({ body: payload })
        if (!response.ok) {
          throw new Error(parseErrorMessage(body) || response.statusText)
        }
        const { body: sessionBody } = await dispatch.session.loadSession()
        window.dataLayer.push({
          event: `web-login-${sessionBody?.user_type}`
        })
        return { response, body }
      } catch (error) {
        return { error }
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async requestAutoLogin(payload) {
      dispatch.loading.setLoading()
      try {
        const { response, body } = await requestAutoLogin(payload)
        if (!response.ok) {
          throw new Error(parseErrorMessage(body) || response.statusText)
        }
        const email = body?.email || payload.username
        return { response, body, email }
      } catch (error) {
        return { error }
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async autoLogin(payload: string) {
      dispatch.loading.setLoading()
      const hash = encodeURIComponent(payload)
      try {
        const { response, body } = await autoLogin(undefined, { hash })
        if (!response.ok) {
          throw new Error(parseErrorMessage(body) || response.statusText)
        }
        const { body: sessionBody } = await dispatch.session.loadSession()
        return { response, body, sessionBody }
      } catch (error: any) {
        if (error?.data?.password_setup_required) throw error
        return { error }
      } finally {
        dispatch.loading.stopLoading()
      }
    }
  })
})

export default auth
