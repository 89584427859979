import { MouseEventHandler } from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import { CssUnit } from '@/types'
import { colors } from '@/styles'
import { toUnit } from '@/utils/styles'

import { ReactComponent as CloseIcon } from './close-button-icon.svg'

const ButtonWrapper = styled.button<{
  height?: CssUnit
  width?: CssUnit
  top?: CssUnit
  right?: CssUnit
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${props => toUnit(props.top)};
  right: ${props => toUnit(props.right)};
  cursor: pointer;
  height: ${props => toUnit(props.height)};
  width: ${props => toUnit(props.width)};
  padding: 0;
  margin: 0;
  border: none;
  appearance: none;
  background-color: transparent;
  z-index: 1;

  svg {
    transition: color 300ms ease-in-out;
    color: ${colors.grey56};
    pointer-events: none;
  }

  &:focus,
  &:hover {
    svg {
      color: ${colors.grey72};
    }
  }
  &:active {
    svg {
      color: ${colors.grey48};
    }
  }

  :before {
    content: '';
    position: absolute;
    border: none;
  }
`

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  width?: CssUnit
  height?: CssUnit
  top?: CssUnit
  right?: CssUnit
}

const CloseButton: React.FC<Props> = ({
  onClick = noop,
  width = 24,
  height = 24,
  top = 15,
  right = 15,
  ...rest
}) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      width={width}
      height={height}
      aria-label="Close"
      top={top}
      right={right}
      {...rest}
    >
      <CloseIcon width={14} height={14} />
    </ButtonWrapper>
  )
}

export default CloseButton
