import styled from 'styled-components'

import { colors } from '@/styles'

export const ParagraphLarge = styled.div`
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
`

export const ParagraphRegular = styled.div`
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`

export const ParagraphMedium = styled.div`
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

export const ParagraphMediumGrey = styled(ParagraphMedium)`
  color: ${colors.grey56};
`

export const ParagraphSmall = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`

export const ParagraphSmallGrey = styled(ParagraphSmall)`
  color: ${colors.grey56};
`
