import { createModel } from '@rematch/core'

import { CompanyResponse } from '@/types'
import { getCompany } from '@/ports'
import { getHeaders } from '@/utils/http'

import { RootModel } from '.'

type CompanyModel = Record<string, CompanyResponse>
const company = createModel<RootModel>()({
  name: 'company',
  state: {} as CompanyModel,
  reducers: {
    update: (state, payload: CompanyModel) => ({ ...state, ...payload })
  },
  effects: dispatch => ({
    async load(companyId, rootState, req) {
      if (rootState.company[companyId]) return
      const { body } = await getCompany(
        {
          companyId: companyId
        },
        undefined,
        getHeaders(req)
      )
      dispatch.company.update({ [companyId]: body })
    }
  })
})

export default company
