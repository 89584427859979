import { useTranslation } from 'next-i18next'

import {
  CloseIcon,
  Text,
  TextWrapper,
  Title,
  ToastIcon,
  Wrapper
} from './elements'

const Notification = ({
  type,
  themeColor,
  title,
  autoClose,
  closeToast,
  children
}) => {
  const { t } = useTranslation()

  if (autoClose) {
    setTimeout(closeToast, autoClose)
  }

  return (
    <Wrapper>
      <ToastIcon type={type} />
      <TextWrapper>
        {!!title && (
          <Title themeColor={themeColor}>
            {typeof title === 'function' ? title(t) : title}
          </Title>
        )}
        {children && <Text>{children}</Text>}
      </TextWrapper>
      <CloseIcon
        onClick={e => {
          e.stopPropagation()
          closeToast()
        }}
      />
    </Wrapper>
  )
}

export default Notification
