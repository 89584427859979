import React, { createContext, useContext, useState } from 'react'

const AlertModalContext = createContext(undefined)

export const AlertModalProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <AlertModalContext.Provider value={{ modalVisible, setModalVisible }}>
      {children}
    </AlertModalContext.Provider>
  )
}

export const useAlertModalContext = () => {
  const context = useContext(AlertModalContext)

  if (!context) {
    throw new Error(
      'useAlertModalContext must be used within a AlertModalProvider'
    )
  }

  return context
}
