import { useEffect } from 'react'

export const updateViewport = (value?: number) => {
  const height = value ?? window?.visualViewport?.height

  if (typeof document !== 'undefined' && height) {
    const vh = height * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}

export const useIOSViewport = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return
    updateViewport()
    let ticking = false
    const handleScroll = () => {
      const height = window?.visualViewport?.height
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateViewport(height)
          ticking = false
        })
        ticking = true
      }
    }
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('resize', handleScroll)
    }
  }, [])
  return null
}
