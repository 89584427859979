import ENV_VARS from '@/config'
import { get, post, put } from '@/ports/api'

const { BACKEND_SERVER } = ENV_VARS

export const login = post(`${BACKEND_SERVER}/auth/login`)
export const autoLogin = put(`${BACKEND_SERVER}/auth/login/auto/%hash%`)

export const requestAutoLogin = post(`${BACKEND_SERVER}/auth/login/auto`)

export const verifyUsername = post(`${BACKEND_SERVER}/users/verify/username`)

export const verifyPhoneLogin = post(
  `${BACKEND_SERVER}/auth/login/verify/phone`
)
export const verifyCodeLogin = post(`${BACKEND_SERVER}/auth/login/verify/code`)
export const setInitialPassword = put(`${BACKEND_SERVER}/auth/password/initial`)

export const requestResetPassword = post(
  `${BACKEND_SERVER}/auth/password/autopass`
)
export const verifyAutopassHash = get(
  `${BACKEND_SERVER}/auth/password/autopass/%hash%`,
  {
    failSilently: true
  }
)
export const autopassLogin = put(
  `${BACKEND_SERVER}/auth/password/autopass/%hash%`
)

export const verifyPhoneSignup = post(
  `${BACKEND_SERVER}/auth/registration/verify/phone`
)
export const verifyCodeSignup = post(
  `${BACKEND_SERVER}/auth/registration/verify/code`
)
export const changePassword = put(`${BACKEND_SERVER}/auth/password`)
export const validateEmail = post(`${BACKEND_SERVER}/users/email/validate`)
export const verifyEmail = post(`${BACKEND_SERVER}/users/email/verify`)
export const logout = post(`${BACKEND_SERVER}/users/logout`)

export const registerRenter = post(`${BACKEND_SERVER}/users/renter`)
export const registerLandlord = post(`${BACKEND_SERVER}/users/landlord`)
export const registerSocial = post(
  `${BACKEND_SERVER}/social_login/%platform%/sign_up`
)

export const verifyPhoneCodeLandlord = post(
  `${BACKEND_SERVER}/auth/registration/verify/phone/code`
)

export const prorataCalculator = post(
  `${BACKEND_SERVER}/plans/subscription/dry_run`
)

export const getCountryByIP = get(
  `${BACKEND_SERVER}/lookup/country_code_by_ip`,
  {
    noLoading: true
  }
)
