type Options = {
  log?: boolean
}

export const handle = <T>(
  promise: Promise<T>,
  { log }: Options = {}
): Promise<[T, undefined] | [undefined, any]> =>
  promise
    .then<[T, undefined]>(data => [data, undefined])
    .catch<[undefined, any]>(error => {
      log && console.error(error, { description: log })
      return [undefined, error]
    })
