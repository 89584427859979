import http from 'http'
import { isEmpty, pick } from 'ramda'

export const getHeaders = (
  req: http.IncomingMessage
): { headers?: Record<string, string> } => {
  if (!req || isEmpty(req)) return {}
  const forwarded = req.headers['x-forwarded-for']
  const ip = forwarded
    ? Array.isArray(forwarded)
      ? forwarded[0]
      : forwarded.split(/, /)[0]
    : req.socket.remoteAddress || ''
  return {
    headers: {
      'x-forwarded-for': ip,
      ...pick(['cookie', 'user-agent', 'pragma'], req.headers)
    }
  }
}
