import { useTranslation } from 'next-i18next'
import {
  filter,
  flatten,
  has,
  head,
  includes,
  isEmpty,
  isNil,
  keys,
  map,
  mergeRight as merge,
  omit,
  pick,
  pickBy,
  pipe,
  prop,
  sortBy,
  split,
  values,
  whereEq
} from 'ramda'

import { FILTERS } from '@/lib/filters'
import { isEmptyOrNull, wrapArray } from '@/utils'
import { parse as parseQs, stringify } from '@/utils/qs'
import { getCityAsync } from '@/hooks/use-city-search'

const listingSearchParams = [
  'featured',
  'boundaries',
  'housing_type',
  'housing_types',
  'unit_types',
  'private_entrance',
  'availability_date',
  'custom_availability_date',
  'min_price',
  'max_price',
  'bathroom_count',
  'bedroom_count',
  'pet_policy',
  'cities',
  'page',
  'page_size',
  'sort',
  'state_machine',
  'sort_by',
  'sort_type',
  'company_id',
  'building_id',
  'boundaries',
  'zoom',
  'top_left',
  'bottom_right',
  'search_only_verified',
  'min_size',
  'lease_type',
  'rental_type',
  'has_3d_tour',
  'features',
  'utilities',
  'distance',
  'location',
  'transportation'
]

export const getUrlSlugs = router => {
  const urlSlugs = wrapArray(router?.query?.slug || [])
  const slugsArray = flatten(map(split(','), urlSlugs))
  let locationSlugs = []
  let filterSlug = ''
  // check the array to gather city/neighborhood/filter slugs
  // first slug can be either a city or filter
  // second slug can be either a neighborhood or filter
  slugsArray.forEach(s => {
    if (has(s.toLowerCase(), FILTERS)) {
      filterSlug = s.toLowerCase()
    } else {
      locationSlugs.push(s.toLowerCase())
    }
  })
  return {
    citySlug: locationSlugs[0],
    neighbourhoodSlug: locationSlugs[1],
    filterSlug
  }
}

export const getFilterSlugData = ({ router, slug }) => {
  const { filterSlug } = getUrlSlugs(router)
  const key = slug || filterSlug
  return FILTERS[key] || {}
}

const getSlugFromFilters = filters => {
  const formatToStrings = obj => {
    return map(v => (isEmptyOrNull(v) ? v : v.toString()), obj)
  }
  const matchesCurrentFilters = slugData => {
    return whereEq(formatToStrings(slugData.filters), formatToStrings(filters))
  }
  const arrayOfAllCurrentSlugs = filter(matchesCurrentFilters, values(FILTERS))
  const primarySlug = pipe(
    sortBy(prop('rank')),
    head,
    prop('slug')
  )(arrayOfAllCurrentSlugs)
  return primarySlug
}

export const getSearch = async router => {
  // Get all search filter params from query
  const queryParams = pick(listingSearchParams, parseQs(router.query))

  // set cities filter based on slug or query params
  const getCities = async () => {
    // Check for "use current location" filter
    const usesLocation = includesCurrentLocation(queryParams?.cities || [])
    if (usesLocation) return [CURRENT_LOCATION]
    // Set cities filter if there is a city url slug
    const { citySlug } = getUrlSlugs(router)
    if (citySlug) {
      try {
        const data = await getCityAsync(citySlug)
        return data.name ? [data.name] : []
      } catch (error) {
        console.error('Error fetching city:', error)
        return []
      }
    }
    return []
  }

  const cities = await getCities()

  // Set other filters based on the url filter slug
  const filterSlugData = getFilterSlugData({ router })
  const filtersFromSlug = filterSlugData?.filters

  return {
    ...queryParams,
    cities: cities,
    ...filtersFromSlug
  }
}

const mergeSearch = (router, newFilter) => {
  // If search takes place on a non-city page (eg. company page) we should bypass filter slugs
  const isSearchPage = router.pathname.includes('rental-listings')
  if (!isSearchPage) {
    const currentFilter = parseQs(router.query)
    const remove = pipe(pickBy(isNil), keys)(newFilter)
    const newObj = pipe(merge(currentFilter), omit(remove))(newFilter)
    return newObj
  }
  // otherwise handle filter slug
  const filterSlugData = getFilterSlugData({ router })
  const filtersFromSlug = filterSlugData?.filters
  const filtersFromQuery = parseQs(router.query)
  // merge all current filters together from slug and query
  const currentFilters = merge(
    omit(['slug'], filtersFromQuery),
    filtersFromSlug
  )
  // find any new filters that are null or empty so we can remove
  const newNullValues = pipe(pickBy(isEmptyOrNull), keys)(newFilter)
  // merge current and new filters (new filters overwrite current)
  const updatedFilters = pipe(
    merge(currentFilters),
    omit(newNullValues)
  )(newFilter)
  // check if any filter slugs apply to the updated filters
  const newFilterSlug = getSlugFromFilters(updatedFilters)
  // update the city/filter slugs
  const { citySlug, neighbourhoodSlug } = getUrlSlugs(router)
  const newSlugsArray = [citySlug, neighbourhoodSlug, newFilterSlug].filter(
    s => !isEmptyOrNull(s)
  )
  const updatedFiltersWithSlug = { ...updatedFilters, slug: newSlugsArray }
  // then update the filter slug if needed
  if (newFilterSlug) {
    const newFilterSlugData = getFilterSlugData({ slug: newFilterSlug })
    // and prevent duplicate filters in the query when a slug exists
    // eg /pet-friendly and ?pet_policy=ALLOWED
    const omitKeys = isEmptyOrNull(newFilterSlugData)
      ? []
      : Object.keys(newFilterSlugData.filters)
    const updatedFiltersWithoutDuplicates = omit(
      omitKeys,
      updatedFiltersWithSlug
    )
    return updatedFiltersWithoutDuplicates
  }
  // otherwise, if no filter slug applies to the new filters, just return the filters
  return updatedFiltersWithSlug
}

export const pushSearch = (router, newFilter, path, options = {}) => {
  // Canada page and city pages have different path for city/filter slugs
  const isCityPage = router.pathname.includes('rental-listings/city')
  const slugPath = isCityPage ? '/[...slug]' : '/[slug]'

  const newSearch = mergeSearch(router, newFilter)
  const filtersWithoutSlug = omit(['slug'], newSearch)
  const pathname = path || router.pathname

  // If search includes slugs (city, filters), we need to adjust the path to include these
  const getPathname = () => {
    const newSlugs = newSearch.slug
    // If Canada page we must change routes from /[slug] to / when all filters are removed
    if (isEmpty(newSlugs) && !isCityPage) return pathname.replace(slugPath, '')

    if (newSlugs?.length > 0) {
      const pathWithoutSlug = pathname.replace(slugPath, '')
      return `${pathWithoutSlug}/${newSlugs.join('/')}`
    }
    return pathname
  }

  const fromUrl = filters =>
    `${getPathname()}${!isEmpty(filters) ? `?${stringify(filters)}` : ''}`
  const nextUrl = fromUrl(filtersWithoutSlug)
  const as = fromUrl(omit(['fromAlert'], filtersWithoutSlug))
  if (options?.target === '_blank') {
    if (typeof window !== 'undefined') window.open(nextUrl, options.target)
    return
  } else {
    router.push(nextUrl, nextUrl !== as ? as : undefined, {
      shallow: !path
    })
  }
}

export const CURRENT_LOCATION = 'Current location'

export const includesCurrentLocation = includes(CURRENT_LOCATION)

// NOTE: at the moment this only translates `Current location`
export const useTranslatedCities = cities => {
  const { t } = useTranslation()
  const label = t(
    'b.search.view.use_current_location.label',
    'Current location'
  )
  if (!cities) return cities
  return cities.map(city => {
    if (city?.label === CURRENT_LOCATION) return { ...city, label }
    if (city === CURRENT_LOCATION) return label
    return city
  })
}
