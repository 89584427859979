import { useRouter } from 'next/router'
import styled, { keyframes } from 'styled-components'

import { breakpoints, colors } from '@/styles'

import Notification from './Notification'
import Progress from './Progress'

const slideIn = keyframes`
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 345px;
  border: 1px solid ${colors.grey88};
  border-radius: 6px;
  background-color: ${colors.white};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 16px 16px 16px 10px;
  margin-bottom: 20px;
  z-index: 25;
  border-left: 6px solid ${props => props.themeColor};
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  animation-duration: 0.3s;
  animation-name: ${slideIn};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 330px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    width: calc(100vw - 50px);
  }

  ${props =>
    props.hasLink &&
    `
    cursor: pointer;
  `}
`

const COMPONENTS = {
  default: Notification,
  progress: Progress
}

const COLORS = {
  success: colors.green48,
  info: colors.blue48,
  warning: colors.yellow48,
  error: colors.red48,
  progress: colors.blue48
}

const getComponent = toastType => COMPONENTS[toastType] || Notification

const ToastItem = ({ type, content, link = '', ...rest }) => {
  const router = useRouter()
  const Component = getComponent(type)
  const themeColor = COLORS[type]

  const handleLinkClick = () => {
    if (link) {
      router.push(link)
      rest.closeToast()
    }
  }

  return (
    <Wrapper onClick={handleLinkClick} hasLink={link} themeColor={themeColor}>
      <Component type={type} themeColor={themeColor} {...rest}>
        {content}
      </Component>
    </Wrapper>
  )
}

export default ToastItem
