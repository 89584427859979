interface ParsedQs {
  [key: string]: undefined | string | string[] | ParsedQs | ParsedQs[]
}

export const stringify = (obj: any): string => {
  if (typeof obj === 'undefined') return ''
  if (typeof obj === 'string') return obj as string
  const url = new URLSearchParams()
  if (typeof obj !== 'object') return url.toString()
  for (const key in obj) {
    const value = obj[key]
    if (Array.isArray(value)) {
      value.length > 0 && url.append(key, value.toString())
    } else if (typeof value === 'object') {
      for (const k in value) {
        url.append(`${key}[${k}]`, value[k])
      }
    } else {
      url.append(key, value)
    }
  }
  return url.toString()
  // using decodeURIComponent to prevent commas from displaying as "%2C", but also breaks "+" symbol :-/
  // return decodeURIComponent(url.toString())
}

type Sort = {
  sort_by?: string
  sort_type?: string
}

const nested = ['sort[sort_by]', 'sort[sort_type]']

const asObject = (obj: any): ParsedQs => {
  if (typeof obj !== 'object') return obj as ParsedQs
  const next = { ...obj }
  const sort: Sort = {}
  for (const key in next) {
    if (nested.indexOf(key) >= 0) {
      const newKey = 'sort[sort_by]' === key ? 'sort_by' : 'sort_type'
      const value = next[key]
      sort[newKey] = value
      next['sort'] = sort
      delete next[key]
    }
  }
  return next
}

export const parse = (str: any): ParsedQs => {
  const sort: Sort = {}
  const obj: ParsedQs = {}
  if (typeof str === 'undefined') return obj
  if (typeof str !== 'string') return asObject(str)
  const url = new URLSearchParams(str)
  url.forEach((value, key) => {
    if (key in obj) {
      const current = obj[key]
      if (nested.indexOf(key) >= 0) {
        const newKey = 'sort[sort_by]' === key ? 'sort_by' : 'sort_type'
        sort[newKey] = value
        obj['sort'] = sort
      } else if (
        !Array.isArray(current) &&
        typeof current !== 'object' &&
        typeof current !== 'undefined'
      ) {
        obj[key] = [current]
      }
      const toPush = obj[key] as any
      toPush.push(value)
    } else {
      obj[key] = value
    }
  })
  return obj
}
