import {
  ComponentType,
  useCallback,
  useEffect,
  useReducer,
  useRef
} from 'react'
import styled from 'styled-components'

import structure from '@/structure'
import { breakpoints, zIndex } from '@/styles'

import ACTION from '../utils/action'
import eventManager from '../utils/eventManager'
import ToastItem from './ToastItem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

const Container = styled.div`
  position: fixed;
  top: ${structure.header.height + 20}px;
  right: 30px;
  align-self: flex-end;
  z-index: ${zIndex.toast};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    top: ${structure.header.heightMobile + 16}px;
    right: 25px;
  }
`

const ADD = 0
const UPDATE = 1
const REMOVE = 2
const CLEAR = 3

type ReducerState = Array<string | number>
type ReducerAction =
  | { type: typeof ADD | typeof REMOVE; data: { id: string | number } }
  | { type: typeof UPDATE; data: { staleToastId: string | number } }
  | { type: typeof CLEAR }

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case ADD:
      return [...state, action.data.id]
    case UPDATE:
      return [...state].filter(id => id !== action.data.staleToastId)
    case REMOVE:
      return state.filter(id => id !== action.data.id)
    case CLEAR:
      return []
    default:
      return state
  }
}

type Id = string | number

type ParsedToastOptions = Omit<
  ToastOptions,
  'progress' | 'staleToastId' | 'toastId'
> & {
  id: Id
  progress: number
}
interface ToastOptions {
  toastId: Id
  staleToastId: Id
  delay?: number
  updateId: string
  key: number
  type: string
  title?: string
  link?: string
  closeToast: () => void
  onClick: () => void
  onOpen?: () => void
  onClose?: () => void
  pauseOnHover?: boolean
  pauseOnFocusLoss?: boolean
  closeOnClick?: boolean
  autoClose?: boolean
  hideProgressBar?: boolean
  progress: string
  role?: string
}

type Collection = Record<string, { options: ParsedToastOptions; content: any }>
type ContentFunction = (a: { closeToast: () => void }) => any
type ContentType = ComponentType | ContentFunction

function isContentFunction(content: ContentType): content is ContentFunction {
  return typeof content === 'function'
}
const ToastContainer = ({
  containerId = 'toast-notification-container',
  ...props
}) => {
  const [data, dispatch] = useReducer(reducer, [])
  const toastRef = useRef(null)
  const collection = useRef<Collection>({})
  const toastKey = useRef(1)

  const appendToast = (
    options: ParsedToastOptions,
    content: any,
    staleToastId: string | number
  ) => {
    const { id, updateId } = options
    collection.current = {
      ...collection.current,
      [id]: {
        options,
        content
      }
    }

    updateId
      ? dispatch({
          type: UPDATE,
          data: { staleToastId }
        })
      : dispatch({
          type: ADD,
          data: { id }
        })
  }

  const buildToast = useCallback(
    (content: ContentType, { delay, ...options }: ToastOptions) => {
      let contentToRender: any = content
      const { toastId } = options

      const closeToast = () => removeToast(toastId)
      const toastOptions: ParsedToastOptions = {
        id: toastId,
        key: options.key || toastKey.current++,
        type: options.type,
        title: options.title || props.title,
        link: options.link || props.link,
        closeToast: closeToast,
        updateId: options.updateId,
        onClick: options.onClick || props.onClick,
        pauseOnHover:
          typeof options.pauseOnHover === 'boolean'
            ? options.pauseOnHover
            : props.pauseOnHover,
        pauseOnFocusLoss:
          typeof options.pauseOnFocusLoss === 'boolean'
            ? options.pauseOnFocusLoss
            : props.pauseOnFocusLoss,
        closeOnClick:
          typeof options.closeOnClick === 'boolean'
            ? options.closeOnClick
            : props.closeOnClick,
        autoClose: options.autoClose || props.autoClose,
        hideProgressBar:
          typeof options.hideProgressBar === 'boolean'
            ? options.hideProgressBar
            : props.hideProgressBar,
        progress: parseFloat(options.progress || '0'),
        role: typeof options.role === 'string' ? options.role : props.role
      }

      typeof options.onOpen === 'function' &&
        (toastOptions.onOpen = options.onOpen)

      typeof options.onClose === 'function' &&
        (toastOptions.onClose = options.onClose)

      if (isContentFunction(content)) {
        contentToRender = content({ closeToast })
      }

      if (delay) {
        setTimeout(
          () =>
            appendToast(toastOptions, contentToRender, options.staleToastId),
          delay
        )
      } else {
        appendToast(toastOptions, contentToRender, options.staleToastId)
      }
    },
    [
      props.autoClose,
      props.closeOnClick,
      props.hideProgressBar,
      props.onClick,
      props.pauseOnFocusLoss,
      props.pauseOnHover,
      props.role,
      props.title,
      props.link
    ]
  )

  const clear = () => dispatch({ type: CLEAR })

  const removeToast = (id: string | number) =>
    dispatch({ type: REMOVE, data: { id } })

  useEffect(() => {
    eventManager
      .cancelEmit(ACTION.WILL_UNMOUNT)
      .subscribe(ACTION.SHOW, buildToast)
      .subscribe(ACTION.CLEAR, id => (id == null ? clear() : removeToast(id)))
      .emit(ACTION.DID_MOUNT, containerId, collection)
    return () => eventManager.emit(ACTION.WILL_UNMOUNT, containerId)
  }, [buildToast, containerId])

  const renderToast = () => {
    const toasts = Object.values(collection.current)
    return toasts.map(({ options, content }) => {
      if (data.indexOf(options.id) !== -1) {
        return (
          <ToastItem
            {...options}
            content={content}
            key={`toast-${options.key}`}
          />
        )
      } else {
        delete collection.current[options.id]
        return null
      }
    })
  }

  return (
    <Wrapper id={containerId} ref={toastRef}>
      <Container>{renderToast()}</Container>
    </Wrapper>
  )
}

export default ToastContainer
