/* eslint-disable complexity */
const GMAPS_KEY = 'AIzaSyDbJ3A2XXOzEfBcNJA2pBMYm950nKy3CXQ'

const defaultVars = {
  MAPBOX_TOKEN:
    process.env.NEXT_PUBLIC_MAPBOX_TOKEN ||
    'pk.eyJ1IjoibWJzYWRtaW4iLCJhIjoiY2l5ejNkamtoMDAwNjMzcGFzNzluZTIyeiJ9.-k7p1OeezR1j2y0kV-CxJg',
  HERE_MAPS_API_KEY: 'WnIMkeH0w8zJA3A-hfn_gUOc11o7nY--rI6qUTZPeDI',
  HERE_MAPS_GEOCODER_SERVER: 'https://geocoder.api.here.com/6.2/geocode.json',
  HERE_MAPS_REVERSE_GEOCODER_SERVER:
    'https://reverse.geocoder.api.here.com/6.2/reversegeocode.json',
  TURNSTILE_SITE_KEY: process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY,
  ENABLE_CONTACT_FORM_CAPTCHA:
    process.env.NEXT_PUBLIC_ENABLE_CONTACT_FORM_CAPTCHA ||
    process.env.NEXT_PUBLIC_BUILD_ENV === 'prod',
  ENABLE_REGISTER_FORM_CAPTCHA:
    process.env.NEXT_PUBLIC_ENABLE_CREGISTER_FORM_CAPTCHA ||
    process.env.NEXT_PUBLIC_BUILD_ENV === 'prod'
}

type Env = 'test' | 'dev' | 'dev2' | 'dev3' | 'stg' | 'qa' | 'prod'

type ToggleConfig = {
  enabled: boolean
}
type Config = {
  BACKEND_SERVER: string
  GRAPHQL_SERVER: string
  DASHBOARD_URL: string
  BASE_URL: string
  IMAGES_URL: string
  VIDEOS_URL: string
  NCHAN_SERVER: string
  GMAPS_KEY: string
  MAP_SERVER: string
  GA: ToggleConfig
  RELEASE_VERSION: string
  SENTRY_DSN: string
  FACEBOOK_APP_ID: string
  USERPILOT_KEY: string
} & typeof defaultVars

const envs: Record<Env, Partial<Config>> = {
  test: {},
  dev: {
    ...defaultVars,
    BACKEND_SERVER:
      process.env.NEXT_PUBLIC_BACKEND_SERVER || 'https://be-dev2.machobear.ca',
    GRAPHQL_SERVER:
      process.env.NEXT_PUBLIC_GRAPHQL_SERVER ||
      'https://nemesis-dev.machobear.ca/graphql',
    DASHBOARD_URL:
      process.env.NEXT_PUBLIC_DASHBOARD_URL ||
      'https://trustliv-dev.machobear.ca/app',
    BASE_URL: 'https://trustliv-dev.machobear.ca',
    IMAGES_URL: 'https://cdn-dev.machobear.ca',
    VIDEOS_URL: 'https://cdn-dev-videos.machobear.ca',
    NCHAN_SERVER:
      process.env.NCHAN_SERVER || 'https://nchan-dev2.machobear.ca/sse',
    GMAPS_KEY: process.env.NEXT_PUBLIC_GMAPS_KEY || GMAPS_KEY,
    MAP_SERVER: 'https://maps-dev.liv.rent',
    FACEBOOK_APP_ID: process.env.NEXT_FACEBOOK_APP_ID || '144273570564908',
    USERPILOT_KEY: 'STG-NX-495b3a0b'
  },
  dev2: {
    ...defaultVars,
    BACKEND_SERVER:
      process.env.NEXT_PUBLIC_BACKEND_SERVER ||
      'https://chaos2-dev.machobear.ca',
    GRAPHQL_SERVER:
      process.env.NEXT_PUBLIC_GRAPHQL_SERVER ||
      'https://nemesis-dev.machobear.ca/graphql',
    DASHBOARD_URL: 'https://trustliv2-dev.machobear.ca/app',
    BASE_URL: 'https://kratos2-dev.machobear.ca',
    IMAGES_URL: 'https://cdn-dev.machobear.ca',
    VIDEOS_URL: 'https://cdn-dev-videos.machobear.ca',
    NCHAN_SERVER:
      process.env.NCHAN_SERVER || 'https://nchan-dev.machobear.ca/sse',
    GMAPS_KEY: process.env.NEXT_PUBLIC_GMAPS_KEY || GMAPS_KEY,
    MAP_SERVER: 'https://maps-dev.liv.rent',
    FACEBOOK_APP_ID: process.env.NEXT_FACEBOOK_APP_ID || '144273570564908',
    USERPILOT_KEY: 'STG-NX-495b3a0b'
  },
  dev3: {
    ...defaultVars,
    BACKEND_SERVER:
      process.env.NEXT_PUBLIC_BACKEND_SERVER ||
      'https://chaos-dev.machobear.ca',
    GRAPHQL_SERVER:
      process.env.NEXT_PUBLIC_GRAPHQL_SERVER ||
      'https://nemesis-dev.machobear.ca/graphql',
    DASHBOARD_URL: 'https://trustliv3-dev.machobear.ca/app',
    BASE_URL: 'https://kratos3-dev.machobear.ca',
    IMAGES_URL: 'https://cdn-dev.machobear.ca',
    VIDEOS_URL: 'https://cdn-dev-videos.machobear.ca',
    NCHAN_SERVER:
      process.env.NCHAN_SERVER || 'https://nchan-dev.machobear.ca/sse',
    GMAPS_KEY: process.env.NEXT_PUBLIC_GMAPS_KEY || GMAPS_KEY,
    MAP_SERVER: 'https://betacdn.liv.rent',
    FACEBOOK_APP_ID: process.env.NEXT_FACEBOOK_APP_ID || '144273570564908',
    USERPILOT_KEY: 'STG-NX-495b3a0b'
  },
  stg: {
    ...defaultVars,
    BACKEND_SERVER:
      process.env.NEXT_PUBLIC_BACKEND_SERVER || 'https://be-stg.machobear.ca',
    GRAPHQL_SERVER: process.env.NEXT_PUBLIC_GRAPHQL_SERVER,
    DASHBOARD_URL: 'https://trustliv-dev.machobear.ca/app',
    IMAGES_URL: 'https://d3j1zuo7506rra.cloudfront.net',
    NCHAN_SERVER:
      process.env.NCHAN_SERVER || 'https://nchan-dev2.machobear.ca/sse',
    GMAPS_KEY: process.env.NEXT_PUBLIC_GMAPS_KEY || GMAPS_KEY,
    MAP_SERVER: 'https://maps-dev.liv.rent',
    GA: {
      enabled: false
    },
    FACEBOOK_APP_ID: process.env.NEXT_FACEBOOK_APP_ID || '144273570564908',
    USERPILOT_KEY: 'STG-NX-495b3a0b'
  },
  qa: {
    ...defaultVars,
    USERPILOT_KEY: 'STG-NX-495b3a0b',
    GA: {
      enabled: false
    }
  },
  prod: {
    ...defaultVars,
    BACKEND_SERVER:
      process.env.NEXT_PUBLIC_BACKEND_SERVER || 'https://be-prod.liv.rent',
    GRAPHQL_SERVER:
      process.env.NEXT_PUBLIC_GRAPHQL_SERVER ||
      'https://nemesis-prod.liv.rent/graphql',
    DASHBOARD_URL: 'https://liv.rent/app',
    BASE_URL: 'https://liv.rent',
    IMAGES_URL: 'https://cdn.liv.rent',
    VIDEOS_URL: 'https://cdn-videos.liv.rent',
    NCHAN_SERVER: process.env.NCHAN_SERVER || 'https://sse.liv.rent/sse',
    GMAPS_KEY: process.env.NEXT_PUBLIC_GMAPS_KEY || GMAPS_KEY,
    MAP_SERVER: process.env.NEXT_PUBLIC_MAPS_SERVER || 'https://maps.liv.rent',
    RELEASE_VERSION: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    SENTRY_DSN: 'https://0ad2baf14d2b4260adff37e844f881af@sentry2.liv.rent/6',
    FACEBOOK_APP_ID: process.env.NEXT_FACEBOOK_APP_ID,
    USERPILOT_KEY: 'NX-495b3a0b'
  }
}

const targetEnv = getEnv(process.env.NEXT_PUBLIC_BUILD_ENV)
const config = {
  ...envs[targetEnv],
  BUILD_ENV: targetEnv
}
export default config

function getEnv(env?: string): Env {
  if (isValidEnv(env)) return env
  return 'dev'
}

function isValidEnv(env?: string): env is Env {
  return !!env && env in envs
}
