export const themeProviderOptions = {
  defaultMessages: {
    Table: {
      missingValue: 'No data available',
      noResultsDescription: 'No results found',
      noResultsTitle: 'No results found'
    },
    Modal: {
      closeButtonLabel: 'Close'
    },
    DateField: {
      nextMonthLabel: 'Next Month',
      previousMonthLabel: 'Previous Month',
      actionFieldLabel: 'Select Date'
    },
    SelectField: {
      noOptionsMessage: 'No options found',
      multiOptionsSelected: (count: string | number) => `${count} selected`,
      selectAllButtonLabel: 'Select All',
      clearAllButtonLabel: 'Clear All'
    },
    TimeField: {
      actionFieldLabel: 'Select Time'
    }
  }
}
