import type { GetServerSidePropsContext } from 'next'
import { isEmpty, lensPath, pick, set } from 'ramda'
import { createModel } from '@rematch/core'
import { batch } from 'react-redux'

import { Product } from '@/types'
import { getSession, publicChatSession, updateSession } from '@/ports'
import { getHeaders } from '@/utils/http'
import { OldPlanName } from '@/features/Pricing/pricing-plans'

import { RootModel } from '..'

const setGA4UserData = (
  userId?: string,
  userType: string = 'nonlogged',
  accountType?: string
) =>
  window.dataLayer.push({
    event: 'setUserData',
    pageType: 'kratos',
    userId,
    userType,
    accountType
  })

const setGA4NonLoggedUserData = () =>
  window.dataLayer.push({
    event: 'setUserData',
    pageType: 'kratos',
    userId: undefined,
    userType: 'nonlogged',
    accountType: undefined
  })

class SessionError extends Error {
  data: unknown = null
  constructor(data: unknown) {
    super()
    this.data = data
  }
}

interface Avatar {
  aws_s3_key: string
}

type CompanyInfo = {
  base_plan_name: Product | 'business-plan-special'
  billing_user_id: number
  subscription_id?: string
}

export type Session = {
  username: string
  user_type?: string
  first_name: string
  last_name: string
  preferred_name?: string
  id: string
  registration_complete: '1' | '0'
  public?: unknown
  error: unknown | null
  uuid: string
  avatar?: Avatar
  account_type: OldPlanName
  company_info: CompanyInfo
  user_role: string
  loaded?: boolean
  intended_plan?: string
  phone_country_code_alpha?: string
  phone?: string
  phone_number?: string
  setting_language?: string
  created_at?: string
  liv_verified_state?: string
  identity_verified_state?: string
}

const TOKEN_NAME = 'X-Token'
const session = createModel<RootModel>()({
  state: {} as Session,
  reducers: {
    set: (state, payload: Partial<Session>) => ({
      ...state,
      ...payload,
      error: null
    }),
    update: (state, at, payload) => set(lensPath(at), payload, state),
    updateFromConfirm: (state, payload) => ({
      ...state,
      public: pick(['first_name', 'last_name', 'username'], payload),
      error: null
    }),
    clearData: () => ({} as Session)
  },
  effects: dispatch => ({
    clear() {
      batch(() => {
        dispatch.session.clearData()
        dispatch.chat.clearData()
        dispatch.listings.clearData()
        dispatch.agreement.clearData()
        dispatch.plans.clear()
        dispatch.flags.clear()
      })
      setGA4NonLoggedUserData()
    },
    loadSession: async (
      opts?,
      rootState?,
      req?: GetServerSidePropsContext['req']
    ) => {
      const current = rootState?.session as { user_type?: string }
      if (req && !req.cookies[TOKEN_NAME])
        return { user_type: current?.user_type }
      if (rootState?.session?.id) return {}
      const { response, body } = await getSession(undefined, undefined, {
        ...(opts || {}),
        ...(req ? getHeaders(req) : {})
      })
      if (!response.ok) {
        dispatch.session.set({ loaded: true })
        setGA4NonLoggedUserData()
        throw new SessionError(body)
      }

      dispatch.session.set({ ...body, loaded: true })

      localStorage.setItem('session-id', body.id)
      setGA4UserData(body.id, body.user_type, body.account_type)
      return { body }
    },
    async updateSession(payload) {
      const data = {
        body: payload
      }
      try {
        const { response, body } = await updateSession(data)
        if (!response.ok) {
          throw new SessionError(body)
        }
        dispatch.session.set({ ...body, loaded: true })
        return response
      } catch (error) {
        return error
      }
    },
    async loadChatPublicSession(_, rootState) {
      if (!isEmpty(rootState.session.public || {})) return {}
      try {
        const { response, body } = await publicChatSession()
        if (!response.ok) {
          throw new SessionError(body)
        }
        dispatch.session.set({ public: body })
        return { response, body }
      } catch (error) {
        dispatch.session.set({ public: {} })
        return { error }
      }
    }
  })
})

export default session
