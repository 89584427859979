import '@machobear/design-system/reset'

import React, { Suspense, useState } from 'react'
import { AppProps } from 'next/app'
import { RouterContext } from 'next/dist/shared/lib/router-context.shared-runtime'
import Router from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider, ToastProvider } from '@machobear/design-system'
import { lakshmi as theme } from '@machobear/design-system/themes/lakshmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { CssUnit } from '@/types'
import { initializeApollo } from '@/lib/apollo'
import { initializeStore } from '@/lib/redux'
import { themeProviderOptions } from '@/lib/themes'
import { Modal, ModalProps } from '@/components/modal'

import { ModalizeProvider } from './context'

type SelectedModalProps = Omit<ModalProps, 'children'>

type ModalProviderProps = {
  dispose: () => void
  children: React.ReactNode
  modalProps: SelectedModalProps
}

const ModalWithProvider: React.FC<ModalProviderProps> = ({
  dispose,
  children,
  modalProps
}) => {
  const [width, setWidth] = useState<CssUnit>()
  const [height, setHeight] = useState<CssUnit>()
  const {
    width: staticWidth,
    height: fullHeight,
    ...modalPropsRest
  } = modalProps
  return (
    <ModalizeProvider value={{ dispose, setWidth, setHeight }}>
      <Modal
        {...modalPropsRest}
        width={width ?? staticWidth}
        height={height ?? fullHeight}
      >
        {children}
      </Modal>
    </ModalizeProvider>
  )
}

type Options = SelectedModalProps & {
  unmountDelay?: number
  mountingNode?: HTMLElement
  id?: string
}

type ModalOptions = SelectedModalProps

// For translations, we actually don't need the router
type TranslateProps = Omit<AppProps, 'router'> & { router: any }

const createModal = <T extends {}>(
  Component: React.ComponentType<T>,
  options?: Options
) => {
  const queryClient = new QueryClient()
  const Translate: React.FC<AppProps> = ({ Component }) => <Component />
  const TranslatedComponent: React.FC<TranslateProps> =
    appWithTranslation(Translate)
  return (props: T, { ...modalOptions }: ModalOptions = {}) => {
    const {
      id,
      unmountDelay = 100,
      mountingNode,
      ...modalProps
    } = options ?? {}
    const theNode = mountingNode || document.body
    const wrapper = theNode.appendChild(document.createElement('div'))
    id && wrapper.setAttribute('id', id)
    wrapper.setAttribute('aria-modal', 'true')

    const store = initializeStore()
    const apolloClient = initializeApollo()
    const pageProps =
      typeof window !== 'undefined' ? window.__NEXT_DATA__.props.pageProps : {}
    const root = createRoot(wrapper)
    try {
      root.render(
        <Suspense fallback={null}>
          <RouterContext.Provider value={Router}>
            <ApolloProvider client={apolloClient}>
              <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                  <React.StrictMode>
                    <ModalWithProvider
                      dispose={dispose}
                      modalProps={{ ...modalProps, ...modalOptions }}
                    >
                      <ThemeProvider
                        theme={theme}
                        options={themeProviderOptions}
                      >
                        <ToastProvider>
                          <TranslatedComponent
                            router={Router}
                            pageProps={pageProps}
                            Component={() => <Component {...props} />}
                          />
                        </ToastProvider>
                      </ThemeProvider>
                    </ModalWithProvider>
                  </React.StrictMode>
                </Provider>
              </QueryClientProvider>
            </ApolloProvider>
          </RouterContext.Provider>
        </Suspense>
      )
    } catch (e) {
      console.error(e)
      throw e
    }

    function dispose() {
      setTimeout(() => {
        try {
          root.unmount()
          theNode.removeChild(wrapper)
        } catch (err) {
          // ignore
        }
      }, unmountDelay)
    }

    return dispose
  }
}

export default createModal
