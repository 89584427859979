interface Event {
  (...args: any): void
}

const eventManager = {
  off: () => {},
  list: new Map<string | number, Event[]>(),
  emitQueue: new Map<string | number, NodeJS.Timeout[]>(),
  subscribe(event: string | number, callback: Event) {
    this.list.has(event) || this.list.set(event, [])
    this.list.get(event)?.push(callback)
    return this
  },
  unsubscribe(event: string | number) {
    this.list.delete(event)
    return this
  },
  cancelEmit(event: string | number) {
    const timers = this.emitQueue.get(event)
    if (timers) {
      timers.forEach(timer => clearTimeout(timer))
      this.emitQueue.delete(event)
    }

    return this
  },
  emit(event: string | number, ...args: any) {
    this.list.has(event) &&
      this.list.get(event)!.forEach(callback => {
        const timer = setTimeout(() => {
          callback(...args)
        }, 0)

        this.emitQueue.has(event) || this.emitQueue.set(event, [])
        this.emitQueue.get(event)!.push(timer)
      })
  }
}

export default eventManager
