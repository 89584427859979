import styled from 'styled-components'

import { colors } from '@/styles'
import { ParagraphMedium } from '@/components/text'

import { ReactComponent as CloseIcon } from '../images/close-icon.svg'
import { ReactComponent as ErrorIcon } from '../images/error-icon.svg'
import { ReactComponent as InfoIcon } from '../images/info-icon.svg'
import { ReactComponent as SuccessIcon } from '../images/success-icon.svg'
import { ReactComponent as WarningIcon } from '../images/warning-icon.svg'

export { CloseIcon }

export const ToastIcon = ({ type }) => {
  if (!type) return null
  const ICONS = {
    success: <SuccessIcon />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />
  }
  const IconComponent = ICONS[type]
  return IconComponent
}

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;

  svg {
    height: 20px;
    width: 20px;
    flex-shrink: 0;

    :last-of-type {
      cursor: pointer;
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
`

export const Title = styled(ParagraphMedium)`
  color: ${colors.grey08};
  font-weight: 500;
`

export const Text = styled(ParagraphMedium)`
  color: ${colors.grey16};
  word-break: break-word;

  strong {
    font-weight: 500;
  }
`
