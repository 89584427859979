import {
  BookViewingResponse,
  CompanyResponse,
  DeleteFlagResponse,
  GetFlagResponse,
  GroupUnitsResponse,
  ListingResponse,
  Plan,
  PromoListingsResponse,
  TimelinesResponse,
  TimelineStatsResponse,
  UpdateListingResponse
} from '@/types'
import ENV_VARS from '@/config'
import { OldPlanName } from '@/features/Pricing/pricing-plans'

import { del, get, post, put } from './api'

const { BACKEND_SERVER, HERE_MAPS_API_KEY } = ENV_VARS

export const buildRequest = <T>(url: string) =>
  get<T>(`${BACKEND_SERVER}/${url}`, {
    noLoading: true
  })()

// AUTH
export const verifyUserEmail = put(`${BACKEND_SERVER}/auth/verify/email/%hash%`)
export const verifyUserContinue = put(
  `${BACKEND_SERVER}/auth/verify/continue/%hash%`
)
export const verifyPhoneSignup = post(
  `${BACKEND_SERVER}/auth/registration/verify/phone`
)
export const verifyCodeSignup = post(
  `${BACKEND_SERVER}/auth/registration/verify/code`
)

interface SessionResponse {
  id: string
  user_type: 'tenant' | 'landlord'
  template?: string
  username?: string
  account_type?: OldPlanName
}

// SESSION
export const getSession = get<SessionResponse>(`${BACKEND_SERVER}/users/me`)
export const updateSession = put<SessionResponse>(`${BACKEND_SERVER}/users/me`)

// USER FLAGS
export const createFlag = put<GetFlagResponse>(`${BACKEND_SERVER}/flags/users`)
export const updateFlag = put<GetFlagResponse>(
  `${BACKEND_SERVER}/flags/users/%id%`
)
export const getFlag = get<GetFlagResponse>(
  `${BACKEND_SERVER}/flags/users/%flagName%`
)
export const deleteFlag = del<DeleteFlagResponse>(
  `${BACKEND_SERVER}/flags/users/%flagId%`
)

// CHAT
export const startPublicChat = post(
  `${BACKEND_SERVER}/web/listings/%listingId%/public_chat`
)
export const publicChatSession = get(`${BACKEND_SERVER}/users/public/me`)
export const getTimelineStats = get<TimelineStatsResponse>(
  `${BACKEND_SERVER}/timeline/stats`
)
export const getTimelines = get<TimelinesResponse>(`${BACKEND_SERVER}/timeline`)
export const tenantStartChat = post(
  `${BACKEND_SERVER}/timeline/listings/%listingId%`
)
export const bookViewing = post<BookViewingResponse>(
  `${BACKEND_SERVER}/appointments/%timeslotId%/subscribe`
)

// LISTINGS
export const getListing = get<ListingResponse>(
  `${BACKEND_SERVER}/web/listings/%listingId%`
)
export const updateListing = put<UpdateListingResponse>(
  `${BACKEND_SERVER}/web/listings/%listingId%`
)
interface ActivateListingResponse {
  message: string
}
export const activateListing = put<ActivateListingResponse>(
  `${BACKEND_SERVER}/auth/verify/expired_listing/%hash%`
)
export const getListingShare = get<ListingResponse>(
  `${BACKEND_SERVER}/web/listings/share/%hash%/%listingId%`
)
export const getGroupListings = get<GroupUnitsResponse>(
  `${BACKEND_SERVER}/web/buildings/%buildingId%/group_units`
)
export const filterListings = post(`${BACKEND_SERVER}/web/listings/search`)

export const applyForListing = post(
  `${BACKEND_SERVER}/web/group_listing/%listingId%/user/%phone%`
)
export const getAvailableCities = get(
  `${BACKEND_SERVER}/listings/available_cities/%countyCode%`,
  { noLoading: true }
)
export const getCompany = get<CompanyResponse>(
  `${BACKEND_SERVER}/companies/%companyId%`
)
export const getCompanyListings = post(
  `${BACKEND_SERVER}/web/listings/company/%companyId%/search`
)
export const logListingView = put(`${BACKEND_SERVER}/l/listings/%listingId%`, {
  noLoading: true
})
export const getPromoListings = get<PromoListingsResponse>(
  `${BACKEND_SERVER}/listings/promo`
)
export const getHasListings = get(`${BACKEND_SERVER}/rpc/userHasListings`)

// HERE MAPS
export const geoCoding = get(
  `https://geocode.search.hereapi.com/v1/geocode?apiKey=${HERE_MAPS_API_KEY}`,
  { credentials: 'omit', noLoading: true }
)
export const reverseGeoCoding = get(
  `https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=${HERE_MAPS_API_KEY}`,
  { credentials: 'omit', noLoading: true, defaultHeaders: {} }
)
export const autoComplete = get(
  `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${HERE_MAPS_API_KEY}&country=can`,
  { credentials: 'omit', noLoading: true }
)

// CONTACT
export const reportProblem = post(`${BACKEND_SERVER}/problem_reports/create`)
export const bookLandlordDemo = post(`${BACKEND_SERVER}/appointments/book/demo`)

// FAVOURITES
export const favouritesList = get(`${BACKEND_SERVER}/listings/favourites`)
export const favouriteListing = put(
  `${BACKEND_SERVER}/listings/%listingId%/favourites`
)
export const removeFavouriteListing = del(
  `${BACKEND_SERVER}/listings/%listingId%/favourites`
)

// AGREEMENTS
export const getAgreements = post(`${BACKEND_SERVER}/agreements`)
export const getAgreement = get(`${BACKEND_SERVER}/agreements/%agreementId%`)
export const agreeToAgreements = put(`${BACKEND_SERVER}/users/agreements/agree`)
export const getUserAgreements = get(`${BACKEND_SERVER}/users/me/agreements`)

// MISC
export const updateUserSearchPreferences = put(
  `${BACKEND_SERVER}/users/search_preferences`
)

export const getListingAlerts = get(`${BACKEND_SERVER}/users/listing_alerts`)
export const getListingAlert = get(
  `${BACKEND_SERVER}/users/listing_alerts/%id%`
)

export const createListingAlert = post(`${BACKEND_SERVER}/users/listing_alerts`)

export const updateListingAlert = put(
  `${BACKEND_SERVER}/users/listing_alerts/%id%`
)
export const deleteListingAlert = del(
  `${BACKEND_SERVER}/users/listing_alerts/%id%`
)

// ALERT
export const getAlertListings = get(
  `${BACKEND_SERVER}/users/search_preferences/alert/%hash%`
)
export const unsubscribeAlert = put(
  `${BACKEND_SERVER}/users/listing_alerts/%hash%/unsubscribe`
)
export const updateAlertPreference = put(
  `${BACKEND_SERVER}/users/search_preferences/alert/%hash%`
)

// PLANS
export const getCatalog = get(`${BACKEND_SERVER}/catalogs/%province%`)
export const getCatalogTaxCode = get(
  `${BACKEND_SERVER}/catalogs/%province%/%taxCode%`
)

export const subscribePlan = post<{ message: string }>(
  `${BACKEND_SERVER}/plans/subscription`
)

export const getPlans = get<Plan[]>(
  `${BACKEND_SERVER}/plans/subscription/category/base`
)

export const getPaymentMethod = get<{ message?: string }>(
  `${BACKEND_SERVER}/plans/payment_methods`
)

// BUILDING
export const createBuilding = post(`${BACKEND_SERVER}/buildings`)
export const createUnit = post(`${BACKEND_SERVER}/buildings/%id%/units`)
export const authorizeBuildingSearch = post(
  `${BACKEND_SERVER}/buildings/search/authorize`
)
export const searchBuilding = post(`${BACKEND_SERVER}/buildings/search`, {
  noLoading: true
})

// POINTS OF INTEREST
export const getPointsOfInterestByListing = post(
  `${BACKEND_SERVER}/poi/listings/%listingId%`
)
export const getPointsOfInterestByBuilding = post(
  `${BACKEND_SERVER}/poi/buildings/%buildingId%`
)
