import { useEffect } from 'react'

import { useDispatch } from '@/lib/redux'
import handleSession from '@/utils/handle-session'

const useLoadSession = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    async function load() {
      await handleSession(dispatch.session.loadSession({ noLoading: true }))
    }
    load()
  }, [dispatch.session])
}

export default useLoadSession
