import { useEffect, useState } from 'react'

let hydrationCompleted = false

interface Options {
  defaultMatches: boolean
  noSsr?: boolean
  ssrMatchMedia?: (a: string) => MediaQueryList
}

function useMediaQuery(queryInput: string, options: Partial<Options> = {}) {
  const query = queryInput.replace('@media ', '')
  const {
    defaultMatches = false,
    noSsr = false,
    ssrMatchMedia = null
  } = options

  const [matches, setMatches] = useState(() => {
    if (hydrationCompleted || noSsr) {
      return window.matchMedia(query).matches
    }
    if (ssrMatchMedia) {
      return ssrMatchMedia(query).matches
    }

    return defaultMatches
  })

  useEffect(() => {
    hydrationCompleted = true

    const queryList = window.matchMedia(query)
    setMatches(queryList.matches)

    function handleMatchesChange(event: MediaQueryListEvent) {
      setMatches(event.matches)
    }

    queryList.addListener(handleMatchesChange)
    return () => {
      queryList.removeListener(handleMatchesChange)
    }
  }, [query])

  return matches
}

export function testReset() {
  hydrationCompleted = false
}

export default useMediaQuery
