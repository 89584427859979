import dynamic from 'next/dynamic'

import { createModal } from '@/components/modalize'

const PasswordModal = dynamic(() => import('./content'))

export const showCreatePasswordModal = createModal(PasswordModal, {
  showClose: false,
  clickOutside: false,
  width: 520
})
