import { ParsedUrlQuery } from 'querystring'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Userpilot } from 'userpilot'

import config from '@/config'
import useCurrentSession from '@/hooks/use-current-session'

const { BUILD_ENV, USERPILOT_KEY } = config

const isDev = BUILD_ENV === 'dev'

const devLandlords = ['100008136', '100009389', '100008670']

const machobearEmails = 'machobear.ca'
const livRentEmails = 'liv.rent'
const internalPhoneNumber = '888'
if (USERPILOT_KEY) {
  Userpilot.initialize(USERPILOT_KEY)
}

const handleShowUserPilot = (
  id = '',
  email = '',
  phone = '',
  userType = ''
) => {
  const isLandlord = userType === 'landlord'
  const firstDigits = phone?.slice(0, 3)

  if (isDev) {
    return devLandlords?.includes(id)
  } else {
    return (
      isLandlord &&
      !email?.includes(machobearEmails) &&
      !email?.includes(livRentEmails) &&
      firstDigits !== internalPhoneNumber
    )
  }
}
const getQuery = (query: ParsedUrlQuery['upid']) => {
  if (!query) return ''
  if (Array.isArray(query)) return ''
  return query
}

export const UserPilot = () => {
  const session = useCurrentSession()
  const router = useRouter()
  const query = router.query
  const upid = getQuery(router.query?.upid)
  const showUserPilot = handleShowUserPilot(
    session?.id,
    session?.username,
    session?.phone,
    session?.user_type
  )
  useEffect(() => {
    if (!showUserPilot) return

    Userpilot.identify(session?.id, {
      first_name: session?.first_name,
      last_name: session?.last_name,
      email: session?.username,
      user_type: session?.user_type,
      account_type: session?.company_info?.base_plan_name,
      created_at: session?.created_at,
      identity_verified_state: session?.identity_verified_state
    })
    if (upid) {
      Userpilot.track(upid)
      delete query.upid
      router.replace(
        {
          query: query
        },
        undefined,
        { shallow: true }
      )
    }
  }, [
    showUserPilot,
    session?.id,
    session?.first_name,
    session?.last_name,
    session?.username,
    session?.user_type,
    session?.company_info?.base_plan_name,
    session?.created_at,
    session?.identity_verified_state,
    upid,
    router,
    query
  ])
  return null
}
