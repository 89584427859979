import {
  always,
  anyPass,
  applySpec,
  Arity1Fn,
  assoc,
  complement,
  converge,
  curry,
  equals,
  head,
  identity,
  ifElse,
  is,
  isEmpty,
  isNil,
  keys,
  map,
  mergeRight,
  pipe,
  reduce,
  split,
  toPairs
} from 'ramda'

export const isNotEmpty = complement(isEmpty)
export const isTruthy: <T>(a: T) => boolean = pipe(Boolean, equals(true))
export const callOr = (fn: Arity1Fn, v: any) => ifElse(isTruthy, fn, always(v))
export const defaultIfFalsy = <T>(v: T) =>
  ifElse(isTruthy, identity, always<T>(v))
export const mergeSpec = curry(
  <TResult extends object>(spec: object, value: object) =>
    converge(mergeRight, [identity, applySpec(spec)])(value) as TResult
)

export const objectToArrayAs = curry((keyProp, valueProp) =>
  pipe(
    toPairs,
    map(([key, value]) => ({
      [keyProp]: key,
      [valueProp]: value
    }))
  )
)

export const headObj = <O extends object, K extends keyof O>(
  obj: O = {} as O
): O[K] | object => {
  const ks = keys(obj)
  const header = head(ks)
  return (header && obj?.[header]) || {}
}

export const log =
  (msg: string) =>
  (...args: unknown[]) => {
    console.log(msg, ...args) // eslint-disable-line no-console
    return args
  }

export const wrapArray = ifElse(is(Array), identity, a => [a])

export const parseArray = ifElse(is(Array), identity, split(','))

export const isEmptyOrNull = anyPass([isNil, isEmpty])

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)
