import { omit, pickBy } from 'ramda'
import { createModel } from '@rematch/core'

import { Plans } from '@/types'
import {
  getCatalog,
  getPaymentMethod,
  // getCatalogTaxCode,
  getPlans,
  subscribePlan
} from '@/ports'
import { stringify } from '@/utils/qs'
import { showWarningModal } from '@/components/warning-modal'

import { RootModel } from '.'

type PlansState = Plans

const defaultState = {
  plans: [],
  plans_loaded: false
}

const plans = createModel<RootModel>()({
  name: 'plans',
  state: { ...defaultState } as PlansState,
  reducers: {
    clear: () => ({ ...defaultState }),
    clearPayment: omit(['payment_methods']),
    patchCatalog: (state, payload) => ({ ...state, catalog: payload }),
    patchPayment: (state, payload) => ({ ...state, payment_methods: payload }),
    patchPlans: (state, payload) => ({
      ...state,
      plans_loaded: true,
      plans: payload
    }),
    update: (state, payload) => ({ ...state, ...payload })
  },
  effects: dispatch => ({
    async load() {
      const { body } = await getPlans()
      dispatch.plans.patchPlans(body)
    },
    async loadCatalog(payload) {
      const { province, plan, couponCode } = payload
      const prov = province.toUpperCase()
      const query = {
        ...(plan ? { plan } : {}),
        ...(couponCode ? { couponCode } : {})
      }
      const queryString = stringify(query)
      // const cached = state?.plans?.catalog
      // const provinceChanged = cached?.intended_province !== prov
      // const taxValue = taxCode ? taxCode : null
      // const codeChanged = cached?.tax_code !== taxValue
      // if (!provinceChanged && !codeChanged) return
      // dispatch.plans.patchCatalog({ ...cached, tax_code: taxValue })
      // const func = taxCode ? getCatalogTaxCode : getCatalog
      const { body }: { body: any } = await getCatalog(
        { province: prov },
        queryString
      )
      if ((body.message_key || '').includes('err.invalid_coupon')) {
        return { error: true, message: body.message }
      }
      dispatch.plans.patchCatalog(body)
      return body
    },
    async subscribe(payload) {
      const { couponCode, ...rest } = payload
      const query = {
        ...(couponCode ? { couponCode } : {})
      }
      const queryString = stringify(query)
      const newData = pickBy(
        (x, key) =>
          (key !== 'original_subscription_id' && key !== 'tax_code') || x,
        rest
      )
      try {
        const hasNewPayment = rest.payment_method
        const { body, response } = await subscribePlan(
          newData as {},
          {},
          queryString
        )
        if (!response.ok) {
          // In some payment failure cases BE creates a new subscription
          // with the new plan, so we need to reload plans on every failure!
          await dispatch.plans.load()
          const errorMessage =
            body?.message ||
            'Something went wrong with the transaction. Please try again.'
          showWarningModal({
            title: 'Payment Failed!',
            message: errorMessage,
            button: {
              text: 'Try Again'
            }
          })
          throw new Error(body?.message)
        }
        dispatch.flags.deleteByKey('intended_plan')
        // We should also re-loaded the plans on success since
        // if the user goes back, we should show the correct plans
        // on the pricing page, and redirect away from buying again.
        // But we don't need to await
        dispatch.plans.load()
        if (hasNewPayment) dispatch.plans.clearPayment()
        return { body, response }
      } catch (error) {
        console.error('[plans/subscribe] Error Occured: ', error)
        return { error }
      }
    },
    async getPaymentMethods(_ = null, state?) {
      if (state?.plans.payment_methods) return
      try {
        const { body, response } = await getPaymentMethod()
        if (!response.ok) throw new Error(body.message || response.statusText)
        dispatch.plans.patchPayment(body)
        return { body, response }
      } catch (error) {
        console.log('[plans/getPaymentMethod]', error)
        return { error }
      }
    }
  })
})

export default plans
