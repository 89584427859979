import dynamic from 'next/dynamic'
import Head from 'next/head'
import { TFunction, useTranslation } from 'next-i18next'
import styled, {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation
} from 'styled-components'
import PropTypes from 'prop-types'

import structure from '@/structure'
import { breakpoints, colors, fontSizes, fontWeights } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import ContentWrapper from '@/components/content-wrapper'

import bgMobile from './images/Bg404.png'
import bg from './images/Bg404@2x.png'
import crashMobile from './images/Crash.png'
import crash from './images/Crash@2x.png'
import noListings from './images/NoListings@2x.png'
import dog from './images/SniffingDog@2x.png'

const CitySelector = dynamic(() => import('@/features/CitySelector'))
const TrendingListings = dynamic(() => import('@/features/TrendingListings'))

const EnableScroll = createGlobalStyle`
  body, html {
    overflow-y: auto !important;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
  }
`

type CustomStyle = { customStyle: FlattenSimpleInterpolation | undefined }

const Container = styled.div<CustomStyle>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;

  ${({ customStyle }) => customStyle}
`

const Error = styled.h3<CustomStyle>`
  width: 433px;
  color: ${colors.regular};
  font-family: futura-pt, sans-serif;
  font-size: 28px;
  font-weight: ${fontWeights.regular};
  line-height: 34px;
  text-align: center;
  margin: 0 auto 100px auto;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
  }

  ${({ customStyle }) => customStyle}
`

const SearchWrapper = styled.div`
  width: 100%;
  margin-top: ${structure.search.height - structure.header.height}px;

  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: ${structure.search.heightMobile -
    structure.header.heightMobile}px;
  }
`

const Title = styled.h2`
  color: ${colors.regular};
  font-family: futura-pt, sans-serif;
  font-size: 62px;
  font-weight: 500;
  line-height: 64px;
  text-align: center;
  margin: 40px 0 25px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: 34px;
    margin-bottom: 10px;
  }
`

const Subtitle = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  line-height: 28px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: normal;
`

const TrendingBox = styled.div`
  padding: 80px 20px;
`

const FullContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${TrendingBox} {
    margin-top: auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 80px;
`

enum ErrorType {
  NotAvailable = 'n/a',
  NotFound = '404',
  RuntimeError = 'runtime',
  NoResults = 'empty',
  CompanyPage = 'company'
}

const renderError = (error: ErrorValue) => {
  return <Error customStyle={error.style}>{error.text}</Error>
}

const renderTitle = (type: ErrorType, t: TFunction) => {
  if (
    type === ErrorType.NotFound ||
    type === ErrorType.RuntimeError ||
    type === ErrorType.CompanyPage
  )
    return null
  return (
    <Title>
      {t('b.error_page.view.find_your_home.title', 'Find your next home')}
    </Title>
  )
}

const renderNotFoundTitle = (type: ErrorType, t: TFunction) => {
  if (type !== ErrorType.NotFound) return null
  return (
    <Subtitle>
      {t(
        'b.error_page.view.error_description.text',
        `The link you followed may be broken, or the page may have been removed.
        Try a new search!`
      )}
    </Subtitle>
  )
}

const renderContent = (
  type: ErrorType,
  hideSearch: boolean | undefined,
  t: TFunction
) => (
  <SearchWrapper>
    {!hideSearch && <CitySelector instanceId="error-page-select-city" />}
    {renderTitle(type, t)}
  </SearchWrapper>
)

type Props = {
  hideSearch?: boolean
  type?: ErrorType
  errorText?: string
}
type ErrorValue = {
  text: string | JSX.Element
  style: FlattenSimpleInterpolation
  containerStyle?: FlattenSimpleInterpolation
}

type ErrorMap = Record<ErrorType, ErrorValue>
const ErrorPage = ({
  hideSearch,
  type = ErrorType.RuntimeError,
  errorText
}: Props) => {
  const { t } = useTranslation()
  const aboveIpadMiniMax = useMediaQuery(
    `(min-width: ${breakpoints.ipadMiniMax})`
  )

  const MAP: ErrorMap = {
    [ErrorType.NotAvailable]: {
      text: t(
        'b.error_page.view.listing_not_available.text',
        'The listing you’re looking for is no longer available.'
      ),
      style: css`
        background: url(${dog.src}) 50% 0 no-repeat;
        background-size: 220px 90px;
        padding-top: 115px;
        margin-top: 0;
        margin-bottom: 0;
      `
    },
    [ErrorType.NoResults]: {
      text: t(
        'b.error_page.view.expand_your_area.text',
        'Please adjust your preferences or expand your area of interest.'
      ),
      style: css`
        background: url(${noListings.src}) 50% 0 no-repeat;
        background-size: 120px 120px;
        padding-top: 130px;
        margin-top: 10px;
      `
    },
    [ErrorType.CompanyPage]: {
      text:
        errorText ||
        t(
          'b.error_page.view.company_page.text',
          'No listings available at this time. Please check back soon.'
        ),
      style: css`
        background: url(${noListings.src}) 50% 0 no-repeat;
        background-size: 120px 120px;
        padding-top: 130px;
        margin-top: 10px;
      `
    },
    [ErrorType.RuntimeError]: {
      text: t(
        'b.error_page.view.unknown_error.title',
        'Oops! An unknown error has occurred.'
      ),
      style: css`
        margin-bottom: 6px;

        @media screen and (max-width: ${breakpoints.phoneMax}) {
          margin-top: 0;
        }
      `,
      containerStyle: css`
        background: url(${crash.src}) 50% 100% no-repeat;
        background-size: auto 300px;
        min-height: 600px;
        padding-bottom: 150px;
        @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
          background-image: url(${crashMobile.src});
          min-height: 450px;
          background-size: auto 160px;
        }
      `
    },
    [ErrorType.NotFound]: {
      text: t(
        'b.error_page.view.page_not_available.text',
        "Sorry, this page isn't available."
      ),
      style: css`
        margin-bottom: 6px;

        @media screen and (max-width: ${breakpoints.phoneMax}) {
          margin-top: 140px;
        }
      `,
      containerStyle: css`
        background: url(${bg.src}) 50% 100% no-repeat;
        background-size: auto 388px;
        min-height: 655px;

        @media screen and (max-width: ${breakpoints.phoneMax}) {
          background-image: url(${bgMobile.src});
          background-size: auto 47vw;
          min-height: 595px;
        }
      `
    }
  }
  const error = MAP[type || ErrorType.RuntimeError]
  return (
    <FullContent>
      {type !== ErrorType.CompanyPage && (
        <Head>
          <title>Page Not Found | liv.rent - Rental Made Easy</title>
          {type !== ErrorType.NoResults && (
            <meta name="robots" content="noindex, nofollow" />
          )}
        </Head>
      )}
      <Container customStyle={error.containerStyle}>
        <ContentWrapper>
          <TextWrapper>
            {renderError(error)}
            {renderNotFoundTitle(type, t)}
            {!aboveIpadMiniMax && renderContent(type, hideSearch, t)}
          </TextWrapper>
        </ContentWrapper>
      </Container>
      {type !== ErrorType.CompanyPage && (
        <TrendingBox>
          <TrendingListings />
        </TrendingBox>
      )}
      <EnableScroll />
    </FullContent>
  )
}

ErrorPage.propTypes = {
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  resetErrorBoundary: PropTypes.func
}

ErrorPage.Types = ErrorType

export default ErrorPage
