import styled from 'styled-components'

export const H1 = styled.div`
  font-family: Inter, sans-serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
`

export const H3 = styled.div`
  font-family: Inter, sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
`

export const H4 = styled.div`
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`

export const H5 = styled.div`
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`

export const Subheading = styled.div`
  font-family: Inter, sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
`
