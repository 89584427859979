import { css } from 'styled-components'
import toInteger from 'lodash/toInteger'

const rainbow = {
  rainbowRed: '#ff7970',
  rainbowYellow: '#f7d250',
  rainbowGreen: '#61e57e',
  rainbowCyan: '#46e7ff',
  rainbowBlue: '#69a5fe',
  rainbowPurple: '#9670ff',
  rainbowPink: '#ff70ff'
}

const rawColors = {
  white: '#ffffff',
  black: '#000000',
  link: '#007aff',
  blue08: '#0a161f',
  blue16: '#132b3e',
  blue24: '#1d415e',
  blue32: '#27567d',
  blue40: '#306c9c',
  blue48: '#3a82bb',
  blue56: '#5495ca',
  blue64: '#73a9d4',
  blue72: '#92bcdd',
  blue80: '#b1cfe7',
  blue88: '#d0e2f1',
  blue96: '#eff5fa',
  red08: '#290300',
  red16: '#510500',
  red24: '#7a0801',
  red32: '#a20a01',
  red40: '#cb0d01',
  red48: '#f31001',
  red56: '#fe2d20',
  red64: '#fe5348',
  red72: '#fe7a71',
  red80: '#fea09a',
  red88: '#ffc6c2',
  red96: '#ffeceb',
  grey08: '#141414',
  grey16: '#292929',
  grey24: '#3d3d3d',
  grey32: '#525252',
  grey40: '#666666',
  grey48: '#7a7a7a',
  grey56: '#8f8f8f',
  grey64: '#a3a3a3',
  grey72: '#b8b8b8',
  grey80: '#cccccc',
  grey88: '#e0e0e0',
  grey96: '#f5f5f5',
  green08: '#05120d',
  green16: '#0b2419',
  green24: '#103626',
  green32: '#164832',
  green40: '#206b4c',
  green48: '#2b8f65',
  green56: '#36b37e',
  green64: '#5ec298',
  green72: '#86d1b2',
  green80: '#afe1cb',
  green88: '#d7f0e5',
  green96: '#ebf7f2',
  yellow08: '#281d01',
  yellow16: '#503a01',
  yellow24: '#785702',
  yellow32: '#a07403',
  yellow40: '#c89104',
  yellow48: '#f0af04',
  yellow56: '#fbbf24',
  yellow64: '#fcca4b',
  yellow72: '#fcd673',
  yellow80: '#fde29b',
  yellow88: '#feedc3',
  yellow96: '#fff9eb',
  purple08: '#140029',
  purple16: '#290052',
  purple24: '#3d007a',
  purple32: '#5200a3',
  purple40: '#6600cc',
  purple48: '#8700f5',
  purple56: '#9a1fff',
  purple64: '#ac47ff',
  purple72: '#bf70ff',
  purple80: '#d199ff',
  purple88: '#e3c2ff',
  purple96: '#f6ebff'
}

const tags = {
  tagsRed: rawColors.red96,
  tagsRedText: rawColors.red24,
  tagsYellow: '#fffdeb',
  tagsYellowText: '#695611',
  tagsGreen: rawColors.green96,
  tagsGreenText: rawColors.green24,
  tagsBlue: rawColors.blue96,
  tagsBlueText: rawColors.blue24,
  tagsPurple: rawColors.purple96,
  tagsPurpleText: rawColors.purple24
}

const brightBlue67 = '#6ea7ea'

const colors = {
  ...rawColors,
  ...tags,
  ...rainbow,
  background: '#fafafa',
  primary: '#fe5f55', // Very close to liv logo but not quite.  Likely will be colors.red64
  pink: '#fb6964', // Close colors.red88 but is slightly lighter
  red: rawColors.red64,
  secondary: rawColors.blue16,
  lightBlue: brightBlue67, // Outline focus color
  mediumGrey: rawColors.grey64,
  lightGrey: rawColors.grey96,
  // Need to consolidate all these greys
  regular: '#303030', // Likely same as colors.grey16
  darkGrey: '#595959',
  grey: '#d8d8d8',
  lightGreyText: '#707070', // Can likely remove this one (cookie policy, listing card)
  mediumLightGrey: '#d9d7d7',
  secondaryLightBlue: '#7494ac'
}

const fontWeights = {
  regular: 400,
  medium: 600,
  bold: 800
}

const fontSizes = {
  extraLarge: '30px',
  large: '23px',
  medium: '20px',
  regular: '16px',
  regularMobile: '15px',
  small: '13px'
}

const transitions = {
  ease: '0.25s ease-in 0s',
  floatingLabel: `
    color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  `
}

const breakpoints = {
  smallPhone: '370px',
  phoneMax: '599px',
  tabletPortrait: '600px',
  ipadMiniMax: '767px',
  tabletLandscape: '991px',
  desktop: '1096px',
  map: '1150px',
  mapLarge: '1580px',
  card: '1200px',
  v3Desktop: '1280px',
  bigDesktop: '1800px',
  lte: (breakpoint: string) =>
    process.browser &&
    window.innerWidth <= toInteger(breakpoint.replace('px', '')),
  gte: (breakpoint: string) =>
    process.browser &&
    window.innerWidth >= toInteger(breakpoint.replace('px', ''))
}

const media = {
  phoneOnly: css`
    @media (min-width: ${breakpoints.phoneMax}) {
      display: none;
    }
  `,
  phoneHidden: css`
    @media only screen and (max-width: ${breakpoints.phoneMax}) {
      display: none;
    }
  `,
  tabletOnly: css`
    @media only screen and (max-width: ${breakpoints.phoneMax}) {
      display: none;
    }
    @media only screen and (min-width: ${breakpoints.tabletLandscape}) {
      display: none;
    }
  `,
  tabletHidden: css`
    @media only screen and (min-width: ${breakpoints.tabletPortrait}) and (max-width: ${breakpoints.tabletLandscape}) {
      display: none;
    }
  `,
  desktopOnly: css`
    @media only screen and (max-width: ${breakpoints.desktop}) {
      display: none;
    }
  `,
  desktopHidden: css`
    @media only screen and (min-width: ${breakpoints.desktop}) {
      display: none;
    }
  `,
  tabletAtLeast: css`
    @media only screen and (max-width: ${breakpoints.tabletPortrait}) {
      display: none;
    }
  `,
  tabletLandscapeAtLeast: css`
    @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
      display: none;
    }
  `
}

const zIndex = {
  searchFilters: 19999,
  mapCarousel: 20000,
  siteHeader: 20001,
  dropdownMenu: 20010,
  modal: 20020,
  toast: 20030,
  loading: 20040,
  notificationBar: 20050
}

export {
  breakpoints,
  colors,
  fontWeights,
  fontSizes,
  media,
  zIndex,
  transitions
}
