import React from 'react'
import styled from 'styled-components'
import Base from '@mui/material/Dialog'

import { CssUnit } from '@/types'
import { breakpoints, colors, zIndex } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import ModalClose from '@/components/modal/modal-close'
import { useModalize } from '@/components/modalize'

const Content = styled.div<{ overflow: string }>`
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: ${props => props.overflow};
  position: relative;
`

const CloseModal = styled(ModalClose)`
  ${props =>
    props.absolute
      ? `
    position: absolute;
    width: 100%;
  `
      : `
    position: sticky;
    top: env(safe-area-inset-top, 0px);
    height: 0;
  `};

  ${props => props.top && `top: ${props.top} !important`}
  ${props => props.right && `right: ${props.right} !important`}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: env(safe-area-inset-top, 0px);
    right: env(safe-area-inset-right, 0px);
  }
`

type Event = React.MouseEvent<HTMLButtonElement>
type Reason = 'backdropClick' | 'escapeKeyDown' | 'closeButton'

export type ModalProps = {
  width?: CssUnit
  height?: CssUnit
  maxWidth?: CssUnit
  maxHeight?: CssUnit
  radius?: CssUnit
  bgColor?: string
  overflow?: string
  showBackground?: boolean
  showClose?: boolean
  clickOutside?: boolean
  onClose?: (e: Event, r?: Reason) => void
  isVideo?: boolean
  closeButtonTop?: CssUnit
  closeButtonRight?: CssUnit
  children: React.ReactNode
}

const Dialog: React.FC<ModalProps> = ({
  width = '400px',
  height = '95vh', // Need fallback for Safari < 15.4
  maxWidth = '95%',
  maxHeight = '95vh',
  radius = '20px',
  bgColor = colors.white,
  overflow = 'auto',
  showBackground = true,
  clickOutside = true,
  showClose = true,
  isVideo = false,
  onClose,
  closeButtonTop,
  closeButtonRight,
  children
}) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const { close } = useModalize()
  const handleClose = (event: Event, reason: Reason) => {
    // If user can't close modal, don't allow
    // out of focus or escape key to close
    if (!clickOutside) return
    if (typeof onClose === 'function') {
      onClose(event, reason)
    } else {
      close()
    }
  }
  return (
    <Base
      disablePortal
      open
      fullScreen={isMobile && !isVideo}
      onClose={handleClose}
      transitionDuration={0}
      sx={{
        zIndex: `${zIndex.modal} !important`,
        '& .MuiBackdrop-root': {
          backgroundColor: showBackground ? 'rgba(0, 0, 0, 0.8)' : 'none'
        },
        '& .MuiPaper-root': {
          ...(isMobile && !isVideo
            ? {
                height,
                width: '100%',
                position: 'absolute',
                bottom: '0px',
                maxWidth: '100%',
                borderRadius: '0',
                backgroundColor: `${bgColor} !important`,
                boxShadow: 'none',
                ...(overflow !== 'auto' ? { overflow } : {}),
                '@supports(height: 100dvh)': { height: '100dvh' }
              }
            : {
                width,
                maxWidth,
                maxHeight,
                borderRadius: radius,
                backgroundColor: bgColor,
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
                ...(overflow !== 'auto' ? { overflow } : {})
              })
        }
      }}
    >
      <Content overflow={overflow}>
        {showClose && (
          <CloseModal
            onClick={e => handleClose(e, 'closeButton')}
            top={closeButtonTop}
            right={closeButtonRight}
          />
        )}
        {children}
      </Content>
    </Base>
  )
}

export default Dialog
