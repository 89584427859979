import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

type SpinnerProps = {
  width: number
  height: number
  stroke: number
}

type WrapperProps = Omit<SpinnerProps, 'stroke'>

const Wrapper = styled.div.withConfig<WrapperProps>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['width', 'height'].includes(prop) && defaultValidatorFn(prop)
})`
  position: relative;
  > svg {
    animation: ${rotate} 2s linear infinite;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    > circle {
      stroke: currentColor;
      stroke-linecap: round;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  }
`

const Icon: React.FC<SpinnerProps> = ({ width, height, stroke }) => {
  const cx = width / 2
  const cy = height / 2
  const radius = cx - stroke
  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <circle
        cx={cx}
        cy={cy}
        r={radius}
        fill="none"
        strokeWidth={stroke}
      ></circle>
    </svg>
  )
}

const Spinner: React.FC<Partial<SpinnerProps>> = ({
  width = 20,
  height = 20,
  stroke = 1.75
}) => {
  return (
    <Wrapper width={width} height={height}>
      <Icon width={width} height={height} stroke={stroke} />
    </Wrapper>
  )
}

export default Spinner
