import { useContext, useEffect, useState } from 'react'

import { CssUnit } from '@/types'

import { Context } from './context'

function useModalize(width?: CssUnit, height?: CssUnit) {
  const [visible, setVisible] = useState<boolean>(true)
  const { dispose, setWidth, setHeight } = useContext(Context)
  const close = () => {
    setVisible(false)
    dispose()
  }
  // Only set width if passed to effect
  const hasArgs = arguments.length > 0
  const twoArgs = arguments.length === 2
  useEffect(() => {
    if (!hasArgs) return
    setWidth(width)
    if (twoArgs) setHeight(height)
  }, [width, height, hasArgs, twoArgs, setWidth, setHeight])
  return { visible, close }
}

export default useModalize
