import {
  ApolloClient,
  gql,
  HttpLink,
  InMemoryCache,
  useQuery
} from '@apollo/client'

import config from '@/config'

const apolloClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: new HttpLink({
    uri: config.GRAPHQL_SERVER, // Server URL (must be absolute)
    credentials: 'same-origin' // Additional fetch() options like `credentials` or `headers`
  }),
  cache: new InMemoryCache({}),
  ssrForceFetchDelay: 300,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

const CITY_SEARCH = gql`
  query ($input: String) {
    city(input: $input) {
      name
      country_code
      state
      location {
        lat
        lon
      }
      slug
    }
  }
`

// Helper function
const getCityAsync = (citySlug, callback) => {
  return apolloClient
    .query({
      query: CITY_SEARCH,
      variables: { input: citySlug }
    })
    .then(response => {
      if (callback) {
        callback(null, response.data.city)
      }
      return response.data.city
    })
    .catch(error => {
      if (callback) {
        callback(error, null)
      }
      throw error
    })
}

const useCitySearch = citySlug => {
  const { data } = useQuery(CITY_SEARCH, {
    skip: !citySlug,
    variables: {
      input: citySlug
    }
  })

  return data ? data.city : null
}

export { getCityAsync, useCitySearch }
