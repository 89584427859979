import { useEffect } from 'react'

export const STORAGE_KEY = 'referralData'

export const getReferralData = () => {
  const referralDataString = localStorage.getItem(STORAGE_KEY)
  return referralDataString ? JSON.parse(referralDataString) : {}
}

export const getUTMParams = (url: string) => {
  const params = new URLSearchParams(new URL(url).search)
  return {
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign'),
    utm_term: params.get('utm_term'),
    utm_content: params.get('utm_content')
  }
}

export const getDomain = (url: string) => {
  const domainRegex =
    /^(?:https?:\/\/)?(?:www\.)?([a-z0-9.-]+)(?::\d+)?(?:\/.*)?$/i
  const match = url.match(domainRegex)
  // Return full URL if string doesn't match the expected pattern
  return match ? match[1] : url
}

const isLivRentUrl = (url: string) => {
  const livRentUrls = [
    'liv.rent',
    'kratos-dev.machobear.ca',
    'trustliv-dev.machobear.ca',
    'local-web.machobear.ca'
  ]
  const domain = getDomain(url)
  return livRentUrls.includes(domain)
}

export const useSetReferralData = () => {
  // Set referral source flag in localStorage
  useEffect(() => {
    if (!document || !window) return
    const referralData = getReferralData()
    const hostname = window?.location?.hostname || ''
    const isInternalUrl = isLivRentUrl(hostname)

    // example: https://liv.rent?utm_source=google&utm_medium=cpc&utm_campaign=paid_renter_search
    const utmParams = getUTMParams(window.location.href)
    const landing = window.location.href || ''

    if (isInternalUrl) {
      // For liv.rent URLs we want to use the most recent external referrer if available
      // otherwise we want to use the current URL to indicate direct traffic
      if (document.referrer && !isLivRentUrl(document.referrer)) {
        const referrer = getDomain(document.referrer)
        if (referrer !== referralData.referrer) {
          localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({ referrer, landing, ...utmParams })
          )
        }
      } else {
        const referrer = getDomain(window.location.href)
        if (!referralData.referrer) {
          localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({ referrer, landing, ...utmParams })
          )
        }
      }
    } else {
      // For non-liv.rent URLs (eg. ubc.offcampus-housing.com) we always want to use the current URL
      const referrer = getDomain(window.location.href)
      if (!referralData.referrer) {
        localStorage.setItem(
          STORAGE_KEY,
          JSON.stringify({ referrer, landing, ...utmParams })
        )
      }
    }
  }, [])
}
