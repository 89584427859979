import {
  anyPass,
  dissocPath,
  evolve,
  includes,
  indexBy,
  isEmpty,
  isNil,
  lensPath,
  map,
  mergeLeft,
  path,
  pipe,
  prop,
  propEq,
  set,
  toLower,
  toUpper
} from 'ramda'
import toInteger from 'lodash/toInteger'
import toNumber from 'lodash/toNumber'

import {
  deleteListingAlert,
  favouriteListing,
  favouritesList,
  getListingAlert,
  getListingAlerts,
  removeFavouriteListing,
  updateAlertPreference,
  updateListingAlert,
  updateUserSearchPreferences
} from '@/ports'
import { callOr, parseArray, wrapArray } from '@/utils'
import { getHeaders } from '@/utils/http'
import { CURRENT_LOCATION } from '@/utils/location'

const MAX_PRICE = 10000

export const DEFAULT_FILTERED_CITY = 'Vancouver'

export const INITIAL_FILTER = {
  featured: 20,
  page: 1,
  page_size: 12,
  housing_types: [],
  unit_types: [],
  bedroom_count: [],
  cities: []
}

export const INITIAL_COMPANY_FILTER = {
  page: 1,
  page_size: 12,
  housing_types: [],
  unit_types: [],
  bedroom_count: [],
  cities: [],
  state_machine: 'published'
}

const toBoolean = val => (val === true || val === 'true' ? true : false)
const formatMaxPrice = val => (val >= MAX_PRICE ? null : toNumber(val))

export const formatFilters = evolve({
  featured: callOr(toNumber, null),
  min_price: callOr(toNumber, null),
  max_price: callOr(formatMaxPrice, null),
  page: callOr(toNumber, null),
  page_size: callOr(toNumber, null),
  company_id: callOr(toInteger, null),
  bedroom_count: callOr(parseArray, []),
  bathroom_count: callOr(parseArray, []),
  housing_type: callOr(toUpper, null),
  housing_types: callOr(parseArray, []),
  unit_types: callOr(parseArray, []),
  pet_policy: callOr(parseArray, []),
  availability_date: callOr(toUpper, null),
  cities: callOr(wrapArray, []),
  search_only_verified: callOr(toBoolean, null),
  min_size: callOr(toNumber, null),
  lease_type: callOr(toUpper, null),
  rental_type: callOr(parseArray, []),
  has_3d_tour: callOr(toBoolean, null),
  features: callOr(parseArray, []),
  utilities: callOr(parseArray, [])
})

const nullWhenEqual = (a, b) => (a === b ? null : a)
const isEmptyOrNull = x => x === undefined || x === null || x === ''
const isFalsy = anyPass([isNil, isEmpty])
const toLowerArray = pipe(parseArray, map(toLower))

const formatLocation = city => ({ city })
const formatRegions = (array = []) => {
  const citiesArray = Array.isArray(array) ? array : [array]
  return {
    search_regions: array.length > 0 ? map(formatLocation, citiesArray) : []
  }
}

const formatAvailability = (text, date) => {
  const textTypes = {
    NOW: 'now',
    NEXT_MONTH: 'next_month',
    CUSTOM: date
  }
  return textTypes[text] || null
}

const formatHousingTypes = types => {
  if (isEmptyOrNull(types) || types?.length === 0) return null
  return types
    .map(type => (type === 'HIGHRISE' ? 'condo' : type.toLowerCase()))
    .filter(type => type !== 'room')
}

const formatPets = (type, value) => {
  const valueArray = callOr(wrapArray, [])(value)
  if (isEmptyOrNull(valueArray)) return null
  if (includes('NOT_ALLOWED', valueArray)) return 0
  if (anyPass([includes(type), includes('ALLOWED')])(valueArray)) return 1
  return null
}

const formatPreferencesPayload = data => ({
  allow_cats: formatPets('CATS_ALLOWED', data.pet_policy),
  allow_dogs: formatPets('DOGS_ALLOWED', data.pet_policy),
  count_bedrooms: isEmptyOrNull(data.bedroom_count) ? null : data.bedroom_count,
  count_bathrooms: isEmptyOrNull(data.bathroom_count)
    ? null
    : data.bathroom_count,
  min_price: isEmptyOrNull(data.min_price) ? null : toNumber(data.min_price),
  max_price: isEmptyOrNull(data.max_price)
    ? null
    : nullWhenEqual(toNumber(data.max_price), MAX_PRICE),
  availability_date: formatAvailability(
    data.availability_date,
    data.custom_availability_date
  ),
  unit_types: formatHousingTypes(data.housing_types),
  unit_scope_types: formatHousingTypes(data.unit_types),
  verified_listing_only: toNumber(!!data.search_only_verified),
  ...formatRegions(data.cities),
  min_size: callOr(toNumber, null)(data.min_size),
  lease_type: callOr(toLower, null)(data.lease_type),
  rental_type: callOr(toLowerArray, [])(data.rental_type),
  has_3d_tour: callOr(toBoolean, null)(data.has_3d_tour),
  search_features: callOr(toLowerArray, [])(data.features),
  utilities: callOr(toLowerArray, [])(data.utilities)
})

const listings = {
  name: 'listings',
  state: { data: {}, filters: INITIAL_FILTER, favourites: {}, alert: {} },
  reducers: {
    set: (state, payload) => ({ ...state, ...payload }),
    update: (state, at, payload) => set(lensPath(at), payload, state),
    patch: (state, at, payload) =>
      set(lensPath(at), pipe(path(at), mergeLeft(payload))(state), state),
    remove: (state, at) => dissocPath(at, state),
    clearData: state => {
      // We want to keep the filters so that we do not lose
      // our filters or cause the map to flash when we sign out.
      const filters = state?.filters
      return {
        data: {},
        favourites: {},
        filters
      }
    },
    updateAlertFilter: (state, payload) => ({
      ...state,
      alert: payload
    }),
    updateFilter: (state, payload) => {
      const formattedFilters = formatFilters(payload)
      const newFilter = {
        ...INITIAL_FILTER,
        ...formattedFilters
      }

      if (typeof localStorage !== 'undefined')
        localStorage.setItem('livrent:filters', JSON.stringify(newFilter))

      return {
        ...state,
        filters: newFilter
      }
    },
    updateCompanyFilter: (state, payload) => {
      const formattedFilters = formatFilters(payload)
      return {
        ...state,
        filters: {
          ...INITIAL_COMPANY_FILTER,
          ...formattedFilters
        }
      }
    }
  },
  effects: dispatch => ({
    async getFavourites(req, rootState) {
      const favourites = rootState?.listings?.favourites || {}
      if (!isEmpty(favourites)) return
      try {
        const { response, body } = await favouritesList(
          {},
          undefined,
          getHeaders(req)
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listings.set({ favourites: body })
        return body
      } catch (error) {
        return { error }
      }
    },
    async favourite(payload) {
      try {
        const { response, body } = await favouriteListing(undefined, {
          listingId: payload
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listings.patch(['favourites', payload], body[payload])
        return body
      } catch (error) {
        return { error }
      }
    },
    async removeFavourite(payload) {
      try {
        const { response, body } = await removeFavouriteListing(undefined, {
          listingId: payload
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listings.remove(['favourites', payload])
        return body
      } catch (error) {
        return { error }
      }
    },
    async getListingAlerts() {
      try {
        const { response, body } = await getListingAlerts(undefined, {})
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        const parsedBody = indexBy(prop('id'), body)
        dispatch.listings.patch(['listing_alerts'], parsedBody)

        return body
      } catch (error) {
        return { error }
      }
    },

    async getListingAlert(id) {
      try {
        const { response, body } = await getListingAlert({ id }, undefined)
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        return body
      } catch (error) {
        return { error }
      }
    },

    async deleteListingAlert(payload) {
      try {
        const { response, body } = await deleteListingAlert(undefined, {
          id: payload.id
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listings.remove(['listing_alerts', payload.id], body)
        return body
      } catch (error) {
        return { error }
      }
    },

    async updateListingAlert(payload) {
      try {
        const { response, body } = await updateListingAlert(
          {
            body: {
              name: payload.name,
              status: payload.status,
              frequency: payload.frequency
            }
          },
          { id: payload.id }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listings.patch(['listing_alerts', payload.id], body)
        return body
      } catch (error) {
        return { error }
      }
    },
    async updateSearchPreferences(req, rootState, options = {}) {
      const filters = options?.filters || rootState.listings.filters
      const formattedPayload = formatPreferencesPayload(filters)
      const session = rootState.session
      const hasSession =
        !!session?.id && propEq('registration_complete', '1', session)
      const { alert, frequency, hash } = options
      if (hasSession && !isFalsy(filters.cities)) {
        const requestBody = {
          ...formattedPayload,
          ...(alert
            ? {
                name: alert,
                notification_frequency: frequency || 'weekly',
                search_regions: formattedPayload.search_regions.filter(
                  ({ city }) => city !== CURRENT_LOCATION
                )
              }
            : { notification_frequency: 'never' })
        }
        try {
          const func = hash
            ? updateAlertPreference
            : updateUserSearchPreferences
          const { response, body } = await func(
            { body: requestBody },
            { hash },
            undefined,
            getHeaders(req)
          )
          if (!response.ok) {
            throw Error(body.message || response.statusText)
          }
          if (!alert) dispatch.session.update(['user_search_preference'], body)
          return body
        } catch (error) {
          return { error }
        }
      }
    },
    async updateAlertPreferences(req, rootState, options = {}) {
      const { filters, ...rest } = options
      const alert = filters?.alert_name
      const frequency = filters?.notification_frequency
      dispatch.listings.updateAlertFilter(filters)
      return dispatch.listings.updateSearchPreferences(req, {
        ...rest,
        filters,
        alert,
        frequency
      })
    }
  })
}

export default listings
