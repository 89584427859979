import { useEffect } from 'react'
import { useRouter } from 'next/router'

import config from '@/config'
import useCurrentSession from '@/hooks/use-current-session'

const isDev = config.BUILD_ENV === 'dev'

const SendInBlue = () => {
  const router = useRouter()
  const session = useCurrentSession()
  const username = session.username
  const path = router.asPath

  useEffect(() => {
    if (typeof window === 'undefined' || isDev) return
    ;(function () {
      window.sib = {
        equeue: [],
        client_key: 'vlt9q8ukk5edv078l553d1tl'
      }
      /* OPTIONAL: email for identify request*/
      window.sib.email_id = username
      window.sendinblue = {}
      for (
        let j = ['track', 'identify', 'trackLink', 'page'], i = 0;
        i < j.length;
        i++
      ) {
        ;(function (k) {
          window.sendinblue[k] = function () {
            var arg = Array.prototype.slice.call(arguments)
            ;(
              window.sib[k] ||
              function () {
                var t = {}
                t[k] = arg
                window.sib.equeue.push(t)
              }
            )(arg[0], arg[1], arg[2], arg[3])
          }
        })(j[i])
      }
      var n = document.createElement('script'),
        i = document.getElementsByTagName('script')[0]
      ;(n.type = 'text/javascript'),
        (n.id = 'sendinblue-js'),
        (n.async = !0),
        (n.src =
          'https://sibautomation.com/sa.js?key=' + window.sib.client_key),
        i.parentNode.insertBefore(n, i),
        window.sendinblue.page()
    })()
    return () => {
      if (typeof window === 'undefined') return
      // Cleanup script and iframes to prevent accumulation of duplicates
      document.getElementById('sendinblue-js')?.remove()
      const iframes = document.querySelectorAll(
        `iframe[src^='https://sibautomation.com']`
      )
      iframes.forEach(iframe => document.body.removeChild(iframe))
    }
  }, [username, path])
  return null
}

export default SendInBlue
