import http from 'http'
import {
  ascend,
  complement,
  evolve,
  find,
  map,
  pipe,
  prop,
  propEq,
  sortBy,
  sortWith
} from 'ramda'
import { createModel } from '@rematch/core'
import toInteger from 'lodash/toInteger'

import {
  BuildingAmenity,
  FileFixedPosition,
  GroupUnitBuilding,
  GroupUnitsResponse,
  SystemFile
} from '@/types'
import { getGroupListings } from '@/ports'
import { isTruthy } from '@/utils'
import { getHeaders } from '@/utils/http'
import { handle } from '@/utils/promise'

import { RootModel } from '.'

type BuildingState = GroupUnitsResponse & {
  error?: any
}

export type EnhancedBuilding = Omit<GroupUnitsResponse['building'], 'image'> & {
  ev_ready: boolean
  image: FileFixedPosition[]
}

const building = createModel<RootModel>()({
  name: 'building',
  state: {} as BuildingState,
  reducers: {
    update: (state, payload: BuildingState) => ({
      ...state,
      ...payload,
      error: null
    }),
    error: (state, payload: Error) => ({ ...state, error: payload }),
    reset: () => ({} as BuildingState)
  },
  effects: dispatch => ({
    async load(buildingId: string, _, req: http.IncomingMessage) {
      const [response, error] = await handle(
        getGroupListings({ buildingId }, undefined, getHeaders(req))
      )
      if (error || !response) {
        dispatch.building.reset()
        dispatch.building.error(error.message)
        return
      }
      dispatch.building.update(response.body)
    }
  }),
  selectors: slice => ({
    building() {
      return slice(
        pipe(prop<'building', GroupUnitBuilding>('building'), building => {
          if (!building) return building as EnhancedBuilding
          return {
            ...building,
            ev_ready: pipe<
              GroupUnitBuilding,
              BuildingAmenity[],
              BuildingAmenity | undefined,
              boolean
            >(
              prop<'amenities', BuildingAmenity[]>('amenities'),
              find<BuildingAmenity>(propEq('txt_id', 'car_charging')),
              isTruthy
            )(building),
            image: pipe<
              GroupUnitBuilding,
              SystemFile[],
              FileFixedPosition[],
              FileFixedPosition[],
              FileFixedPosition[]
            >(
              prop<'image', SystemFile[]>('image'),
              map<SystemFile, FileFixedPosition>(
                evolve({
                  position: toInteger
                })
              ),
              sortWith([ascend(prop('position')), ascend(prop('tag'))]),
              sortBy<FileFixedPosition>(
                complement(propEq('tag', 'cover_photo'))
              )
            )(building)
          } as EnhancedBuilding
        })
      )
    }
  })
})

export default building
