import type { GetServerSidePropsContext } from 'next'
import {
  __,
  ascend,
  complement,
  concat,
  defaultTo,
  evolve,
  filter,
  head,
  lensPath,
  map,
  mergeLeft,
  omit,
  path,
  pipe,
  prop,
  propEq,
  slice as ramdaSlice,
  set,
  sortBy,
  sortWith,
  values,
  whereEq
} from 'ramda'
import { createModel } from '@rematch/core'
import toInteger from 'lodash/toInteger'

import { Brokers, FileFixedPosition, FileGroup, ListingResponse } from '@/types'
import {
  activateListing,
  bookViewing,
  getListing,
  getListingShare,
  logListingView,
  updateListing
} from '@/ports'
import { headObj } from '@/utils'
import getErrorMessage from '@/utils/get-error'
import { getHeaders } from '@/utils/http'
import { handle } from '@/utils/promise'

import { RootModel } from '.'

interface Notification {
  source: string
  message: string
}
type Error = Notification
export type ListingState = ListingResponse & {
  notification?: Notification | null
  error?: Error | null
}
const listing = createModel<RootModel>()({
  name: 'listing',
  state: {} as ListingState,
  reducers: {
    update: (state, payload: Partial<ListingState>) => ({
      ...state,
      ...payload,
      error: null
    }),
    patch: (state, at: string[], payload) =>
      set(
        lensPath(at),
        pipe(path<any[] | null>(at), defaultTo([]), mergeLeft(payload))(state),
        state
      ),
    error: (state, payload: Error) => ({ ...state, error: payload }),
    notification: (state, payload: Notification) => ({
      ...state,
      notification: payload
    }),
    clearMessages: state => ({
      ...state,
      notification: null,
      error: null
    }),
    reset: (state, keepNotification: boolean) =>
      (keepNotification
        ? { notification: state.notification }
        : {}) as ListingState,
    concat: (state, at: string[], payload: string[]) =>
      set(
        lensPath(at),
        pipe(path<any[] | null>(at), defaultTo([]), concat(__, payload))(state),
        state
      )
  },
  effects: dispatch => ({
    async load(
      payload: { hash?: string; listingId?: string },
      _,
      req: GetServerSidePropsContext['req']
    ) {
      const method = payload.hash ? getListingShare : getListing
      const headers = getHeaders(req)

      if (req) {
        try {
          const [response, error] = await handle(
            method(omit(['keepNotification'], payload), undefined, {
              headers: {
                ...headers.headers,
                cookies: 'X-Token=' + req.cookies['X-Token']
              }
            })
          )

          if (error || !response) {
            dispatch.listing.error({
              source: 'load',
              message: error.message
            })
            return
          }

          dispatch.listing.update(response.body)

          logListingView(
            undefined,
            { listingId: payload.listingId },
            undefined,
            {
              headers: {
                ...headers.headers,
                cookies: 'X-Token=' + req.cookies['X-Token']
              }
            }
          )
        } catch (error) {
          dispatch.listing.error({
            source: 'load',
            message: getErrorMessage(error)
          })
          console.log('[listing/load] Error occured: ', error)
        }
      }
    },
    async activate(hash) {
      try {
        const { response, body } = await activateListing(undefined, { hash })

        if (!response.ok) {
          dispatch.listing.error({ source: 'activate', message: body.message })
          return false
        } else {
          dispatch.listing.notification({
            source: 'activate',
            message: body.message
          })
          return true
        }
      } catch (error) {
        dispatch.listing.error({
          source: 'activate',
          message: getErrorMessage(error)
        })
        console.log('[listing/activate] Error occured: ', error)
      }
    },
    async bookViewing(payload) {
      const [response, error] = await handle(
        bookViewing(undefined, {
          timeslotId: payload.timeslotId
        })
      )
      if (error || !response) {
        return { error }
      }
      dispatch.listing.concat(
        [payload.listingId, 'attending_to', payload.listingId],
        [payload.timeslotId]
      )
      return response.response
    },

    updateListing: async ({ listing_id, ...payload }) => {
      const params = {
        listingId: listing_id
      }
      const data = {
        body: payload
      }
      const { response, body } = await updateListing(data, params)

      if (response.ok) {
        dispatch.listing.patch(['listings'], body.listings[listing_id])
      }
      return response
    }
  }),
  selectors: (slice, createSelector) => ({
    listingDetail() {
      return slice(prop('listings'))
    },
    listingLinks() {
      return slice(pipe(prop('listing_links'), headObj))
    },
    broker() {
      return slice(
        createSelector(
          path<Brokers>(['brokers']),
          path<FileGroup>(['broker_files']),
          (brokers = {}, brokerFiles = {}) => {
            const a = values(brokers)
            const h = head(a)
            if (h) {
              h.logo = Object.values(brokerFiles).find(broker =>
                whereEq({
                  reference_id: h.id,
                  tag: 'broker_logo'
                })(broker)
              )
            }
            return h
          }
        )
      )
    },
    unitImages() {
      return slice(
        pipe(
          path(['files', 'unit']),
          pipe(
            headObj,
            values,
            map(
              evolve({
                position: toInteger
              })
            ),
            filter((file: FileFixedPosition) => file.tag.includes('photo')),
            sortWith([ascend(prop('position')), ascend(prop('tag'))]),
            sortBy(complement(propEq('tag', 'cover_photo'))),
            ramdaSlice(0, 20),
            map((file: FileFixedPosition) => ({
              aws_s3_key: file.aws_s3_key,
              id: file.id
            }))
          )
        )
      )
    },
    unitVideos() {
      return slice(
        createSelector(
          path(['files', 'unit']),
          pipe(
            headObj,
            values,
            map(
              evolve({
                position: toInteger
              })
            ),
            filter((file: FileFixedPosition) => file.tag.includes('video')),
            map((file: FileFixedPosition) => ({
              location: file.location,
              aws_s3_key: file.aws_s3_key,
              id: file.id,
              created_at: file.created_at
            }))
          )
        )
      )
    },
    buildingImages() {
      return slice(
        pipe(path<FileGroup>(['files', 'building']), headObj, values)
      )
    },
    userImage() {
      return slice(
        createSelector(
          path<FileGroup>(['files', 'user']),
          path<string>(['listings', 'primary_landlord']),
          (files, primaryLandlordId = '') => {
            return headObj(
              files?.[primaryLandlordId] || {}
            ) as FileFixedPosition
          }
        )
      )
    },
    appointments() {
      return slice(
        pipe(
          prop('listings_appointments'),
          defaultTo({}),
          headObj,
          values,
          sortBy(prop('meeting_at'))
        )
      )
    },
    similarListings() {
      return slice(path(['suggestions', 'items']))
    }
  })
})

export default listing
