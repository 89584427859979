import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'

import config from './src/config'

if (config.BUILD_ENV === 'prod' && config.SENTRY_DSN) {
  Sentry.init({
    release: config.RELEASE_VERSION,
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'ChunkLoadError',
      'AbortError: The user aborted a request.',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled'
    ],
    allowUrls: [/beta\.liv\.rent/],
    denyUrls: [
      /graph\.facebook\.com/i,
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /js\.api\.here\.com/i,
      /mapsjs-/i
    ]
  })
}
