import { showCreatePasswordModal } from '@/features/Auth/CreatePasswordModal'

import { handle } from './promise'

const noop = () => ({})

const handleSession = async <T>(
  promise: Promise<T>,
  afterSetPassword?: () => {}
) => {
  const [result, error] = await handle(promise)
  if (error?.data?.password_setup_required && process.browser) {
    showCreatePasswordModal({ afterSetPassword: afterSetPassword ?? noop })
  }
  return [result, error]
}

export default handleSession
