import { createModel } from '@rematch/core'

import { RootModel } from '.'

const loading = createModel<RootModel>()({
  state: {
    semaphore: 0
  },
  reducers: {
    setLoading: state => ({ semaphore: state.semaphore + 1 }),
    stopLoading: state => ({ semaphore: state.semaphore - 1 })
  }
})

export default loading
