import { useMemo } from 'react'
import {
  init,
  RematchDispatch,
  RematchRootState,
  RematchStore
} from '@rematch/core'
import selectPlugin from '@rematch/select'
import {
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase
} from 'react-redux'

import models, { RootModel } from '@/models'

export type Store = RematchStore<RootModel, Record<string, never>>
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>

let store: Store

const initialState = {}

function initStore(preloadedState = initialState) {
  return init<RootModel>({
    name: 'Kratos',
    models,
    plugins: [selectPlugin()],
    redux: {
      initialState: preloadedState,
      rootReducers: {
        RESET_STATE: (state, action) => {
          return { ...state, ...action.payload }
        }
      }
    }
  })
}

export function initializeStore(preloadedState?: RootState) {
  const _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    store.dispatch({ type: 'RESET_STATE', payload: preloadedState })
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(state?: RootState) {
  return useMemo(() => initializeStore(state), [state])
}

export const useDispatch = () => {
  return useDispatchBase<Dispatch>()
}

export const useSelector = <T>(func: (state: RootState) => T) => {
  return useSelectorBase<RootState, T>(func)
}
