import { prop } from 'ramda'
import { useQuery } from '@tanstack/react-query'

import { getSession } from '@/ports'
import { RootState, useSelector } from '@/lib/redux'

const useCurrentSession = () =>
  useSelector<RootState['session']>(prop('session'))

const fetchSession = async () => {
  const { body } = await getSession()
  return body
}

export const useCurrentSessionQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['session'],
    queryFn: fetchSession
  })
  return { data, isLoading }
}

export default useCurrentSession
