import { useEffect } from 'react'
import { useRouter } from 'next/router'

import config from '@/config'

const isDev = config.BUILD_ENV === 'dev'

const domains = [
  'https://vars.hotjar.com',
  'https://script.hotjar.com',
  'https://static.hotjar.com'
]

const ALLOWED_ROUTES = ['/pricing', '/landlords', '/register']

const shouldTrackRoute = path => {
  if (!path) return false
  // Set this option to true to allow hotjar to run on all pages:
  const allowAll = true
  // Ideally we only want hotjar to run on the pages we are actively
  // tracking since it can have a negative impact on page speed
  return allowAll || ALLOWED_ROUTES.indexOf(path) > -1
}

const HotJar = () => {
  const router = useRouter()
  const path = router?.pathname || ''
  const track = shouldTrackRoute(path)
  useEffect(() => {
    if (typeof window === 'undefined' || isDev || !track) return
    ;(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: 2525511, hjsv: 6 }
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    return () => {
      if (typeof window === 'undefined') return
      const tags = domains
        .map(domain => document.querySelector(`script[src^='${domain}']`))
        .filter(el => !!el)
      tags.forEach(tag => document.head.removeChild(tag))
    }
  }, [track])
  return null
}

export default HotJar
