import { pipe, propOr } from 'ramda'
import { createModel } from '@rematch/core'
import Nchan from 'nchan'

import ENV_VARS from '@/config'
import { buildRequest } from '@/ports'
import { wrapArray } from '@/utils'

import { RootModel } from '.'

const { NCHAN_SERVER } = ENV_VARS

const STORAGE_KEY = '@SSE:lastEventId'

const NCHAN_OPTIONS: Nchan.Options = {
  subscriber: 'eventsource',
  shared: false,
  reconnect: false
}

const MODELS = {
  support_chat: 'supportRoom',
  be_callback: 'sse',
  user_updated: ['chat', 'profile'],
  profile_progress: 'profile'
}

const getOptions = () => {
  let lastMessageId
  try {
    lastMessageId = localStorage.getItem(STORAGE_KEY) || ''
  } catch (err) {
    console.log('Storage::error', err)
  }
  return {
    ...NCHAN_OPTIONS,
    msgId: lastMessageId
  }
}

const getModel = (type: string) => propOr('chat', type)(MODELS)

const formatType = (type: string) =>
  type
    .replace(/[_-]/g, ' ')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')

let sub: Nchan | null = null

const sse = createModel<RootModel>()({
  name: 'sse',
  state: null,
  reducers: {},
  effects: dispatch => ({
    subscribe(uuid: string) {
      sub = new Nchan(`${NCHAN_SERVER}/${uuid}`, getOptions())
      sub.on('transportSetup', opt => {
        opt.eventsource.withCredentials = true
      })
      sub.on('message', (payload, meta) => {
        const message = JSON.parse(payload)
        dispatch.sse.dispatchMessage(message, 'SSE')
        localStorage.setItem(STORAGE_KEY, meta.id.split(',')[0])
      })
      sub.start()
    },
    unsubscribe() {
      if (sub?.running) {
        sub.stop()
      }
    },
    async parseBeCallbackSSE<T extends Record<string, any>>(url: string) {
      const { response, body } = await buildRequest<T>(url)
      if (response.ok) {
        dispatch.sse.dispatchMessage(body, 'Callback')
      }
    },
    dispatchMessage(message: Record<string, any>, _, suffix: string) {
      const models = pipe(getModel, wrapArray)(message.type)
      for (const model of models) {
        const action =
          dispatch[model]?.[formatType(`parse_${message.type}${suffix}`)]
        action && action(message.data)
      }
    }
  })
})

export default sse
