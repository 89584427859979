// Filter data to generate filter URL slugs and sitemaps
const APARTMENT = {
  housing_types: ['HIGHRISE'],
  unit_types: ['ENTIRE', 'PARTIAL']
}
const HOUSE = {
  housing_types: ['HOUSE'],
  unit_types: ['ENTIRE', 'PARTIAL']
}
const TOWNHOUSE = {
  housing_types: ['TOWNHOUSE'],
  unit_types: ['ENTIRE', 'PARTIAL']
}
const ROOM = {
  unit_types: ['SINGLE']
}

const MIN_PRICE_RANGE = 500
const MAX_PRICE_RANGE = 2000
const MAX_PRICE_RANGE_ROOMS = 1500
const PRICE_INTERVAL = 100

// In cases where there are multiple possible filter slugs for a combination of filters
// we look at the "rank" to determine which one to use - lower rank takes priority

const priceFilters = () => {
  let filters = []
  for (let i = MIN_PRICE_RANGE; i <= MAX_PRICE_RANGE; i = i + PRICE_INTERVAL) {
    filters.push({
      [`under-${i}`]: {
        slug: `under-${i}`,
        filters: { min_price: '0', max_price: i },
        rank: 1000
      },
      [`apartments-under-${i}`]: {
        slug: `apartments-under-${i}`,
        filters: {
          ...APARTMENT,
          min_price: '0',
          max_price: i
        },
        rank: 400
      },
      [`houses-under-${i}`]: {
        slug: `houses-under-${i}`,
        filters: {
          ...HOUSE,
          min_price: '0',
          max_price: i
        },
        rank: 400
      },
      [`townhouses-under-${i}`]: {
        slug: `townhouses-under-${i}`,
        filters: {
          ...TOWNHOUSE,
          min_price: '0',
          max_price: i
        },
        rank: 400
      }
    })
    if (i <= MAX_PRICE_RANGE_ROOMS) {
      filters.push({
        [`rooms-under-${i}`]: {
          slug: `rooms-under-${i}`,
          filters: {
            ...ROOM,
            min_price: '0',
            max_price: i
          },
          rank: 400
        }
      })
    }
  }
  return filters
}

const bedBathFilters = () => {
  return [
    {
      studios: {
        slug: 'studios',
        filters: { bedroom_count: ['0'] },
        rank: 700
      },
      '1-bedroom': {
        slug: '1-bedroom',
        filters: { bedroom_count: ['1'] },
        rank: 700
      },
      '2-bedroom': {
        slug: '2-bedroom',
        filters: { bedroom_count: ['2'] },
        rank: 700
      },
      '3-bedroom': {
        slug: '3-bedroom',
        filters: { bedroom_count: ['3'] },
        rank: 700
      },
      '4-bedroom': {
        slug: '4-bedroom',
        filters: { bedroom_count: ['4+'] },
        rank: 700
      },
      'studio-apartments': {
        slug: 'studio-apartments',
        filters: {
          bedroom_count: ['0'],
          ...APARTMENT
        },
        rank: 100
      },
      '1-bedroom-apartments': {
        slug: '1-bedroom-apartments',
        filters: { bedroom_count: ['1'], ...APARTMENT },
        rank: 100
      },
      '2-bedroom-apartments': {
        slug: '2-bedroom-apartments',
        filters: { bedroom_count: ['2'], ...APARTMENT },
        rank: 100
      },
      '3-bedroom-apartments': {
        slug: '3-bedroom-apartments',
        filters: { bedroom_count: ['3'], ...APARTMENT },
        rank: 100
      },
      '4-bedroom-apartments': {
        slug: '4-bedroom-apartments',
        filters: { bedroom_count: ['4+'], ...APARTMENT },
        rank: 100
      },
      '1-bedroom-houses': {
        slug: '1-bedroom-houses',
        filters: {
          bedroom_count: ['1'],
          ...HOUSE
        },
        rank: 100
      },
      '2-bedroom-houses': {
        slug: '2-bedroom-houses',
        filters: {
          bedroom_count: ['2'],
          ...HOUSE
        },
        rank: 100
      },
      '3-bedroom-houses': {
        slug: '3-bedroom-houses',
        filters: {
          bedroom_count: ['3'],
          ...HOUSE
        },
        rank: 100
      },
      '4-bedroom-houses': {
        slug: '4-bedroom-houses',
        filters: {
          bedroom_count: ['4+'],
          ...HOUSE
        },
        rank: 100
      },
      '1-bedroom-townhouses': {
        slug: '1-bedroom-townhouses',
        filters: {
          bedroom_count: ['1'],
          ...TOWNHOUSE
        },
        rank: 100
      },
      '2-bedroom-townhouses': {
        slug: '2-bedroom-townhouses',
        filters: {
          bedroom_count: ['2'],
          ...TOWNHOUSE
        },
        rank: 100
      },
      '3-bedroom-townhouses': {
        slug: '3-bedroom-townhouses',
        filters: {
          bedroom_count: ['3'],
          ...TOWNHOUSE
        },
        rank: 100
      },
      '4-bedroom-townhouses': {
        slug: '4-bedroom-townhouses',
        filters: {
          bedroom_count: ['4+'],
          ...TOWNHOUSE
        },
        rank: 100
      },
      '1-bedroom-1-bath': {
        slug: '1-bedroom-1-bath',
        filters: { bedroom_count: ['1'], bathroom_count: ['1'] },
        rank: 300
      },
      '1-bedroom-2-bath': {
        slug: '1-bedroom-2-bath',
        filters: { bedroom_count: ['1'], bathroom_count: ['2'] },
        rank: 300
      },
      '2-bedroom-1-bath': {
        slug: '2-bedroom-1-bath',
        filters: { bedroom_count: ['2'], bathroom_count: ['1'] },
        rank: 300
      },
      '2-bedroom-2-bath': {
        slug: '2-bedroom-2-bath',
        filters: { bedroom_count: ['2'], bathroom_count: ['2'] },
        rank: 300
      },
      '3-bedroom-1-bath': {
        slug: '3-bedroom-1-bath',
        filters: { bedroom_count: ['3'], bathroom_count: ['1'] },
        rank: 300
      },
      '3-bedroom-2-bath': {
        slug: '3-bedroom-2-bath',
        filters: { bedroom_count: ['3'], bathroom_count: ['2'] },
        rank: 300
      },
      '3-bedroom-3-bath': {
        slug: '3-bedroom-3-bath',
        filters: { bedroom_count: ['3'], bathroom_count: ['3'] },
        rank: 300
      },
      '4-bedroom-2-bath': {
        slug: '4-bedroom-2-bath',
        filters: { bedroom_count: ['4+'], bathroom_count: ['2'] },
        rank: 300
      },
      '4-bedroom-3-bath': {
        slug: '4-bedroom-3-bath',
        filters: { bedroom_count: ['4+'], bathroom_count: ['3'] },
        rank: 300
      },
      '4-bedroom-4-bath': {
        slug: '4-bedroom-4-bath',
        filters: { bedroom_count: ['4+'], bathroom_count: ['4+'] },
        rank: 300
      },
      '1-bedroom-apartments-under-500': {
        slug: '1-bedroom-apartments-under-500',
        filters: {
          bedroom_count: ['1'],
          ...APARTMENT,
          min_price: '0',
          max_price: 500
        },
        rank: 80
      },
      '1-bedroom-apartments-under-1000': {
        slug: '1-bedroom-apartments-under-1000',
        filters: {
          bedroom_count: ['1'],
          ...APARTMENT,
          min_price: '0',
          max_price: 1000
        },
        rank: 80
      },
      '2-bedroom-apartments-under-1000': {
        slug: '2-bedroom-apartments-under-1000',
        filters: {
          bedroom_count: ['2'],
          ...APARTMENT,
          min_price: '0',
          max_price: 1000
        },
        rank: 80
      },
      '3-bedroom-houses-under-1000': {
        slug: '3-bedroom-houses-under-1000',
        filters: {
          bedroom_count: ['3'],
          ...HOUSE,
          min_price: '0',
          max_price: 1000
        },
        rank: 80
      },
      '1-bedroom-1-bath-apartments': {
        slug: '1-bedroom-1-bath-apartments',
        filters: { bedroom_count: ['1'], bathroom_count: ['1'], ...APARTMENT },
        rank: 75
      },
      '1-bedroom-2-bath-apartments': {
        slug: '1-bedroom-2-bath-apartments',
        filters: { bedroom_count: ['1'], bathroom_count: ['2'], ...APARTMENT },
        rank: 75
      },
      '2-bedroom-1-bath-apartments': {
        slug: '2-bedroom-1-bath-apartments',
        filters: { bedroom_count: ['2'], bathroom_count: ['1'], ...APARTMENT },
        rank: 75
      },
      '2-bedroom-2-bath-apartments': {
        slug: '2-bedroom-2-bath-apartments',
        filters: { bedroom_count: ['2'], bathroom_count: ['2'], ...APARTMENT },
        rank: 75
      },
      '2-bedroom-3-bath-apartments': {
        slug: '2-bedroom-3-bath-apartments',
        filters: { bedroom_count: ['2'], bathroom_count: ['3'], ...APARTMENT },
        rank: 75
      },
      '3-bedroom-2-bath-apartments': {
        slug: '3-bedroom-2-bath-apartments',
        filters: { bedroom_count: ['3'], bathroom_count: ['2'], ...APARTMENT },
        rank: 75
      },
      '1-bedroom-1-bath-houses': {
        slug: '1-bedroom-1-bath-houses',
        filters: { bedroom_count: ['1'], bathroom_count: ['1'], ...HOUSE },
        rank: 75
      },
      '2-bedroom-1-bath-houses': {
        slug: '2-bedroom-1-bath-houses',
        filters: { bedroom_count: ['2'], bathroom_count: ['1'], ...HOUSE },
        rank: 75
      },
      '2-bedroom-2-bath-houses': {
        slug: '2-bedroom-2-bath-houses',
        filters: { bedroom_count: ['2'], bathroom_count: ['2'], ...HOUSE },
        rank: 75
      },
      '3-bedroom-1-bath-houses': {
        slug: '3-bedroom-1-bath-houses',
        filters: { bedroom_count: ['3'], bathroom_count: ['1'], ...HOUSE },
        rank: 75
      },
      '3-bedroom-2-bath-houses': {
        slug: '3-bedroom-2-bath-houses',
        filters: { bedroom_count: ['3'], bathroom_count: ['2'], ...HOUSE },
        rank: 75
      },
      '4-bedroom-2-bath-houses': {
        slug: '4-bedroom-2-bath-houses',
        filters: { bedroom_count: ['4+'], bathroom_count: ['2'], ...HOUSE },
        rank: 75
      },
      '2-bedroom-2-bath-townhouses': {
        slug: '2-bedroom-2-bath-townhouses',
        filters: { bedroom_count: ['2'], bathroom_count: ['2'], ...TOWNHOUSE },
        rank: 75
      },
      '2-bath-apartments': {
        slug: '2-bath-apartments',
        filters: { bathroom_count: ['2'], ...APARTMENT },
        rank: 490
      },
      '3-bath-apartments': {
        slug: '3-bath-apartments',
        filters: { bathroom_count: ['3'], ...APARTMENT },
        rank: 490
      },
      '2-bath-houses': {
        slug: '2-bath-houses',
        filters: { bathroom_count: ['2'], ...HOUSE },
        rank: 490
      },
      '3-bath-houses': {
        slug: '3-bath-houses',
        filters: { bathroom_count: ['3'], ...HOUSE },
        rank: 490
      }
    }
  ]
}

const petFilters = () => {
  return [
    {
      'pet-friendly': {
        slug: 'pet-friendly',
        filters: { pet_policy: ['ALLOWED'] },
        rank: 800
      },
      'dog-friendly': {
        slug: 'dog-friendly',
        filters: { pet_policy: ['DOGS_ALLOWED'] },
        rank: 800
      },
      'cat-friendly': {
        slug: 'cat-friendly',
        filters: { pet_policy: ['CATS_ALLOWED'] },
        rank: 800
      },
      'pet-friendly-apartments': {
        slug: 'pet-friendly-apartments',
        filters: { pet_policy: ['ALLOWED'], ...APARTMENT },
        rank: 200
      },
      'pet-friendly-houses': {
        slug: 'pet-friendly-houses',
        filters: { pet_policy: ['ALLOWED'], ...HOUSE },
        rank: 200
      },
      'pet-friendly-townhouses': {
        slug: 'pet-friendly-townhouses',
        filters: { pet_policy: ['ALLOWED'], ...TOWNHOUSE },
        rank: 200
      },
      'pet-friendly-rooms': {
        slug: 'pet-friendly-rooms',
        filters: { pet_policy: ['ALLOWED'], ...ROOM },
        rank: 200
      },
      'dog-friendly-apartments': {
        slug: 'dog-friendly-apartments',
        filters: { pet_policy: ['DOGS_ALLOWED'], ...APARTMENT },
        rank: 200
      },
      'dog-friendly-houses': {
        slug: 'dog-friendly-houses',
        filters: { pet_policy: ['DOGS_ALLOWED'], ...HOUSE },
        rank: 200
      },
      'dog-friendly-townhouses': {
        slug: 'dog-friendly-townhouses',
        filters: { pet_policy: ['DOGS_ALLOWED'], ...TOWNHOUSE },
        rank: 200
      },
      'dog-friendly-rooms': {
        slug: 'dog-friendly-rooms',
        filters: { pet_policy: ['DOGS_ALLOWED'], ...ROOM },
        rank: 200
      },
      'cat-friendly-apartments': {
        slug: 'cat-friendly-apartments',
        filters: { pet_policy: ['CATS_ALLOWED'], ...APARTMENT },
        rank: 200
      },
      'cat-friendly-houses': {
        slug: 'cat-friendly-houses',
        filters: { pet_policy: ['CATS_ALLOWED'], ...HOUSE },
        rank: 200
      },
      'cat-friendly-townhouses': {
        slug: 'cat-friendly-townhouses',
        filters: { pet_policy: ['CATS_ALLOWED'], ...TOWNHOUSE },
        rank: 200
      },
      'cat-friendly-rooms': {
        slug: 'cat-friendly-rooms',
        filters: { pet_policy: ['CATS_ALLOWED'], ...ROOM },
        rank: 200
      },
      'pet-friendly-short-term': {
        slug: 'pet-friendly-short-term',
        filters: { pet_policy: ['ALLOWED'], rental_type: ['SHORT_TERM'] },
        rank: 560
      },
      'pet-friendly-long-term': {
        slug: 'pet-friendly-long-term',
        filters: { pet_policy: ['ALLOWED'], rental_type: ['LONG_TERM'] },
        rank: 560
      },
      'dog-friendly-short-term': {
        slug: 'dog-friendly-short-term',
        filters: { pet_policy: ['DOGS_ALLOWED'], rental_type: ['SHORT_TERM'] },
        rank: 560
      },
      'dog-friendly-long-term': {
        slug: 'dog-friendly-long-term',
        filters: { pet_policy: ['DOGS_ALLOWED'], rental_type: ['LONG_TERM'] },
        rank: 560
      },
      'cat-friendly-short-term': {
        slug: 'cat-friendly-short-term',
        filters: { pet_policy: ['CATS_ALLOWED'], rental_type: ['SHORT_TERM'] },
        rank: 560
      },
      'cat-friendly-long-term': {
        slug: 'cat-friendly-long-term',
        filters: { pet_policy: ['CATS_ALLOWED'], rental_type: ['LONG_TERM'] },
        rank: 560
      },
      'pet-friendly-studios': {
        slug: 'pet-friendly-studios',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['0'] },
        rank: 680
      },
      'pet-friendly-1-bedroom': {
        slug: 'pet-friendly-1-bedroom',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['1'] },
        rank: 680
      },
      'pet-friendly-2-bedroom': {
        slug: 'pet-friendly-2-bedroom',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['2'] },
        rank: 680
      },
      'pet-friendly-3-bedroom': {
        slug: 'pet-friendly-3-bedroom',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['3'] },
        rank: 680
      },
      'pet-friendly-4-bedroom': {
        slug: 'pet-friendly-4-bedroom',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['4+'] },
        rank: 680
      },
      'dog-friendly-studios': {
        slug: 'dog-friendly-studios',
        filters: { pet_policy: ['DOGS_ALLOWED'], bedroom_count: ['0'] },
        rank: 680
      },
      'dog-friendly-1-bedroom': {
        slug: 'dog-friendly-1-bedroom',
        filters: { pet_policy: ['DOGS_ALLOWED'], bedroom_count: ['1'] },
        rank: 680
      },
      'dog-friendly-2-bedroom': {
        slug: 'dog-friendly-2-bedroom',
        filters: { pet_policy: ['DOGS_ALLOWED'], bedroom_count: ['2'] },
        rank: 680
      },
      'dog-friendly-3-bedroom': {
        slug: 'dog-friendly-3-bedroom',
        filters: { pet_policy: ['DOGS_ALLOWED'], bedroom_count: ['3'] },
        rank: 680
      },
      'dog-friendly-4-bedroom': {
        slug: 'dog-friendly-4-bedroom',
        filters: { pet_policy: ['DOGS_ALLOWED'], bedroom_count: ['4+'] },
        rank: 680
      },
      'pet-friendly-studio-apartments': {
        slug: 'pet-friendly-studio-apartments',
        filters: {
          pet_policy: ['ALLOWED'],
          bedroom_count: ['0'],
          ...APARTMENT
        },
        rank: 85
      },
      'pet-friendly-1-bedroom-apartments': {
        slug: 'pet-friendly-1-bedroom-apartments',
        filters: {
          pet_policy: ['ALLOWED'],
          bedroom_count: ['1'],
          ...APARTMENT
        },
        rank: 85
      },
      'pet-friendly-2-bedroom-houses': {
        slug: 'pet-friendly-2-bedroom-houses',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['2'], ...HOUSE },
        rank: 85
      },
      'pet-friendly-3-bedroom-houses': {
        slug: 'pet-friendly-3-bedroom-houses',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['3'], ...HOUSE },
        rank: 85
      },
      'pet-friendly-4-bedroom-houses': {
        slug: 'pet-friendly-4-bedroom-houses',
        filters: { pet_policy: ['ALLOWED'], bedroom_count: ['4+'], ...HOUSE },
        rank: 85
      },
      'pet-friendly-furnished-short-term': {
        slug: 'pet-friendly-furnished-short-term',
        filters: {
          pet_policy: ['ALLOWED'],
          features: ['furnished'],
          rental_type: ['SHORT_TERM']
        },
        rank: 530
      }
    }
  ]
}
const leaseTypeFilters = () => {
  return [
    {
      'fixed-term-lease': {
        slug: 'fixed-term-lease',
        filters: { lease_type: 'FIXED' },
        rank: 1200
      },
      'month-to-month': {
        slug: 'month-to-month',
        filters: { lease_type: 'MONTH_TO_MONTH' },
        rank: 1200
      },
      'monthly-apartments': {
        slug: 'monthly-apartments',
        filters: { lease_type: 'MONTH_TO_MONTH', ...APARTMENT },
        rank: 480
      },
      'monthly-houses': {
        slug: 'monthly-houses',
        filters: { lease_type: 'MONTH_TO_MONTH', ...HOUSE },
        rank: 480
      },
      'monthly-townhouses': {
        slug: 'monthly-townhouses',
        filters: { lease_type: 'MONTH_TO_MONTH', ...TOWNHOUSE },
        rank: 480
      },
      'monthly-rooms': {
        slug: 'monthly-rooms',
        filters: { lease_type: 'MONTH_TO_MONTH', ...ROOM },
        rank: 480
      },
      'monthly-short-term': {
        slug: 'monthly-short-term',
        filters: { lease_type: 'MONTH_TO_MONTH', rental_type: ['SHORT_TERM'] },
        rank: 580
      },
      'monthly-studios': {
        slug: 'monthly-studios',
        filters: { lease_type: 'MONTH_TO_MONTH', bedroom_count: ['0'] },
        rank: 660
      },
      'monthly-1-bedroom': {
        slug: 'monthly-1-bedroom',
        filters: { lease_type: 'MONTH_TO_MONTH', bedroom_count: ['1'] },
        rank: 660
      },
      'monthly-2-bedroom': {
        slug: 'monthly-2-bedroom',
        filters: { lease_type: 'MONTH_TO_MONTH', bedroom_count: ['2'] },
        rank: 660
      },
      'monthly-3-bedroom': {
        slug: 'monthly-3-bedroom',
        filters: { lease_type: 'MONTH_TO_MONTH', bedroom_count: ['3'] },
        rank: 660
      },
      'monthly-4-bedroom': {
        slug: 'monthly-4-bedroom',
        filters: { lease_type: 'MONTH_TO_MONTH', bedroom_count: ['4+'] },
        rank: 660
      }
    }
  ]
}

const rentalTypeFilters = () => {
  return [
    {
      'short-term': {
        slug: 'short-term',
        filters: { rental_type: ['SHORT_TERM'] },
        rank: 600
      },
      'long-term': {
        slug: 'long-term',
        filters: { rental_type: ['LONG_TERM'] },
        rank: 600
      },
      'short-term-apartments': {
        slug: 'short-term-apartments',
        filters: { rental_type: ['SHORT_TERM'], ...APARTMENT },
        rank: 460
      },
      'short-term-houses': {
        slug: 'short-term-houses',
        filters: { rental_type: ['SHORT_TERM'], ...HOUSE },
        rank: 460
      },
      'short-term-townhouses': {
        slug: 'short-term-townhouses',
        filters: { rental_type: ['SHORT_TERM'], ...TOWNHOUSE },
        rank: 460
      },
      'short-term-rooms': {
        slug: 'short-term-rooms',
        filters: { rental_type: ['SHORT_TERM'], ...ROOM },
        rank: 460
      },
      'long-term-apartments': {
        slug: 'long-term-apartments',
        filters: { rental_type: ['LONG_TERM'], ...APARTMENT },
        rank: 460
      },
      'long-term-houses': {
        slug: 'long-term-houses',
        filters: { rental_type: ['LONG_TERM'], ...HOUSE },
        rank: 460
      },
      'long-term-townhouses': {
        slug: 'long-term-townhouses',
        filters: { rental_type: ['LONG_TERM'], ...TOWNHOUSE },
        rank: 460
      },
      'long-term-rooms': {
        slug: 'long-term-rooms',
        filters: { rental_type: ['LONG_TERM'], ...ROOM },
        rank: 460
      },
      'studio-short-term': {
        slug: 'studio-short-term',
        filters: { rental_type: ['SHORT_TERM'], bedroom_count: ['0'] },
        rank: 520
      },
      '1-bedroom-short-term': {
        slug: '1-bedroom-short-term',
        filters: { rental_type: ['SHORT_TERM'], bedroom_count: ['1'] },
        rank: 520
      },
      '2-bedroom-short-term': {
        slug: '2-bedroom-short-term',
        filters: { rental_type: ['SHORT_TERM'], bedroom_count: ['2'] },
        rank: 520
      },
      '3-bedroom-short-term': {
        slug: '3-bedroom-short-term',
        filters: { rental_type: ['SHORT_TERM'], bedroom_count: ['3'] },
        rank: 520
      },
      '4-bedroom-short-term': {
        slug: '4-bedroom-short-term',
        filters: { rental_type: ['SHORT_TERM'], bedroom_count: ['4+'] },
        rank: 520
      },
      'studio-long-term': {
        slug: 'studio-long-term',
        filters: { rental_type: ['LONG_TERM'], bedroom_count: ['0'] },
        rank: 520
      },
      '1-bedroom-long-term': {
        slug: '1-bedroom-long-term',
        filters: { rental_type: ['LONG_TERM'], bedroom_count: ['1'] },
        rank: 520
      },
      '2-bedroom-long-term': {
        slug: '2-bedroom-long-term',
        filters: { rental_type: ['LONG_TERM'], bedroom_count: ['2'] },
        rank: 520
      },
      '3-bedroom-long-term': {
        slug: '3-bedroom-long-term',
        filters: { rental_type: ['LONG_TERM'], bedroom_count: ['3'] },
        rank: 520
      },
      '4-bedroom-long-term': {
        slug: '4-bedroom-long-term',
        filters: { rental_type: ['LONG_TERM'], bedroom_count: ['4+'] },
        rank: 520
      },
      'short-term-studio-apartments': {
        slug: 'short-term-studio-apartments',
        filters: {
          rental_type: ['SHORT_TERM'],
          bedroom_count: ['0'],
          ...APARTMENT
        },
        rank: 95
      },
      'short-term-1-bedroom-apartments': {
        slug: 'short-term-1-bedroom-apartments',
        filters: {
          rental_type: ['SHORT_TERM'],
          bedroom_count: ['1'],
          ...APARTMENT
        },
        rank: 95
      },
      'short-term-2-bedroom-apartments': {
        slug: 'short-term-2-bedroom-apartments',
        filters: {
          rental_type: ['SHORT_TERM'],
          bedroom_count: ['2'],
          ...APARTMENT
        },
        rank: 95
      },
      'short-term-3-bedroom-apartments': {
        slug: 'short-term-3-bedroom-apartments',
        filters: {
          rental_type: ['SHORT_TERM'],
          bedroom_count: ['3'],
          ...APARTMENT
        },
        rank: 95
      }
    }
  ]
}
const featuresFilters = () => {
  return [
    {
      furnished: {
        slug: 'furnished',
        filters: { features: ['furnished'] },
        rank: 1100
      },
      unfurnished: {
        slug: 'unfurnished',
        filters: { features: ['unfurnished'] },
        rank: 1100
      },
      'furnished-apartments': {
        slug: 'furnished-apartments',
        filters: { features: ['furnished'], ...APARTMENT },
        rank: 440
      },
      'furnished-houses': {
        slug: 'furnished-houses',
        filters: { features: ['furnished'], ...HOUSE },
        rank: 440
      },
      'furnished-townhouses': {
        slug: 'furnished-townhouses',
        filters: { features: ['furnished'], ...TOWNHOUSE },
        rank: 440
      },
      'furnished-rooms': {
        slug: 'furnished-rooms',
        filters: { features: ['furnished'], ...ROOM },
        rank: 440
      },
      'unfurnished-apartments': {
        slug: 'unfurnished-apartments',
        filters: { features: ['unfurnished'], ...APARTMENT },
        rank: 440
      },
      'unfurnished-houses': {
        slug: 'unfurnished-houses',
        filters: { features: ['unfurnished'], ...HOUSE },
        rank: 440
      },
      'unfurnished-townhouses': {
        slug: 'unfurnished-townhouses',
        filters: { features: ['unfurnished'], ...TOWNHOUSE },
        rank: 440
      },
      'unfurnished-rooms': {
        slug: 'unfurnished-rooms',
        filters: { features: ['unfurnished'], ...ROOM },
        rank: 440
      },
      'furnished-short-term': {
        slug: 'furnished-short-term',
        filters: { features: ['furnished'], rental_type: ['SHORT_TERM'] },
        rank: 540
      },
      'unfurnished-short-term': {
        slug: 'unfurnished-short-term',
        filters: { features: ['unfurnished'], rental_type: ['SHORT_TERM'] },
        rank: 540
      },
      'furnished-long-term': {
        slug: 'furnished-long-term',
        filters: { features: ['furnished'], rental_type: ['LONG_TERM'] },
        rank: 540
      },
      'unfurnished-long-term': {
        slug: 'unfurnished-long-term',
        filters: { features: ['unfurnished'], rental_type: ['LONG_TERM'] },
        rank: 540
      },
      'furnished-studios': {
        slug: 'furnished-studios',
        filters: { features: ['furnished'], bedroom_count: ['0'] },
        rank: 450
      },
      'furnished-1-bedroom': {
        slug: 'furnished-1-bedroom',
        filters: { features: ['furnished'], bedroom_count: ['1'] },
        rank: 450
      },
      'furnished-2-bedroom': {
        slug: 'furnished-2-bedroom',
        filters: { features: ['furnished'], bedroom_count: ['2'] },
        rank: 450
      },
      'furnished-3-bedroom': {
        slug: 'furnished-3-bedroom',
        filters: { features: ['furnished'], bedroom_count: ['3'] },
        rank: 450
      },
      'furnished-4-bedroom': {
        slug: 'furnished-4-bedroom',
        filters: { features: ['furnished'], bedroom_count: ['4+'] },
        rank: 450
      },
      'unfurnished-studios': {
        slug: 'unfurnished-studios',
        filters: { features: ['unfurnished'], bedroom_count: ['0'] },
        rank: 450
      },
      'unfurnished-1-bedroom': {
        slug: 'unfurnished-1-bedroom',
        filters: { features: ['unfurnished'], bedroom_count: ['1'] },
        rank: 450
      },
      'unfurnished-2-bedroom': {
        slug: 'unfurnished-2-bedroom',
        filters: { features: ['unfurnished'], bedroom_count: ['2'] },
        rank: 450
      },
      'unfurnished-3-bedroom': {
        slug: 'unfurnished-3-bedroom',
        filters: { features: ['unfurnished'], bedroom_count: ['3'] },
        rank: 450
      },
      'unfurnished-4-bedroom': {
        slug: 'unfurnished-4-bedroom',
        filters: { features: ['unfurnished'], bedroom_count: ['4+'] },
        rank: 450
      },
      'furnished-pet-friendly': {
        slug: 'furnished-pet-friendly',
        filters: { features: ['furnished'], pet_policy: ['ALLOWED'] },
        rank: 780
      },
      'furnished-dog-friendly': {
        slug: 'furnished-dog-friendly',
        filters: { features: ['furnished'], pet_policy: ['DOGS_ALLOWED'] },
        rank: 780
      },
      'furnished-monthly': {
        slug: 'furnished-monthly',
        filters: { features: ['furnished'], lease_type: 'MONTH_TO_MONTH' },
        rank: 1080
      },
      'unfurnished-monthly': {
        slug: 'unfurnished-monthly',
        filters: { features: ['unfurnished'], lease_type: 'MONTH_TO_MONTH' },
        rank: 1080
      },
      'furnished-short-term-apartments': {
        slug: 'furnished-short-term-apartments',
        filters: {
          features: ['furnished'],
          rental_type: ['SHORT_TERM'],
          ...APARTMENT
        },
        rank: 430
      },
      'furnished-studio-apartments': {
        slug: 'furnished-studio-apartments',
        filters: {
          features: ['furnished'],
          bedroom_count: ['0'],
          ...APARTMENT
        },
        rank: 90
      },
      'furnished-1-bedroom-apartments': {
        slug: 'furnished-1-bedroom-apartments',
        filters: {
          features: ['furnished'],
          bedroom_count: ['1'],
          ...APARTMENT
        },
        rank: 90
      },
      'furnished-2-bedroom-apartments': {
        slug: 'furnished-2-bedroom-apartments',
        filters: {
          features: ['furnished'],
          bedroom_count: ['2'],
          ...APARTMENT
        },
        rank: 90
      },
      'furnished-3-bedroom-apartments': {
        slug: 'furnished-3-bedroom-apartments',
        filters: {
          features: ['furnished'],
          bedroom_count: ['3'],
          ...APARTMENT
        },
        rank: 90
      },
      'furnished-4-bedroom-houses': {
        slug: 'furnished-4-bedroom-houses',
        filters: {
          features: ['furnished'],
          bedroom_count: ['4+'],
          ...HOUSE
        },
        rank: 90
      },
      'unfurnished-studio-apartments': {
        slug: 'unfurnished-studio-apartments',
        filters: {
          features: ['unfurnished'],
          bedroom_count: ['0'],
          ...APARTMENT
        },
        rank: 90
      },
      'unfurnished-1-bedroom-apartments': {
        slug: 'unfurnished-1-bedroom-apartments',
        filters: {
          features: ['unfurnished'],
          bedroom_count: ['1'],
          ...APARTMENT
        },
        rank: 90
      },
      'unfurnished-2-bedroom-apartments': {
        slug: 'unfurnished-2-bedroom-apartments',
        filters: {
          features: ['unfurnished'],
          bedroom_count: ['2'],
          ...APARTMENT
        },
        rank: 90
      },
      'unfurnished-3-bedroom-apartments': {
        slug: 'unfurnished-3-bedroom-apartments',
        filters: {
          features: ['unfurnished'],
          bedroom_count: ['3'],
          ...APARTMENT
        },
        rank: 90
      }
    }
  ]
}

const filters = {
  apartments: {
    slug: 'apartments',
    filters: APARTMENT,
    rank: 500
  },
  houses: {
    slug: 'houses',
    filters: HOUSE,
    rank: 500
  },
  townhouses: {
    slug: 'townhouses',
    filters: TOWNHOUSE,
    rank: 500
  },
  rooms: {
    slug: 'rooms',
    filters: ROOM,
    rank: 500
  },
  'verified-rentals': {
    slug: 'verified-rentals',
    filters: { search_only_verified: true },
    rank: 900
  }
}

const FILTERS = Object.assign(
  {},
  filters,
  ...bedBathFilters(),
  ...petFilters(),
  ...leaseTypeFilters(),
  ...rentalTypeFilters(),
  ...priceFilters(),
  ...featuresFilters()
)

module.exports = { FILTERS }
