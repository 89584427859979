import Link from 'next/link'
import styled, { css } from 'styled-components'
import { omit } from 'ramda'

import { CssUnit } from '@/types'
import { colors, fontWeights } from '@/styles'
import { toUnit } from '@/utils/styles'
import { LoadingSpinner } from '@/components/loading-circle'

const LoadingWrapper = styled.div`
  height: 1px;
  display: flex;
  align-items: center;
  overflow-y: visible;
`

type Theme =
  | 'primary'
  | 'secondary'
  | 'delete'
  | 'success'
  | 'text'
  | 'neutral'
  | 'deleteSecondary'
  | 'blueSecondary'
type Size = 'small' | 'medium' | 'unset'

type ButtonProps = {
  onClick?: () => void
  href?: string
  theme?: Theme
  size?: Size
  width?: CssUnit
  disabled?: boolean
  loading?: boolean
  $loading?: boolean
}

const primary = css`
  color: ${colors.white};
  background-color: ${colors.blue16};
  box-shadow: 0px 2px 4px rgba(19, 43, 62, 0.25),
    0px 6px 6px 2px rgba(19, 43, 62, 0.1);
  &:hover {
    background-color: ${colors.blue24};
    box-shadow: 0px 6px 8px -2px rgba(29, 66, 94, 0.4),
      0px 12px 16px rgba(32, 121, 193, 0.15);
  }
  &:active {
    background-color: ${colors.blue08} !important;
    color: #9ca3a5;
    box-shadow: none !important;
  }
`

const secondary = css`
  color: ${colors.blue16};
  background-color: ${colors.white};
  border: 1.5px solid ${colors.grey88};
  &:hover {
    background-color: ${colors.grey96};
  }
  &:active {
    background-color: ${colors.grey88} !important;
  }
`

const neutral = css`
  background-color: ${colors.mediumLightGrey};
  color: ${colors.regular};
  font-weight: 600;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`

const del = css`
  color: ${colors.red64};
  background-color: ${colors.red96};
  border: 1.5px solid ${colors.red88};
  &:hover {
    background-color: ${colors.red88};
  }
  &:active {
    color: ${colors.red56} !important;
    background-color: ${colors.red80} !important;
  }
`

const delSecondary = css`
  color: ${colors.red64};
  background-color: ${colors.white};
  border: 1.5px solid ${colors.red64};
  &:hover {
    background-color: ${colors.red88};
  }
  &:active {
    color: ${colors.red56} !important;
    background-color: ${colors.red80} !important;
  }
`

const text = css`
  color: ${colors.link};
  background-color: transparent;
  border: none;
  font-weight: 600;
  &:hover {
    background-color: transparent;
  }
  &:active {
    color: ${colors.link} !important;
    background-color: transparent;
  }
`

const success = css`
  color: ${colors.white};
  background: ${colors.green56};
  box-shadow: none;
  &:hover {
    background-color: ${colors.green48};
  }
  &:active {
    background-color: ${colors.green48} !important;
  }
`

const blueSecondary = css`
  ${secondary}
  color: ${colors.blue16};
  border: 1.5px solid ${colors.blue16};
`

const disabled = css`
  background-color: ${colors.grey88};
  color: ${colors.grey56};
  border: 0;
`

const small = css`
  min-width: 64px;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  border-radius: 6px;
  gap: 0 4px;
  max-height: 40px;
`

const medium = css`
  min-width: 160px;
  font-weight: ${fontWeights.bold};
  font-size: 14px;
  line-height: 16px;
  padding: 16px;
  border-radius: 6px;
  gap: 0 8px;
  min-height: 48px;
`
const unset = css`
  font-weight: ${fontWeights.bold};
  font-size: 14px;
  line-height: 16px;
  padding: 16px 20px;
  border-radius: 6px;
  gap: 0 8px;
`

const getVariantStyles = (props: ButtonProps) => {
  if (props.theme === 'success') return success
  if (props.theme === 'delete') return del
  if (props.theme === 'deleteSecondary') return delSecondary
  if (props.theme === 'secondary') return secondary
  if (props.theme === 'blueSecondary') return blueSecondary
  if (props.theme === 'text') return text
  if (props.theme === 'neutral') return neutral
  return primary
}

const getSizeStyles = (props: ButtonProps) => {
  if (props.size === 'small') return small
  if (props.size === 'unset') return unset
  return medium
}

const BaseButton = styled.button<ButtonProps>`
  pointer-events: auto;
  white-space: pre;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s,
    box-shadow 300ms ease-in-out 0s;
  border: none;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.width ? toUnit(props.width) : 'unset')};

  &:disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
    user-select: none;
    pointer-events: ${props => (props.$loading ? 'none' : 'auto')};
    ${props => (props.$loading ? getVariantStyles(props) : disabled)};
  }
  &:not(:disabled):not([aria-disabled='true']) {
    ${props => getVariantStyles(props)};
  }

  ${props => getSizeStyles(props)};

  svg,
  div {
    pointer-events: none;
  }
`

export const Button: React.FC<
  React.ComponentProps<typeof BaseButton>
> = props => {
  const { children, href, disabled } = props
  const isLink = !disabled && href

  if (isLink) {
    const linkProps = omit(['onClick', 'type'], props)
    const { href, loading, ...rest } = linkProps
    return (
      <Link href={href} passHref legacyBehavior>
        <BaseButton as="a" $loading={loading} {...rest}>
          {children}
        </BaseButton>
      </Link>
    )
  } else {
    const buttonProps = omit(['href'], props)
    const { loading, ...rest } = buttonProps
    return (
      <BaseButton $loading={loading} {...rest}>
        {!loading ? (
          children
        ) : (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        )}
      </BaseButton>
    )
  }
}

export const TextButton: React.FC<
  React.ComponentProps<typeof BaseButton>
> = props => {
  return (
    <Button theme="text" {...props}>
      {props.children}
    </Button>
  )
}

Button.defaultProps = {
  type: 'button',
  theme: 'primary',
  size: 'medium',
  loading: false
}
