import styled from 'styled-components'

import { breakpoints } from '@/styles'

const ContentWrapper = styled.section`
  margin: 0 auto;
  width: 95%;
  max-width: ${breakpoints.desktop};

  @media (max-width: ${breakpoints.tabletLandscape}) {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
`

export default ContentWrapper
