import { path } from 'ramda'

import { agreeToAgreements, getAgreement, getUserAgreements } from '@/ports'

const initialState = {
  currentAgreement: null,
  agreements: {},
  mismatched: {},
  current: {},
  newest: {}
}

const agreement = {
  state: { ...initialState },
  reducers: {
    update: (state, payload) => ({ ...state, ...payload }),
    clearData: () => ({ ...initialState })
  },
  effects: dispatch => ({
    validateAgreements: (payload, { agreement: agreements }) => {
      const baseAgreements = path(['newest'], agreements) || {}
      const userAgreements = path(['current'], agreements) || {}
      const mismatched =
        Array.isArray(userAgreements) && !userAgreements.length
          ? baseAgreements
          : {}
      Object.keys(userAgreements).forEach(key => {
        const userAgreement = userAgreements[key]
        const baseAgreement = baseAgreements[key]

        if (userAgreement.system_agreement_version !== baseAgreement.version) {
          mismatched[key] = baseAgreement
        }
      })

      dispatch.agreement.update({ mismatched })
    },
    async getCurrentAgreements() {
      try {
        const response = await getUserAgreements()
        const body = response?.body
        if (response.response.ok) {
          dispatch.agreement.update({
            newest: body?.agreements,
            current: body?.user_agreements
          })
        }
      } catch (error) {
        console.error('[agreement/getCurrentAgreement] Error occured: ', error)
      }
    },
    async loadAgreement(agreementId) {
      try {
        const response = await getAgreement({ agreementId })

        if (response.response.ok) {
          dispatch.agreement.update({ currentAgreement: response.body })
        }
      } catch (error) {
        console.error('[agreement/loadAgreement] Error occured: ', error)
      }
    },
    async agreeToAgreements(payload, rootScope) {
      try {
        const agreementIds = Object.keys(rootScope.agreement.mismatched)
        const response = await agreeToAgreements({ body: agreementIds })

        if (response.response.ok) {
          dispatch.agreement.update({ mismatched: {} })
        }
      } catch (error) {
        console.error('[agreement/agreeToAgreements] Error occured: ', error)
      }
    }
  })
}

export default agreement
